import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import axios from 'axios'
import VueAxios from 'vue-axios'

//import Home from './components/HelloWorld.vue'
const Home = ()=> import('./components/HelloWorld.vue')
//import Denunciarecursoagua from './components/Denunciarecursoagua.vue'
const Alegatos = ()=> import('./components/Alegatos.vue')
const Costos = ()=> import('./components/Costos.vue')
const Descargos = ()=> import('./components/Descargos.vue')
import Regisrtroederff from './components/FunRegisrtroederff.vue'
import Regisrtropp from './components/FunRegisrtropp.vue'
import Regisrtroloief from './components/FunRegisrtroloief.vue'
import FormularioPCB from './components/FormularioPCB.vue'
import FormularioRUA from './components/FormularioRUA.vue'
import FormularioACU from './components/FormularioACU.vue'
import FormularioITE from './components/FormularioIET.vue'
import FormularioRespel from './components/FormularioRespel.vue'
import FormularioRAACU from './components/FormularioRAACU.vue'
import FormularioGestorACU from './components/FormularioGestorACU.vue'
import FormularioDGA from './components/FormularioDGA.vue'
import FormularioILLANTAS from './components/FormularioILLANTAS.vue'
import FormularioRCD from './components/FormularioRCDAnexoIV.vue'
import FormularioPlanchsn from './components/FormularioPlanchsn.vue'
import FormularioRAACUG from './components/FormularioRAACUGENE.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false


const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home
  },
  {
    path: '/Alegatos',
    name: 'Alegatos',
    component: Alegatos
  },
  {
    path: '/Costos',
    name: 'Costos',
    component: Costos
  },
  {
    path: '/Descargos',
    name: 'Descargos',
    component: Descargos
  },
  {
    path: '/FormularioILLANTAS',
    name: 'FormularioILLANTAS',
    component: FormularioILLANTAS,
  },
  {
    path: '/FormularioRCD',
    name: 'FormularioRCD',
    component: FormularioRCD,
  },
  {
    path: '/FormularioPlanchsn',
    name: 'FormularioPlanchsn',
    component: FormularioPlanchsn,
  },
  {
    path: '/FormularioPCB',
    name: 'FormularioPCB',
    component: FormularioPCB,
  },
  {
    path: '/FormularioRUA',
    name: 'FormularioRUA',
    component: FormularioRUA,
  },
  {
    path: '/FormularioACU',
    name: 'FormularioACU',
    component: FormularioACU,
  },
  {
    path: '/FormularioITE',
    name: 'FormularioITE',
    component: FormularioITE,
  },
  {
    path: '/FormularioRespel',
    name: 'FormularioRespel',
    component: FormularioRespel,
  },
  {
    path: '/FormularioRAACU',
    name: 'FormularioRAACU',
    component: FormularioRAACU,
  },
  {
    path: '/FormularioGestorACU',
    name: 'FormularioGestorACU',
    component: FormularioGestorACU,
  },
  {
    path: '/FormularioDGA',
    name: 'FormularioDGA',
    component: FormularioDGA,
  },
  {
    path: '/Regisrtroederff',
    name: 'Regisrtroederff',
    component: Regisrtroederff
  },
  {
    path: '/Regisrtropp',
    name: 'Regisrtropp',
    component: Regisrtropp
  },
  {
    path: '/Regisrtroloief',
    name: 'Regisrtroloief',
    component: Regisrtroloief
  },
  {
    path: '/FormularioRAACUG',
    name: 'FormularioRAACUG',
    component: FormularioRAACUG,
  },
]

const router = new VueRouter({
  mode: "history",
  routes
});



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
