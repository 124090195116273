<template>
  <div>
    <div class="container">
      <p style="font-weight: bold; text-align: center">
        FORMULARIO ANEXO IV. INSCRIPCIÓN DE GESTORES DE RCD ANTE LA AUTORIDAD
        AMBIENTAL COMPETENTE
      </p>
      <p style="font-weight: bold; text-align: center">
        Resolución 1257 de 2021 - Resolución 0472 de 2017
      </p>
    </div>
    <div class="container">
      <p style="text-align: justify">
        Señor(a) usuario (a), los campos que tienen el * son obligatorios para
        radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
        de la información solicitada, así como adjuntar los documentos descritos
        en las instrucciones de diligenciamiento de este formulario.
      </p>
    </div>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="text-right my-3">
        <b-button
          v-b-tooltip.hover
          title="Limpiar campos"
          variant="success"
          @click="resetForm()"
        >
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold">DATOS DEL SOLICITANTE </label>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="*Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoper"
              name="selectedtipoper"
              @change="onChangeRadioButton()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoident"
              name="selectedtipoident"
            >
              <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoper == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoper == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Nombre o razón social:"
            style="text-align: left"
          >
            <b-form-group style="text-align: left">
              <label
                style="text-align: left"
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
              >
                *Razón Social</label
              >
              <b-form-input
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
                v-model="form.nombre"
                placeholder="razón social"
                maxlength="180"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre1"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre2"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre3"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre4"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacion"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacion"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digito"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
            <label>*Dirección de correspondencia: </label>
            <b-form-input
              v-model="form.dircorrespondencia"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.depart"
              :options="optionsdepartamento"
              @change="onChangeCiudad()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudad" v-model="ciudadcompleta">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudades"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.tel"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.fax"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*¿Autoriza la notificación mediante correo electrónico?"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcorreo"
              name="selectedcorreo"
            >
              <b-form-radio value="1">Si </b-form-radio>
              <b-form-radio value="2">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="En caso contrario indique la dirección para notificación física:"
            style="text-align: left"
            ><b-form-input
              v-model="form.correodirnoti"
              placeholder="Dirección de notificación"
              maxlength="200"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
    </div>
    <br />
    <!------------------------------------Datos basicos del gestor----------------------------------------------->
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DATOS BÁSICOS DEL GESTOR</label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="*Nombre o Razón social" style="text-align: left"
            ><b-form-input
              v-model="form.nombregestor"
              placeholder="Nombre completo o Razón social"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Representante Legal" style="text-align: left"
            ><b-form-input
              v-model="form.reprelegalgestor"
              placeholder="Representante Legal"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Tipo Identificación" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentges"
              name="selectedtipoidentges"
            >
              <b-form-radio value="1">NIT </b-form-radio>
              <b-form-radio value="2">CC </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentges == 2 || form.selectedtipoidentges == 3
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionges"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoidentges == 2 || form.selectedtipoidentges == 3
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionges"
                  placeholder="No:"
                  v-if="form.selectedtipoidentges == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoges"
                  placeholder="Dígito verificación:"
                  v-if="form.selectedtipoidentges == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Código de actividad económica CIIU</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD PRINCIPAL" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUp"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD SECUNDARIA" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUs"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección Oficina Principal</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirges"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviages"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviages"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirges"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortges"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteges"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Tipo" style="text-align: left">
            <b-form-select
              v-model="form.tipotel"
              :options="optionstipotel"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Indicativo" style="text-align: left"
            ><b-form-input
              v-model="form.indiges"
              placeholder="Indicativo"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="*Teléfono:" style="text-align: left"
            ><b-form-input
              v-model="form.telges"
              placeholder="Teléfono"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.comptelges"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correoges"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <!------------------------------------DIRECCIÓN DE OPERACIÓN 1----------------------------------------------->
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DIRECCIÓN DE OPERACIÓN 1</label>
      </div>
      <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope1a"
              name="selectedactope1a"
            >
              <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope1b"
              name="selectedactope1b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope1c"
              name="selectedactope1c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope1d"
              name="selectedactope1d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope1e"
              name="selectedactope1e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección de actividad operativa</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departope1"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp1">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirope1"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviaope1"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviaope1"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.dirope1"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirope1"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortope1"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteope1"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Uso de suelo " style="text-align: left">
            <b-form-select
              v-model="form.usosueloope1"
              :options="optionsusosuelo"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Actividad de Almacenamiento (punto limpio)</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoplope1"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoplope1"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label
            >Actividades de Almacenamiento y Aprovechamiento en Planta</label
          >
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoapope1"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoapope1"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capapropesoapope1"
              placeholder="Capacidad de aprovechamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capaproflujoapope1"
              placeholder="Capacidad de aprovechamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Sitio de Disposición Final</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad total de disposición final de RCD (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.captotaldppope1"
              placeholder="Capacidad total de disposición final de RCD (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota mínima (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotaminope1"
              placeholder="Cota mínima (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota máxima (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotamaxope1"
              placeholder="Cota máxima (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Densidad de referencia del RCD (t/m3)"
            style="text-align: left"
            ><b-form-input
              v-model="form.dencircdope1"
              placeholder="Densidad de referencia del RCD (t/m3)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <b-col lg="4" class="pb-2"
          ><b-button size="sm" @click="activasitio2"
            >Agregar Nuevo Lugar de Operación</b-button
          ></b-col
        >
      </div>
    </div>
    <br />
    <!-----------------------------------------DIRECCIÓN DE OPERACIÓN 2------------------------------------------>
    <div
      class="border border-dark container"
      style="background: #f5f5f5"
      v-if="showestab2"
    >
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DIRECCIÓN DE OPERACIÓN 2</label>
      </div>
      <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope2a"
              name="selectedactope2a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope2b"
              name="selectedactope2b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope2c"
              name="selectedactope2c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope2d"
              name="selectedactope2d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope2e"
              name="selectedactope2e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección de actividad operativa</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departope2"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp2">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirope2"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviaope2"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviaope2"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.dirope2"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirope2"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortope2"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteope2"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Uso de suelo " style="text-align: left">
            <b-form-select
              v-model="form.usosueloope2"
              :options="optionsusosuelo"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Actividad de Almacenamiento (punto limpio)</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoplope2"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoplope2"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label
            >Actividades de Almacenamiento y Aprovechamiento en Planta</label
          >
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoapope2"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoapope2"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capapropesoapope2"
              placeholder="Capacidad de aprovechamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capaproflujoapope2"
              placeholder="Capacidad de aprovechamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Sitio de Disposición Final</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad total de disposición final de RCD  (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.captotaldppope2"
              placeholder="Capacidad total de disposición final de RCD  (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota mínima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotaminope2"
              placeholder="Cota mínima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota máxima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotamaxope2"
              placeholder="Cota máxima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Densidad de referencia del RCD (t/m3)"
            style="text-align: left"
            ><b-form-input
              v-model="form.dencircdope2"
              placeholder="Densidad de referencia del RCD (t/m3)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <b-col lg="4" class="pb-2"
          ><b-button size="sm" @click="activasitio3"
            >Agregar Nuevo Lugar de Operación</b-button
          ></b-col
        >
      </div>
    </div>
    <br />
    <!-----------------------------------DIRECCIÓN DE OPERACIÓN 3------------------------------------------------>
    <div
      class="border border-dark container"
      style="background: #f5f5f5"
      v-if="showestab3"
    >
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DIRECCIÓN DE OPERACIÓN 3</label>
      </div>
      <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope3a"
              name="selectedactope3a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope3b"
              name="selectedactope3b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope3c"
              name="selectedactope3c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope3d"
              name="selectedactope3d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope3e"
              name="selectedactope3e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección de actividad operativa</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departope3"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp3">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirope3"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviaope3"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviaope3"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.dirope3"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirope3"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortope3"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteope3"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Uso de suelo " style="text-align: left">
            <b-form-select
              v-model="form.usosueloope3"
              :options="optionsusosuelo"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Actividad de Almacenamiento (punto limpio)</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoplope3"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoplope3"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label
            >Actividades de Almacenamiento y Aprovechamiento en Planta</label
          >
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoapope3"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoapope3"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capapropesoapope3"
              placeholder="Capacidad de aprovechamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capaproflujoapope3"
              placeholder="Capacidad de aprovechamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Sitio de Disposición Final</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad total de disposición final de RCD  (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.captotaldppope3"
              placeholder="Capacidad total de disposición final de RCD  (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota mínima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotaminope3"
              placeholder="Cota mínima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota máxima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotamaxope3"
              placeholder="Cota máxima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Densidad de referencia del RCD (t/m3)"
            style="text-align: left"
            ><b-form-input
              v-model="form.dencircdope3"
              placeholder="Densidad de referencia del RCD (t/m3)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <b-col lg="4" class="pb-2"
          ><b-button size="sm" @click="activasitio4"
            >Agregar Nuevo Lugar de Operación</b-button
          ></b-col
        >
      </div>
    </div>
    <br />
    <!-----------------------------------------DIRECCIÓN DE OPERACIÓN 4------------------------------------------>
    <div
      class="border border-dark container"
      style="background: #f5f5f5"
      v-if="showestab4"
    >
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DIRECCIÓN DE OPERACIÓN 4</label>
      </div>
      <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope4a"
              name="selectedactope4a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope4b"
              name="selectedactope4b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope4c"
              name="selectedactope4c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope4d"
              name="selectedactope4d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope4e"
              name="selectedactope4e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección de actividad operativa</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departope4"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp4">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirope4"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviaope4"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviaope4"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.dirope4"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirope4"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortope4"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteope4"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Uso de suelo " style="text-align: left">
            <b-form-select
              v-model="form.usosueloope4"
              :options="optionsusosuelo"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Actividad de Almacenamiento (punto limpio)</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoplope4"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoplope4"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label
            >Actividades de Almacenamiento y Aprovechamiento en Planta</label
          >
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoapope4"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoapope4"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capapropesoapope4"
              placeholder="Capacidad de aprovechamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capaproflujoapope4"
              placeholder="Capacidad de aprovechamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Sitio de Disposición Final</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad total de disposición final de RCD  (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.captotaldppope4"
              placeholder="Capacidad total de disposición final de RCD  (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota mínima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotaminope4"
              placeholder="Cota mínima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota máxima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotamaxope4"
              placeholder="Cota máxima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Densidad de referencia del RCD (t/m3)"
            style="text-align: left"
            ><b-form-input
              v-model="form.dencircdope4"
              placeholder="Densidad de referencia del RCD (t/m3)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <b-col lg="4" class="pb-2"
          ><b-button size="sm" @click="activasitio5"
            >Agregar Nuevo Lugar de Operación</b-button
          ></b-col
        >
      </div>
    </div>
    <br />
    <!-------------------------------------------DIRECCIÓN DE OPERACIÓN 5---------------------------------------->
    <div
      class="border border-dark container"
      style="background: #f5f5f5"
      v-if="showestab5"
    >
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DIRECCIÓN DE OPERACIÓN 5</label>
      </div>
      <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope5a"
              name="selectedactope5a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope5b"
              name="selectedactope5b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope5c"
              name="selectedactope5c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope5d"
              name="selectedactope5d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope5e"
              name="selectedactope5e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección de actividad operativa</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departope5"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp5">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirope5"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviaope5"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviaope5"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.dirope5"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirope5"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortope5"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteope5"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Uso de suelo " style="text-align: left">
            <b-form-select
              v-model="form.usosueloope5"
              :options="optionsusosuelo"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Actividad de Almacenamiento (punto limpio)</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoplope5"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoplope5"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label
            >Actividades de Almacenamiento y Aprovechamiento en Planta</label
          >
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmpesoapope5"
              placeholder="Capacidad de almacenamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de almacenamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capalmflujoapope5"
              placeholder="Capacidad de almacenamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en peso (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capapropesoapope5"
              placeholder="Capacidad de aprovechamiento en peso (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad de aprovechamiento en Flujo (t/mes)"
            style="text-align: left"
            ><b-form-input
              v-model="form.capaproflujoapope5"
              placeholder="Capacidad de aprovechamiento en Flujo (t/mes)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold; text-align: center">
          <label>Sitio de Disposición Final</label>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Capacidad total de disposición final de RCD  (t)"
            style="text-align: left"
            ><b-form-input
              v-model="form.captotaldppope5"
              placeholder="Capacidad total de disposición final de RCD  (t)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota mínima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotaminope5"
              placeholder="Cota mínima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Cota máxima  (m.s.n.m.)" style="text-align: left"
            ><b-form-input
              v-model="form.cuotamaxope5"
              placeholder="Cota máxima  (m.s.n.m.)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Densidad de referencia del RCD (t/m3)"
            style="text-align: left"
            ><b-form-input
              v-model="form.dencircdope5"
              placeholder="Densidad de referencia del RCD (t/m3)"
              maxlength="100"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <b-col lg="4" class="pb-2"
          ><b-button size="sm" @click="activasitio6"
            >Agregar Nuevo Lugar de Operación</b-button
          ></b-col
        >
      </div>
    </div>
    <br />
    <!-----------------------------------------preguntas----------------------------------------->
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="col-md-12" style="font-weight: bold">
        <label>TIPOS DE RESIDUO GESTIONADOS</label>
      </div>
      <div class="col-md-12">
          <b-form-group
            label="1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges0a"
              name="tiporesiduoges0a"
            >
              <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges0b"
              name="tiporesiduoges0b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges0c"
              name="tiporesiduoges0c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges0d"
              name="tiporesiduoges0d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.1 Los contaminados con residuos peligrosos."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges0e"
              name="tiporesiduoges0e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.2. Los que por su estado no pueden ser aprovechados."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges0f"
              name="tiporesiduoges0f"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges0g"
              name="tiporesiduoges0g"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
    </div>
    <br>
    <!---------------------------------------DATOS DEL GESTOR RCD 1-------------------------------------------->
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="col-md-12">
        <b-form-group
          label="*Los Residuos de construcción y Demolición- RCD son entregados a otro gestor "
          style="text-align: left"
        >
          <b-form-radio-group
            id="radio-slots"
            v-model="form.selectedanonimo"
            name="selectedanonimo"
          >
            <b-form-radio value="1">Si </b-form-radio>
            <b-form-radio value="2">No</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <div style="text-align: center; margin-top: 20px" v-if="form.selectedanonimo == 1">
        <label style="font-weight: bold">DATOS DEL GESTOR RCD 1</label>
      </div>
      <div class="row" v-if="form.selectedanonimo == 1">
        <div class="col-md-12">
          <b-form-group label="*Nombre o Razón social" style="text-align: left"
            ><b-form-input
              v-model="form.nombregestor1"
              placeholder="Nombre completo o Razón social"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Representante Legal" style="text-align: left"
            ><b-form-input
              v-model="form.reprelegalgestor1"
              placeholder="Representante Legal"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Tipo Identificación" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentges1"
              name="selectedtipoidentges1"
            >
              <b-form-radio value="1">NIT </b-form-radio>
              <b-form-radio value="2">CC </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentges1 == 2 || form.selectedtipoidentges1 == 3
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionges1"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoidentges1 == 2 || form.selectedtipoidentges1 == 3
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionges1"
                  placeholder="No:"
                  v-if="form.selectedtipoidentges1 == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoges1"
                  placeholder="Dígito verificación:"
                  v-if="form.selectedtipoidentges1 == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Código de actividad económica CIIU</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD PRINCIPAL" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUp1"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD SECUNDARIA" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUs1"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección Oficina Principal</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio1"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp01">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirges1"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviages1"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviages1"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio1"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirges1"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortges1"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteges1"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Tipo" style="text-align: left">
            <b-form-select
              v-model="form.tipotel1"
              :options="optionstipotel"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Indicativo" style="text-align: left"
            ><b-form-input
              v-model="form.indiges1"
              placeholder="Indicativo"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="*Teléfono:" style="text-align: left"
            ><b-form-input
              v-model="form.telges1"
              placeholder="Teléfono"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.comptelges1"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope6a"
              name="selectedactope6a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope6b"
              name="selectedactope6b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope6c"
              name="selectedactope6c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope6d"
              name="selectedactope6d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope6e"
              name="selectedactope6e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
        <label>TIPOS DE RESIDUO GESTIONADOS</label>
      </div>
      <div class="col-md-12">
          <b-form-group
            label="1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges1a"
              name="tiporesiduoges1a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges1b"
              name="tiporesiduoges1b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges1c"
              name="tiporesiduoges1c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges1d"
              name="tiporesiduoges1d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.1 Los contaminados con residuos peligrosos."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges1e"
              name="tiporesiduoges1e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio> 
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.2. Los que por su estado no pueden ser aprovechados."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges1f"
              name="tiporesiduoges1f"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges1g"
              name="tiporesiduoges1g"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <b-col lg="4" class="pb-2"
          ><b-button size="sm" @click="activasitio7"
            >Agregar Nuevo Gestor RCD</b-button
          ></b-col
        >
      </div>
    </div>
    <br />
    <!----------------------------------------DATOS DEL GESTOR RCD 2-------------------------------------------->
    <div class="border border-dark container" style="background: #f5f5f5" v-if="showestab6">
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DATOS DEL GESTOR RCD 2</label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="*Nombre o Razón social" style="text-align: left"
            ><b-form-input
              v-model="form.nombregestor2"
              placeholder="Nombre completo o Razón social"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Representante Legal" style="text-align: left"
            ><b-form-input
              v-model="form.reprelegalgestor2"
              placeholder="Representante Legal"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Tipo Identificación" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentges2"
              name="selectedtipoidentges2"
            >
              <b-form-radio value="1">NIT </b-form-radio>
              <b-form-radio value="2">CC </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentges2 == 2 || form.selectedtipoidentges2 == 3
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionges2"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoidentges2 == 2 || form.selectedtipoidentges2 == 3
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionges2"
                  placeholder="No:"
                  v-if="form.selectedtipoidentges2 == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoges2"
                  placeholder="Dígito verificación:"
                  v-if="form.selectedtipoidentges2 == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Código de actividad económica CIIU</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD PRINCIPAL" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUp2"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD SECUNDARIA" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUs2"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección Oficina Principal</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio2"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp02">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirges2"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviages2"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviages2"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio2"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirges2"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortges2"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteges2"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Tipo" style="text-align: left">
            <b-form-select
              v-model="form.tipotel2"
              :options="optionstipotel"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Indicativo" style="text-align: left"
            ><b-form-input
              v-model="form.indiges2"
              placeholder="Indicativo"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="*Teléfono:" style="text-align: left"
            ><b-form-input
              v-model="form.telges2"
              placeholder="Teléfono"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.comptelges2"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope7a"
              name="selectedactope7a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope7b"
              name="selectedactope7b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope7c"
              name="selectedactope7c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope7d"
              name="selectedactope7d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope7e"
              name="selectedactope7e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
        <label>TIPOS DE RESIDUO GESTIONADOS</label>
      </div>
      <div class="col-md-12">
          <b-form-group
            label="1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges2a"
              name="tiporesiduoges2a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges2b"
              name="tiporesiduoges2b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges2c"
              name="tiporesiduoges2c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges2d"
              name="tiporesiduoges2d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.1 Los contaminados con residuos peligrosos."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges2e"
              name="tiporesiduoges2e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.2. Los que por su estado no pueden ser aprovechados."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges2f"
              name="tiporesiduoges2f"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges2g"
              name="tiporesiduoges2g"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <b-col lg="4" class="pb-2"
          ><b-button size="sm" @click="activasitio8"
            >Agregar Nuevo Gestor RCD</b-button
          ></b-col
        >
      </div>
    </div>
    <br />
    <!------------------------------------------DATOS DEL GESTOR RCD 3------------------------------------------>
    <div class="border border-dark container" style="background: #f5f5f5" v-if="showestab7">
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DATOS DEL GESTOR RCD 3</label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="*Nombre o Razón social" style="text-align: left"
            ><b-form-input
              v-model="form.nombregestor3"
              placeholder="Nombre completo o Razón social"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Representante Legal" style="text-align: left"
            ><b-form-input
              v-model="form.reprelegalgestor3"
              placeholder="Representante Legal"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Tipo Identificación" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentges3"
              name="selectedtipoidentges3"
            >
              <b-form-radio value="1">NIT </b-form-radio>
              <b-form-radio value="2">CC </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentges3 == 2 || form.selectedtipoidentges3 == 3
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionges3"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoidentges3 == 2 || form.selectedtipoidentges3 == 3
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionges3"
                  placeholder="No:"
                  v-if="form.selectedtipoidentges3 == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoges3"
                  placeholder="Dígito verificación:"
                  v-if="form.selectedtipoidentges3 == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Código de actividad económica CIIU</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD PRINCIPAL" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUp3"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*ACTIVIDAD SECUNDARIA" style="text-align: left">
            <b-form-select
              v-model="form.codigoCIIUs3"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
          <label>Dirección Oficina Principal</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio3"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp03">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Dirección" style="text-align: left">
            <b-form-select
              v-model="form.tipodirges3"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Tipo de Vía" style="text-align: left">
            <b-form-select
              v-model="form.tipoviages3"
              :options="optionstipovia"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Número de vía" style="text-align: left"
            ><b-form-input
              v-model="form.numviages3"
              placeholder="Número de vía"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-8">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio3"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.compldirges3"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>COORDENADAS Magna Origen Nacional</label>
        </div>
        <div class="col-md-6">
          <b-form-group label="NORTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.coornortges3"
              placeholder="NORTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="ESTE (metros)" style="text-align: left"
            ><b-form-input
              v-model="form.cooresteges3"
              placeholder="ESTE (metros)"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Tipo" style="text-align: left">
            <b-form-select
              v-model="form.tipotel3"
              :options="optionstipotel"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Indicativo" style="text-align: left"
            ><b-form-input
              v-model="form.indiges3"
              placeholder="Indicativo"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="*Teléfono:" style="text-align: left"
            ><b-form-input
              v-model="form.telges3"
              placeholder="Teléfono"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Complemento" style="text-align: left"
            ><b-form-input
              v-model="form.comptelges3"
              placeholder="Complemento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
        <label>Actividad ejecutada por el gestor de RCD</label>
      </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE RECOLECCIÓN"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope8a"
              name="selectedactope8a"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="ACTIVIDAD DE TRANSPORTE" style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope8b"
              name="selectedactope8b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope8c"
              name="selectedactope8c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE APROVECHAMIENTO"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope8d"
              name="selectedactope8d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="ACTIVIDAD DE DISPOSICIÓN FINAL"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedactope8e"
              name="selectedactope8e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="font-weight: bold">
        <label>TIPOS DE RESIDUO GESTIONADOS</label>
      </div>
      <div class="col-md-12">
          <b-form-group
            label="1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges3a"
              name="tiporesiduoges3a"
            >
              <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges3b"
              name="tiporesiduoges3b"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges3c"
              name="tiporesiduoges3c"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges3d"
              name="tiporesiduoges3d"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.1 Los contaminados con residuos peligrosos."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges3e"
              name="tiporesiduoges3e"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.2. Los que por su estado no pueden ser aprovechados."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges3f"
              name="tiporesiduoges3f"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión."
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.tiporesiduoges3g"
              name="tiporesiduoges3g"
            >
            <b-form-radio value="Si">Si </b-form-radio>
              <b-form-radio value="No">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

      </div>
    </div>
    <br />
    <!------------------------------------------DOCUMENTOS------------------------------------------>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: center; margin-top: 20px">
        <label style="font-weight: bold">DOCUMENTACIÓN </label>
      </div>
      <div class="row">
        <div class="col-md-12" style="font-weight: bold">
        <label>Resolución 472 de 2017. Artículo 10. Medidas mínimas de manejo ambiental en puntos limpios y en plantas de aprovechamiento.</label>
      </div>
      <div class="col-md-12">
        1. Describir el flujo de los procesos realizados con los RCD.
      </div>
      <div class="col-md-12">
        2. Diseñar y ejecutar las obras de drenaje y de control de sedimentos.
      </div>
      <div class="col-md-12">
        3. Contar con instrumentos de pesaje debidamente calibrados de acuerdo con la normatividad vigente.
      </div>
      <div class="col-md-12">
        4. Establecer barreras para evitar el impacto visual en los alrededores de la planta, cuando a ello hubiera lugar.
      </div>
      <div class="col-md-12">
        5. Realizar acciones para evitar la dispersión de partículas. 
      </div>
      <div class="col-md-12">
        6. Mantener los RCD debidamente separados de acuerdo al tipo de RCD de que trata el anexo I.
      </div>
      <div class="col-md-12" style="font-weight: bold">
        <label>Resolución 1257 de 2021. Articulo 3. Medidas mínimas de manejo ambiental de sitios de disposición final de RCD.</label>
      </div>
      <div class="col-md-12">
        1. Describir de manera detallada el flujo de los procesos realizados con los materiales de construcción y los RCD resultantes.
      </div>
      <div class="col-md-12">
        2. Formular e implementar las acciones de control para evitar la dispersión de partículas, las obras de drenaje y de control de sedimentos.
      </div>
      <div class="col-md-12">
        3. Definir las medidas para garantizar la estabilidad geotécnica del sitio.
      </div>
      <div class="col-md-12">
        4. Establecer barreras para evitar el impacto visual en los alrededores del sitio de disposición final de RCD.
      </div>
      <div class="col-md-12">
        5. Contar con instrumentos de pesaje debidamente calibrados de acuerdo con la normatividad vigente.
      </div>
      <div class="col-md-12">
        6. Contar con cerramiento perimetral que garantice el aislamiento y seguridad del sitio.
      </div>
      <div class="col-md-12">
        7. Contar con una valla informativa visible que contenga la información relevante del sitio.
      </div>
      <div class="col-md-12">
        8. Describir e implementar las actividades de clausura y posclausura.
      </div>
      <div class="col-md-12">
        <label style="font-weight: bold">Parágrafo 1.</label> El gestor deberá remitir copia del documento de que trata el presente artículo a la autoridad ambiental competente, con una antelación de 60 días calendario al inicio de actividades del sitio de disposición final de RCD, para efectos de su seguimiento y control. A dicho documento se le anexaran copia de los permisos, licencias y demás autorizaciones ambientales a que haya lugar, así como copia de la certificación sobre la compatibilidad del proyecto con los usos del suelo establecidos en el POT, PBOT o EOT. 

Los proyectos sujetos a licenciamiento ambiental que tengan sitios de disposición final de RCD autorizados deberán remitir la información dentro de los Informes de Cumplimiento Ambiental de acuerdo con la periodicidad definida por la autoridad ambiental para su presentación.
      </div>
      <div class="col-md-12">
        <label style="font-weight: bold">Parágrafo 2.</label> El gestor deberá remitir dentro de los 15 días calendario posteriores a la finalización de cada trimestre del año, la información prevista en el Anexo III de la presente resolución con la cantidad de RCD dispuestos en el trimestre inmediatamente anterior.
      </div>
      <div class="col-md-12">
        <label style="font-weight: bold">Parágrafo 3.</label> Sin perjuico de lo anterior en cumplimiento del artículo 42 de la Ley 1523 de 2012 o aquella que la modifique o sustituya, el gestor deberá diseñar e implementar medidas de reducción del riesgo y planes de emergencia y contingencia. 
      </div>
      </div>
    </div>
    <br />
    <!------------------------------------------------------------------------------------>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="padding: 5px">
        <div class="col-md-12">
          <b-form-group
            label="ESCRIBA LA FIRMA (Representante Legal del Gestor RCD)"
            style="text-align: left; font-weight: bold"
            ><b-form-input
              v-model="form.firmasolicitante"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label style="text-align: left; font-weight: bold"
            >POR FAVOR FIRME LA SOLICITUD SOBRE EL SIGUIENTE ESPACIO</label
          >
          <canvas
            ref="signatureCanvas"
            @mouseup="captureSignature"
            @touchend="captureSignature"
          ></canvas>
        </div>
        <div class="col-md-12 mt-3">
          <!-- Agrega espacio arriba de los botones -->
          <b-button
            type="button"
            @click="clearSignature"
            class="btn btn-primary btn-sm mr-2"
            >Borrar</b-button
          >
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-11">
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Adjunta los documentos, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
            drop-placeholder="Drop file here..."
            multiple
            browse-text="Buscar"
          ></b-form-file>
        </div>
        <div class="col-md-1">
          <b-button id="popover-button-variant" href="#" tabindex="0"
            >Ayuda</b-button
          >
          <b-popover
            target="popover-button-variant"
            variant="info"
            triggers="focus"
          >
            <template #title>Tutorial</template>
            De click
            <b-link
              href="../../../document/Guía Rápida Adjuntar documentos Tramites Web.pdf"
              target="blank"
              >aqui</b-link
            >
          </b-popover>
        </div>
      </div>
      <br /><br />
      <b-form-checkbox
        id="aceptacion"
        v-model="form.aceptacion"
        name="aceptacion"
        value="1"
        unchecked-value="0"
        >Acepto el uso de mis datos personales, tratamiento, protección y
        conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
        de 2012.
        <a
          href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
          >Ver Política de protección de Datos Personales de la CVC.</a
        > </b-form-checkbox
      ><br />
      <div v-show="show">
        <b-button block variant="success" @click="enviar()"
          >Enviar solicitud</b-button
        >
      </div>
      <br />
      <div class="text-center" v-show="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div class="text-center">
          <strong>Enviando Solicitud</strong><br />
          <strong
            >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
            subida y salga un mensaje con el número de radicado. Si abandona el
            proceso, la solicitud quedará incompleta...</strong
          >
        </div>
        <br />

        <div
          :key="ind"
          v-for="(tr, ind) in filesValide"
          class="row ml-8 mb-2 d-flex justify-content-center"
        >
          <b-progress
            :max="100"
            striped
            animated
            :variant="'primary'"
            class="w-75"
          >
            <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
              <span>
                {{
                  tr.value !== 0
                    ? tr.color
                      ? `Se proceso el archivo '${tr.name} de manera correcta.`
                      : `No se pudo procesar el archivo '${tr.name}'.`
                    : `Se esta procesando el archivo '${tr.name}'`
                }}
                <strong></strong></span
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <br />
    </div>
    <br />
  </div>
  <!--cierre div principal-->
</template>

<script>
import {
  rutafile,
  rutaciudad,
  rutaenviosolicitudrcd,
  tiponotificacion,
  logo,
  INSCRIPCION_DE_GESTORES_DE_RCD,
} from "../properties";
import SignaturePad from "signature_pad";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      canvasWidth: 700,
      canvasHeight: 120,
      signaturePad: null, // Variable para almacenar la instancia de SignaturePad
      form: {
        tipotramite: "74",
        mediorecepcion: "4",
        observacionradicado:
          "INSCRIPCIÓN DE GESTORES DE RCD ANTE LA AUTORIDAD AMBIENTAL COMPETENTE",
        asunto:
          "INSCRIPCIÓN DE GESTORES DE RCD ANTE LA AUTORIDAD AMBIENTAL COMPETENTE",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: INSCRIPCION_DE_GESTORES_DE_RCD, //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        desciudad: "", //CAMBIO
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: tiponotificacion,
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        desciudadapoderado: "", //CAMBIO
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        desciudadpredio: "",
        nombrecentropoblado: "",
        logo: logo,
        filesname: "",
        firmasolicitante: "",
        aceptacion: "",


        nombregestor: "",
        reprelegalgestor: "",
        selectedtipoidentges: "",
        tipoper: "",
        identificacionges: "",
        digitoges: "",
        codigoCIIUp: "",
        codigoCIIUs: "",
        tipodirges: "",
        tipoviages: "",
        numviages: "",
        compldirges: "",
        coornortges: "",
        cooresteges: "",
        tipotel: "",
        indiges: "",
        telges: "",
        comptelges: "",
        correoges: "",

        selectedactope1a: "",
        selectedactope1b: "",
        selectedactope1c: "",
        selectedactope1d: "",
        selectedactope1e: "",
        departope1: "",
        ciudadope1: "",
        tipodirope1: "",
        tipoviaope1: "",
        numviaope1: "",
        dirope1: "",
        compldirope1: "",
        coornortope1: "",
        cooresteope1: "",
        usosueloope1: "",
        capalmpesoplope1: "",
        capalmflujoplope1: "",
        capalmpesoapope1: "",
        capalmflujoapope1: "",
        capapropesoapope1: "",
        capaproflujoapope1: "",
        captotaldppope1: "",
        cuotaminope1: "",
        cuotamaxope1: "",
        dencircdope1: "",

        tiporesiduoges0a: "",
        tiporesiduoges0b: "",
        tiporesiduoges0c: "",
        tiporesiduoges0d: "",
        tiporesiduoges0e: "",
        tiporesiduoges0f: "",
        tiporesiduoges0g: "",

        selectedactope2a: "",
        selectedactope2b: "",
        selectedactope2c: "",
        selectedactope2d: "",
        selectedactope2e: "",
        departope2: "",
        ciudadope2: "",
        tipodirope2: "",
        tipoviaope2: "",
        numviaope2: "",
        dirope2: "",
        compldirope2: "",
        coornortope2: "",
        cooresteope2: "",
        usosueloope2: "",
        capalmpesoplope2: "",
        capalmflujoplope2: "",
        capalmpesoapope2: "",
        capalmflujoapope2: "",
        capapropesoapope2: "",
        capaproflujoapope2: "",
        captotaldppope2: "",
        cuotaminope2: "",
        cuotamaxope2: "",
        dencircdope2: "",

        selectedactope3a: "",
        selectedactope3b: "",
        selectedactope3c: "",
        selectedactope3d: "",
        selectedactope3e: "",
        departope3: "",
        ciudadope3: "",
        tipodirope3: "",
        tipoviaope3: "",
        numviaope3: "",
        dirope3: "",
        compldirope3: "",
        coornortope3: "",
        cooresteope3: "",
        usosueloope3: "",
        capalmpesoplope3: "",
        capalmflujoplope3: "",
        capalmpesoapope3: "",
        capalmflujoapope3: "",
        capapropesoapope3: "",
        capaproflujoapope3: "",
        captotaldppope3: "",
        cuotaminope3: "",
        cuotamaxope3: "",
        dencircdope3: "",

        selectedactope4a: "",
        selectedactope4b: "",
        selectedactope4c: "",
        selectedactope4d: "",
        selectedactope4e: "",
        departope4: "",
        ciudadope4: "",
        tipodirope4: "",
        tipoviaope4: "",
        numviaope4: "",
        dirope4: "",
        compldirope4: "",
        coornortope4: "",
        cooresteope4: "",
        usosueloope4: "",
        capalmpesoplope4: "",
        capalmflujoplope4: "",
        capalmpesoapope4: "",
        capalmflujoapope4: "",
        capapropesoapope4: "",
        capaproflujoapope4: "",
        captotaldppope4: "",
        cuotaminope4: "",
        cuotamaxope4: "",
        dencircdope4: "",

        selectedactope5a: "",
        selectedactope5b: "",
        selectedactope5c: "",
        selectedactope5d: "",
        selectedactope5e: "",
        departope5: "",
        ciudadope5: "",
        tipodirope5: "",
        tipoviaope5: "",
        numviaope5: "",
        dirope5: "",
        compldirope5: "",
        coornortope5: "",
        cooresteope5: "",
        usosueloope5: "",
        capalmpesoplope5: "",
        capalmflujoplope5: "",
        capalmpesoapope5: "",
        capalmflujoapope5: "",
        capapropesoapope5: "",
        capaproflujoapope5: "",
        captotaldppope5: "",
        cuotaminope5: "",
        cuotamaxope5: "",
        dencircdope5: "",

       

        nombregestor1: "",
        reprelegalgestor1: "",
        selectedtipoidentges1: "",
        tipoper1: "",
        identificacionges1: "",
        digitoges1: "",
        codigoCIIUp1: "",
        codigoCIIUs1: "",
        departpredio1: "",
        ciudadcompletarcd1: "",
        tipodirges1: "",
        tipoviages1: "",
        numviages1: "",
        direccionpredio1: "",
        compldirges1: "",
        coornortges1: "",
        cooresteges1: "",
        tipotel1: "",
        indiges1: "",
        telges1: "",
        comptelges1: "",
        selectedactope6a: "",
        selectedactope6b: "",
        selectedactope6c: "",
        selectedactope6d: "",
        selectedactope6e: "",
        tiporesiduoges1a: "",
        tiporesiduoges1b: "",
        tiporesiduoges1c: "",
        tiporesiduoges1d: "",
        tiporesiduoges1e: "",
        tiporesiduoges1f: "",
        tiporesiduoges1g: "",

        nombregestor2: "",
        reprelegalgestor2: "",
        selectedtipoidentges2: "",
        tipoper2: "",
        identificacionges2: "",
        digitoges2: "",
        codigoCIIUp2: "",
        codigoCIIUs2: "",
        departpredio2: "",
        ciudadcompletarcd2: "",
        tipodirges2: "",
        tipoviages2: "",
        numviages2: "",
        direccionpredio2: "",
        compldirges2: "",
        coornortges2: "",
        cooresteges2: "",
        tipotel2: "",
        indiges2: "",
        telges2: "",
        comptelges2: "",
        selectedactope7a: "",
        selectedactope7b: "",
        selectedactope7c: "",
        selectedactope7d: "",
        selectedactope7e: "",
        tiporesiduoges2a: "",
        tiporesiduoges2b: "",
        tiporesiduoges2c: "",
        tiporesiduoges2d: "",
        tiporesiduoges2e: "",
        tiporesiduoges2f: "",
        tiporesiduoges2g: "",

        nombregestor3: "",
        reprelegalgestor3: "",
        selectedtipoidentges3: "",
        tipoper3: "",
        identificacionges3: "",
        digitoges3: "",
        codigoCIIUp3: "",
        codigoCIIUs3: "",
        departpredio3: "",
        ciudadcompletarcd3: "",
        tipodirges3: "",
        tipoviages3: "",
        numviages3: "",
        direccionpredio3: "",
        compldirges3: "",
        coornortges3: "",
        cooresteges3: "",
        tipotel3: "",
        indiges3: "",
        telges3: "",
        comptelges3: "",
        selectedactope8a: "",
        selectedactope8b: "",
        selectedactope8c: "",
        selectedactope8d: "",
        selectedactope8e: "",
        tiporesiduoges3a: "",
        tiporesiduoges3b: "",
        tiporesiduoges3c: "",
        tiporesiduoges3d: "",
        tiporesiduoges3e: "",
        tiporesiduoges3f: "",
        tiporesiduoges3g: "",

        selectedanonimo: "",
        dia: "",
        mes: "",
        anio: "",
        firmaBase64: "",
      },
      form1: {
        actividadeco: "",
        CodigoCIIU: "",
        costototal: "",
        deparfuente: "",
        munifuente: "",
        direccionprediofuente: "",
        fuenteabastecimiento: "",
        grado1: "",
        grado2: "",
        grado3: "",
        munitos1: "",
        munitos2: "",
        munitos3: "",
        segundos1: "",
        segundos2: "",
        segundos3: "",
        grados1l: "",
        grados2l: "",
        grados3l: "",
        minutos1l: "",
        minutos2l: "",
        minutos3l: "",
        segundos1l: "",
        segundos2l: "",
        segundos3l: "",
        altitud1l: "",
        altitud2l: "",
        altitud3l: "",
        usosueloactual: "",
        usosuelopotencial: "",
        selectedtipoverti: "",
        selectedcaptacion: "",
        tiempodescargahora: "",
        tiempodescargadias: "",
        tiempodescargacuadal: "",
        selectedtipoflujo: "",
        areavertimiento: "",
        observa: "",
        firma: "",
        aceptacion: "",
        tipovertmiento: "2",
      },
      gestores: [
      {
        nombregestor: "",
        reprelegalgestor: "",
        selectedtipoidentges: "",
        tipoper: "",
        identificacionges: "",
        digitoges: "",        
        departpredio: "",
        ciudadcompleta: "",
        tipodirges: "",
        tipoviages: "",
        numviages: "",
        direccionpredio: "",
        compldirges: "",        
        coornortges: "",
        cooresteges: "",        
        tipotel: "",
        indiges: "",
        telges: "",
        comptelges: "",
        correoges: "",
        codigoCIIUp: "",
        codigoCIIUs: "",
        selectedactopea: "",
        selectedactopeb: "",
        selectedactopec: "",
        selectedactoped: "",
        selectedactopee: "",
        usosueloope: "",
        capalmpesoplope: "",
        capalmflujoplope: "",
        capalmpesoapope: "",
        capalmflujoapope: "",
        capapropesoapope: "",
        capaproflujoapope: "",
        captotaldppope: "",
        cuotaminope: "",
        cuotamaxope: "",
        dencircdope: "",
        tiporesiduogesa: "",
        tiporesiduogesb: "",
        tiporesiduogesc: "",
        tiporesiduogesd: "",
        tiporesiduogese: "",
        tiporesiduogesf: "",
        tiporesiduogesg: ""
      },
    ],
      ciudadapoderado: "", //CAMBIO
      ciudadcompletaemp: "", //CAMBIO
      ciudadcompleta: "", //CAMBIO
      ciudadcompletaemp1: "", //CAMBIO
      ciudadcompletaemp2: "", //CAMBIO
      ciudadcompletaemp3: "", //CAMBIO
      ciudadcompletaemp4: "", //CAMBIO
      ciudadcompletaemp5: "", //CAMBIO
      ciudadcompletaemp01: "",
      ciudadcompletaemp02: "",
      ciudadcompletaemp03: "",
      form2: {},
      datos: [],
      tipopeticion: "",
      nombretramite: "",
      peticion: "",
      loading: false,
      show: true,
      showciudad: false,
      showciudadapo: false,
      showestab2: false,
      showestab3: false,
      showestab4: false,
      showestab5: false,
      showestab6: false,
      showestab7: false,
      file1: [],
      pesofile1: 0,
      nombrefiles: [],
      arryaJson: [],
      contadorarchivos: 0, // nueva------------------------------------------------------------
      contadorresponse: 0, // nueva------------------------------------------------------------
      filesValide: [], // nueva----------------------------------------------------------------
      optionsCiudad: [
        { value: "1000", text: "SANTIAGO DE CALI" },
        { value: "20000", text: "ALCALA" },
        { value: "36000", text: "ANDALUCIA" },
        { value: "41000", text: "ANSERMANUEVO" },
        { value: "54000", text: "ARGELIA" },
        { value: "100000", text: "BOLIVAR" },
        { value: "109000", text: "BUENAVENTURA" },
        { value: "111000", text: "GUADALAJARA DE BUGA" },
        { value: "113000", text: "BUGALAGRANDE" },
        { value: "122000", text: "CAICEDONIA" },
        { value: "126000", text: "DARIEN" },
        { value: "130000", text: "CANDELARIA" },
        { value: "147000", text: "CARTAGO" },
        { value: "233000", text: "DAGUA" },
        { value: "243000", text: "EL AGUILA" },
        { value: "246000", text: "EL CAIRO" },
        { value: "248000", text: "EL CERRITO" },
        { value: "250000", text: "EL DOVIO" },
        { value: "275000", text: "FLORIDA" },
        { value: "306000", text: "GINEBRA" },
        { value: "318000", text: "GUACARI" },
        { value: "364000", text: "JAMUNDI" },
        { value: "377000", text: "LA CUMBRE" },
        { value: "400000", text: "LA UNION" },
        { value: "403000", text: "LA VICTORIA" },
        { value: "497000", text: "OBANDO" },
        { value: "520000", text: "PALMIRA" },
        { value: "563000", text: "PRADERA" },
        { value: "606000", text: "RESTREPO" },
        { value: "616000", text: "RIOFRIO" },
        { value: "622000", text: "ROLDANILLO" },
        { value: "670000", text: "SAN PEDRO" },
        { value: "736000", text: "SEVILLA" },
        { value: "823000", text: "TORO" },
        { value: "828000", text: "TRUJILLO" },
        { value: "834000", text: "TULUA" },
        { value: "845000", text: "ULLOA" },
        { value: "863000", text: "VERSALLES" },
        { value: "869000", text: "VIJES" },
        { value: "890000", text: "YOTOCO" },
        { value: "892000", text: "YUMBO" },
        { value: "895000", text: "ZARZAL" },
      ],
      optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
      optionsdepartamento: [
        { value: "91", text: "AMAZONAS" },
        { value: "5", text: "ANTIOQUIA" },
        { value: "81", text: "ARAUCA" },
        {
          value: "88",
          text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        },
        { value: "8", text: "ATLANTICO" },
        { value: "11", text: "BOGOTA D.C" },
        { value: "13", text: "BOLIVAR" },
        { value: "15", text: "BOYACA" },
        { value: "17", text: "CALDAS" },
        { value: "18", text: "CAQUETA" },
        { value: "85", text: "CASANARE" },
        { value: "19", text: "CAUCA" },
        { value: "20", text: "CESAR" },
        { value: "27", text: "CHOCO" },
        { value: "23", text: "CORDOBA" },
        { value: "25", text: "CUNDINAMARCA" },
        { value: "94", text: "GUAINIA" },
        { value: "95", text: "GUAVIARE" },
        { value: "41", text: "HUILA" },
        { value: "44", text: "LA GUAJIRA" },
        { value: "47", text: "MAGDALENA" },
        { value: "50", text: "META" },
        { value: "52", text: "NARIÑO" },
        { value: "54", text: "NORTE DE SANTANDER" },
        { value: "86", text: "PUTUMAYO" },
        { value: "63", text: "QUINDIO" },
        { value: "66", text: "RISARALDA" },
        { value: "68", text: "SANTANDER" },
        { value: "70", text: "SUCRE" },
        { value: "73", text: "TOLIMA" },
        { value: "76", text: "VALLE DEL CAUCA" },
        { value: "97", text: "VAUPES" },
        { value: "99", text: "VICHADA" },
      ],
      optioonsCIIU: [
        {
          value: "1011",
          text: "1011	Procesamiento y conservación de carne y productos cárnicos",
        },
        {
          value: "1012",
          text: "1012	Procesamiento y conservación de pescados, crustáceos y moluscos",
        },
        {
          value: "1020",
          text: "1020	Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos",
        },
        {
          value: "1031",
          text: "1031	Extracción de aceites de origen vegetal crudos",
        },
        {
          value: "1032",
          text: "1032	Elaboración de aceites y grasas de origen vegetal refinados",
        },
        {
          value: "1033",
          text: "1033	Elaboración de aceites y grasas de origen animal",
        },
        { value: "1040", text: "1040 Elaboración de productos lácteos" },
        { value: "1051", text: "1051 Elaboración de productos de molinería" },
        {
          value: "1052",
          text: "1052	Elaboración de almidones y productos derivados del almidón",
        },
        { value: "1061", text: "1061 Trilla de café" },
        {
          value: "1062",
          text: "1062 Descafeinado, tostión y molienda del café",
        },
        { value: "1063", text: "1063 Otros derivados del café" },
        { value: "1071", text: "1071 Elaboración y refinación de azúcar" },
        { value: "1072", text: "1072 Elaboración de panela	" },
        { value: "1081", text: "1081 Elaboración de productos de panadería" },
        {
          value: "1082",
          text: "1082	Elaboración de cacao, chocolate y productos de confitería",
        },
        {
          value: "1083",
          text: "1083	Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares",
        },
        {
          value: "1084",
          text: "1084 Elaboración de comidas y platos preparados",
        },
        {
          value: "1089",
          text: "1089	Elaboración de otros productos alimenticios n.c.p.",
        },
        {
          value: "1090",
          text: "1090	Elaboración de alimentos preparados para animales",
        },
        {
          value: "1101",
          text: "1101	Destilación, rectificación y mezcla de bebidas alcohólicas",
        },
        {
          value: "1102",
          text: "1102	Elaboración de bebidas fermentadas no destiladas",
        },
        {
          value: "1103",
          text: "1103	Producción de malta, elaboración de cervezas y otras bebidas malteadas",
        },
        {
          value: "1104",
          text: "1104	Elaboración de bebidas no alcohólicas, producción de aguas minerales y otras aguas embotelladas",
        },
        { value: "1200", text: "1200 Elaboración de productos de tabaco" },
        {
          value: "1311",
          text: "1311 Preparación e hilatura de fibras textiles",
        },
        { value: "1312", text: "1312 Tejeduría de productos textiles" },
        { value: "1313", text: "1313 Acabado de productos textiles" },
        {
          value: "1391",
          text: "1391 Fabricación de tejidos de punto y ganchillo",
        },
        {
          value: "1392",
          text: "1392	Confección de artículos con materiales textiles, excepto prendas de vestir",
        },
        {
          value: "1393",
          text: "1393	Fabricación de tapetes y alfombras para pisos",
        },
        {
          value: "1394",
          text: "1394	Fabricación de cuerdas, cordeles, cables, bramantes y redes",
        },
        {
          value: "1399",
          text: "1399	Fabricación de otros artículos textiles n.c.p.",
        },
        {
          value: "1410",
          text: "1410	Confección de prendas de vestir, excepto prendas de piel",
        },
        { value: "1420", text: "1420 Fabricación de artículos de piel" },
        {
          value: "1430",
          text: "1430	Fabricación de artículos de punto y ganchillo",
        },
        {
          value: "1511",
          text: "1511	Curtido y recurtido de cueros; recurtido y teñido de pieles",
        },
        {
          value: "1512",
          text: "1512	Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería",
        },
        {
          value: "1513",
          text: "1513	Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales",
        },
        {
          value: "1521",
          text: "1521	Fabricación de calzado de cuero y piel, con cualquier tipo de suela",
        },
        {
          value: "1522",
          text: "1522	Fabricación de otros tipos de calzado, excepto calzado de cuero y piel",
        },
        { value: "1523", text: "1523 Fabricación de partes del calzado" },
        {
          value: "1610",
          text: "1610	Aserrado, acepillado e impregnación de la madera",
        },
        {
          value: "1620",
          text: "1620	Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles",
        },
        {
          value: "1630",
          text: "1630	Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción",
        },
        { value: "1640", text: "1640 Fabricación de recipientes de madera" },
        {
          value: "1690",
          text: "1690 Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería",
        },
        {
          value: "1701",
          text: "1701	Fabricación de pulpas (pastas) celulósicas; papel y cartón",
        },
        {
          value: "1702",
          text: "1702	Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón",
        },
        {
          value: "1709",
          text: "1709	Fabricación de otros artículos de papel y cartón",
        },
        { value: "1811", text: "1811 Actividades de impresión" },
        {
          value: "1812",
          text: "1812	Actividades de servicios relacionados con la impresión",
        },
        {
          value: "1820",
          text: "1820	Producción de copias a partir de grabaciones originales",
        },
        {
          value: "1910",
          text: "1910 Fabricación de productos de hornos de coque",
        },
        {
          value: "1921",
          text: "1921	Fabricación de productos de la refinación del petróleo",
        },
        { value: "1922", text: "1922 Actividad de mezcla de combustibles	" },
        {
          value: "2011",
          text: "2011	Fabricación de sustancias y productos químicos básicos",
        },
        {
          value: "2012",
          text: "2012	Fabricación de abonos y compuestos inorgánicos nitrogenados",
        },
        {
          value: "2013",
          text: "2013 Fabricación de plásticos en formas primarias",
        },
        {
          value: "2014",
          text: "2014	Fabricación de caucho sintético en formas primarias",
        },
        {
          value: "2021",
          text: "2021	Fabricación de plaguicidas y otros productos químicos de uso agropecuario",
        },
        {
          value: "2022",
          text: "2022	Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas",
        },
        {
          value: "2023",
          text: "2023	Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador",
        },
        {
          value: "2029",
          text: "2029	Fabricación de otros productos químicos n.c.p.",
        },
        {
          value: "2030",
          text: "2030	Fabricación de fibras sintéticas y artificiales",
        },
        {
          value: "2100",
          text: "2100	Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico",
        },
        {
          value: "2211",
          text: "2211	Fabricación de llantas y neumáticos de caucho",
        },
        { value: "2212", text: "2212 Reencauche de llantas usadas" },
        {
          value: "2219",
          text: "2219	Fabricación de formas básicas de caucho y otros productos de caucho n.c.p.",
        },
        {
          value: "2221",
          text: "2221 Fabricación de formas básicas de plástico",
        },
        {
          value: "2229",
          text: "2229 Fabricación de artículos de plástico n.c.p.",
        },
        {
          value: "2310",
          text: "2310 Fabricación de vidrio y productos de vidrio",
        },
        { value: "2391", text: "2391 Fabricación de productos refractarios" },
        {
          value: "2392",
          text: "2392	Fabricación de materiales de arcilla para la construcción",
        },
        {
          value: "2393",
          text: "2393	Fabricación de otros productos de cerámica y porcelana",
        },
        { value: "2394", text: "2394 Fabricación de cemento, cal y yeso" },
        {
          value: "2395",
          text: "2395	Fabricación de artículos de hormigón, cemento y yeso",
        },
        { value: "2396", text: "2396 Corte, tallado y acabado de la piedra" },
        {
          value: "2399",
          text: "2399	Fabricación de otros productos minerales no metálicos n.c.p.",
        },
        { value: "2410", text: "2410 Industrias básicas de hierro y de acero	" },
        { value: "2421", text: "2421 Industrias básicas de metales preciosos" },
        {
          value: "2429",
          text: "2429	Industrias básicas de otros metales no ferrosos",
        },
        { value: "2431", text: "2431 Fundición de hierro y de acero" },
        { value: "2432", text: "2432 Fundición de metales no ferrosos" },
        {
          value: "2511",
          text: "2511	Fabricación de productos metálicos para uso estructural",
        },
        {
          value: "2512",
          text: "2512	Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o el transporte de mercancías",
        },
        {
          value: "2513",
          text: "2513	Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central",
        },
        { value: "2520", text: "2520 Fabricación de armas y municiones" },
        {
          value: "2591",
          text: "2591	Forja, prensado, estampado y laminado de metal; pulvimetalurgia",
        },
        {
          value: "2592",
          text: "2592	Tratamiento y revestimiento de metales; mecanizado",
        },
        {
          value: "2593",
          text: "2593	Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
        },
        {
          value: "2599",
          text: "2599	Fabricación de otros productos elaborados de metal n.c.p.",
        },
        {
          value: "2610",
          text: "2610	Fabricación de componentes y tableros electrónicos",
        },
        {
          value: "2620",
          text: "2620	Fabricación de computadoras y de equipo periférico",
        },
        { value: "2630", text: "2630 Fabricación de equipos de comunicación" },
        {
          value: "2640",
          text: "2640	Fabricación de aparatos electrónicos de consumo",
        },
        {
          value: "2651",
          text: "2651	Fabricación de equipo de medición, prueba, navegación y control",
        },
        { value: "2652", text: "2652 Fabricación de relojes" },
        {
          value: "2660",
          text: "2660	Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico",
        },
        {
          value: "2670",
          text: "2670	Fabricación de instrumentos ópticos y equipo fotográfico",
        },
        {
          value: "2680",
          text: "2680	Fabricación de medios magnéticos y ópticos para almacenamiento de datos",
        },
        {
          value: "2711",
          text: "2711	Fabricación de motores, generadores y transformadores eléctricos",
        },
        {
          value: "2712",
          text: "2712	Fabricación de aparatos de distribución y control de la energía eléctrica",
        },
        {
          value: "2720",
          text: "2720	Fabricación de pilas, baterías y acumuladores eléctricos",
        },
        {
          value: "2731",
          text: "2731	Fabricación de hilos y cables eléctricos y de fibra óptica",
        },
        { value: "2732", text: "2732 Fabricación de dispositivos de cableado" },
        {
          value: "2740",
          text: "2740	Fabricación de equipos eléctricos de iluminación",
        },
        {
          value: "2750",
          text: "2750 Fabricación de aparatos de uso doméstico",
        },
        {
          value: "2790",
          text: "2790	Fabricación de otros tipos de equipo eléctrico n.c.p.",
        },
        {
          value: "2811",
          text: "2811	Fabricación de motores, turbinas, y partes para motores de combustión interna",
        },
        {
          value: "2812",
          text: "2812	Fabricación de equipos de potencia hidráulica y neumática",
        },
        {
          value: "2813",
          text: "2813	Fabricación de otras bombas, compresores, grifos y válvulas",
        },
        {
          value: "2814",
          text: "2814	Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
        },
        {
          value: "2815",
          text: "2815	Fabricación de hornos, hogares y quemadores industriales",
        },
        {
          value: "2816",
          text: "2816	Fabricación de equipo de elevación y manipulación",
        },
        {
          value: "2817",
          text: "2817	Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)",
        },
        {
          value: "2818",
          text: "2818	Fabricación de herramientas manuales con motor",
        },
        {
          value: "2819",
          text: "2819	Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p.",
        },
        {
          value: "2821",
          text: "2821	Fabricación de maquinaria agropecuaria y forestal",
        },
        {
          value: "2822",
          text: "2822	Fabricación de máquinas formadoras de metal y de máquinas herramienta",
        },
        {
          value: "2823",
          text: "2823 Fabricación de maquinaria para la metalurgia",
        },
        {
          value: "2824",
          text: "2824	Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción",
        },
        {
          value: "2825",
          text: "2825	Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
        },
        {
          value: "2826",
          text: "2826	Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
        },
        {
          value: "2829",
          text: "2829	Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p.",
        },
        {
          value: "2910",
          text: "2910	Fabricación de vehículos automotores y sus motores",
        },
        {
          value: "2920",
          text: "2920	Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
        },
        {
          value: "2930",
          text: "2930	Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
        },
        {
          value: "3011",
          text: "3011	Construcción de barcos y de estructuras flotantes",
        },
        {
          value: "3012",
          text: "3012	Construcción de embarcaciones de recreo y deporte",
        },
        {
          value: "3020",
          text: "3020	Fabricación de locomotoras y de material rodante para ferrocarriles",
        },
        {
          value: "3030",
          text: "3030	Fabricación de aeronaves, naves espaciales y de maquinaria conexa",
        },
        {
          value: "3040",
          text: "3040	Fabricación de vehículos militares de combate",
        },
        { value: "3091", text: "3091 Fabricación de motocicletas" },
        {
          value: "3092",
          text: "3092	Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad",
        },
        {
          value: "3099",
          text: "3099	Fabricación de otros tipos de equipo de transporte n.c.p.",
        },
        { value: "3110", text: "3110 Fabricación de muebles" },
        { value: "3120", text: "3120 Fabricación de colchones y somieres" },
        {
          value: "3210",
          text: "3210	Fabricación de joyas, bisutería y artículos conexos",
        },
        { value: "3220", text: "3220 Fabricación de instrumentos musicales" },
        {
          value: "3230",
          text: "3230	Fabricación de artículos y equipo para la práctica del deporte",
        },
        {
          value: "3240",
          text: "3240	Fabricación de juegos, juguetes y rompecabezas",
        },
        {
          value: "3250",
          text: "3250	Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)",
        },
        { value: "3290", text: "3290 Otras industrias manufactureras n.c.p." },
        {
          value: "3311",
          text: "3311	Mantenimiento y reparación especializado de productos elaborados en metal",
        },
        {
          value: "3312",
          text: "3312	Mantenimiento y reparación especializado de maquinaria y equipo",
        },
        {
          value: "3313",
          text: "3313	Mantenimiento y reparación especializado de equipo electrónico y óptico",
        },
        {
          value: "3314",
          text: "3314	Mantenimiento y reparación especializado de equipo eléctrico",
        },
        {
          value: "3315",
          text: "3315	Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas",
        },
        {
          value: "3319",
          text: "3319	Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p.",
        },
        {
          value: "3320",
          text: "3320	Instalación especializada de maquinaria y equipo industrial",
        },
      ],
      optionsciudades: [],
      optionsciudadesapo: [], //CAMBIO
      optionstiponoti: [
        { value: "Urbana", text: "Urbana" },
        { value: "Rural", text: "Rural" },
      ],
      optionstipotel: [
        { value: "Teléfono Fijo", text: "Teléfono Fijo" },
        { value: "Celular", text: "Celular" },
      ],
      optionstipovia: [
        { value: "Autopista AU", text: "Autopista AU" },
        { value: "Avenida AV", text: "Avenida AV" },
        { value: "Avenida Calle AC", text: "Avenida Calle AC" },
        { value: "Avenida Carrera AK", text: "Avenida Carrera AK" },
        { value: "Bulevar BL", text: "Bulevar BL" },
        { value: "Calle CL", text: "Calle CL" },
        { value: "Carrera KR", text: "Carrera KR" },
        { value: "Carretera CT", text: "Carretera CT" },
        { value: "Circular CQ", text: "Circular CQ" },
        { value: "Circunvalar CV", text: "Circunvalar CV" },
        { value: "Cuentas Corridas CC", text: "Cuentas Corridas CC" },
        { value: "Diagonal DG", text: "Diagonal DG" },
        { value: "Pasaje PJ", text: "Pasaje PJ" },
        { value: "Paseo PS", text: "Paseo PS" },
        { value: "Peatonal PT", text: "Peatonal PT" },
        { value: "Transversal TV", text: "Transversal TV" },
        { value: "Troncal TC", text: "Troncal TC" },
        { value: "Variante VT", text: "Variante VT" },
        { value: "Vía VI", text: "Vía VI" },
      ],
      optionsusosuelo: [
        { value: "Especial", text: "Especial" },
        { value: "Residencial", text: "Residencial" },
        { value: "Comercial", text: "Comercial" },
        { value: "Industrial", text: "Industrial" },
        { value: "Dotacional", text: "Dotacional" },
      ],
      optionciiu: [
        {
          value:
            "0111 - Cultivo de cereales (excepto arroz), legumbres y semillas oleaginosas",
          text: "0111 - Cultivo de cereales (excepto arroz), legumbres y semillas oleaginosas",
        },
        { value: "0112 - Cultivo de arroz", text: "0112 - Cultivo de arroz" },
        {
          value: "0113 - Cultivo de hortalizas, raíces y tubérculos",
          text: "0113 - Cultivo de hortalizas, raíces y tubérculos",
        },
        { value: "0114 - Cultivo de tabaco", text: "0114 - Cultivo de tabaco" },
        {
          value: "0115 - Cultivo de plantas textiles",
          text: "0115 - Cultivo de plantas textiles",
        },
        {
          value: "0119 - Otros cultivos transitorios n.c.p.",
          text: "0119 - Otros cultivos transitorios n.c.p.",
        },
        {
          value: "0121 - Cultivo de frutas tropicales y subtropicales",
          text: "0121 - Cultivo de frutas tropicales y subtropicales",
        },
        {
          value: "0122 - Cultivo de plátano y banano",
          text: "0122 - Cultivo de plátano y banano",
        },
        { value: "0123 - Cultivo de café", text: "0123 - Cultivo de café" },
        {
          value: "0124 - Cultivo de caña de azúcar",
          text: "0124 - Cultivo de caña de azúcar",
        },
        {
          value: "0125 - Cultivo de flor de corte",
          text: "0125 - Cultivo de flor de corte",
        },
        {
          value:
            "0126 - Cultivo de palma para aceite (palma africana) y otros frutos oleaginosos",
          text: "0126 - Cultivo de palma para aceite (palma africana) y otros frutos oleaginosos",
        },
        {
          value: "0127 - Cultivo de plantas con las que se preparan bebidas",
          text: "0127 - Cultivo de plantas con las que se preparan bebidas",
        },
        {
          value:
            "0128 - Cultivo de especias y de plantas aromáticas y medicinales",
          text: "0128 - Cultivo de especias y de plantas aromáticas y medicinales",
        },
        {
          value: "0129 - Otros cultivos permanentes n.c.p.",
          text: "0129 - Otros cultivos permanentes n.c.p.",
        },
        {
          value:
            "0130 - Propagación de plantas (actividades de los viveros, excepto viveros forestales)",
          text: "0130 - Propagación de plantas (actividades de los viveros, excepto viveros forestales)",
        },
        {
          value: "0141 - Cría de ganado bovino y bufalino",
          text: "0141 - Cría de ganado bovino y bufalino",
        },
        {
          value: "0142 - Cría de caballos y otros equinos",
          text: "0142 - Cría de caballos y otros equinos",
        },
        {
          value: "0143 - Cría de ovejas y cabras",
          text: "0143 - Cría de ovejas y cabras",
        },
        {
          value: "0144 - Cría de ganado porcino",
          text: "0144 - Cría de ganado porcino",
        },
        {
          value: "0145 - Cría de aves de corral",
          text: "0145 - Cría de aves de corral",
        },
        {
          value: "0149 - Cría de otros animales n.c.p.",
          text: "0149 - Cría de otros animales n.c.p.",
        },
        {
          value: "0150 - Explotación mixta (agrícola y pecuaria)",
          text: "0150 - Explotación mixta (agrícola y pecuaria)",
        },
        {
          value: "0161 - Actividades de apoyo a la agricultura",
          text: "0161 - Actividades de apoyo a la agricultura",
        },
        {
          value: "0162 - Actividades de apoyo a la ganadería",
          text: "0162 - Actividades de apoyo a la ganadería",
        },
        {
          value: "0163 - Actividades posteriores a la cosecha",
          text: "0163 - Actividades posteriores a la cosecha",
        },
        {
          value: "0164 - Tratamiento de semillas para propagación",
          text: "0164 - Tratamiento de semillas para propagación",
        },
        {
          value:
            "0170 - Caza ordinaria y mediante trampas y actividades de servicios conexas",
          text: "0170 - Caza ordinaria y mediante trampas y actividades de servicios conexas",
        },
        {
          value: "0210 - Silvicultura y otras actividades forestales",
          text: "0210 - Silvicultura y otras actividades forestales",
        },
        {
          value: "0220 - Extracción de madera",
          text: "0220 - Extracción de madera",
        },
        {
          value:
            "0230 - Recolección de productos forestales diferentes a la madera",
          text: "0230 - Recolección de productos forestales diferentes a la madera",
        },
        {
          value: "0240 - Servicios de apoyo a la silvicultura",
          text: "0240 - Servicios de apoyo a la silvicultura",
        },
        { value: "0311 - Pesca marítima", text: "0311 - Pesca marítima" },
        {
          value: "0312 - Pesca de agua dulce",
          text: "0312 - Pesca de agua dulce",
        },
        {
          value: "0321 - Acuicultura marítima",
          text: "0321 - Acuicultura marítima",
        },
        {
          value: "0322 - Acuicultura de agua dulce",
          text: "0322 - Acuicultura de agua dulce",
        },
        {
          value: "0510 - Extracción de hulla (carbón de piedra)",
          text: "0510 - Extracción de hulla (carbón de piedra)",
        },
        {
          value: "0520 - Extracción de carbón lignito",
          text: "0520 - Extracción de carbón lignito",
        },
        {
          value: "0610 - Extracción de petróleo crudo",
          text: "0610 - Extracción de petróleo crudo",
        },
        {
          value: "0620 - Extracción de gas natural",
          text: "0620 - Extracción de gas natural",
        },
        {
          value: "0710 - Extracción de minerales de hierro",
          text: "0710 - Extracción de minerales de hierro",
        },
        {
          value: "0721 - Extracción de minerales de uranio y de torio",
          text: "0721 - Extracción de minerales de uranio y de torio",
        },
        {
          value: "0722 - Extracción de oro y otros metales preciosos",
          text: "0722 - Extracción de oro y otros metales preciosos",
        },
        {
          value: "0723 - Extracción de minerales de níquel",
          text: "0723 - Extracción de minerales de níquel",
        },
        {
          value:
            "0729 - Extracción de otros minerales metalíferos no ferrosos n.c.p.",
          text: "0729 - Extracción de otros minerales metalíferos no ferrosos n.c.p.",
        },
        {
          value:
            "0811 - Extracción de piedra, arena, arcillas comunes, yeso y anhidrita",
          text: "0811 - Extracción de piedra, arena, arcillas comunes, yeso y anhidrita",
        },
        {
          value:
            "0812 - Extracción de arcillas de uso industrial, caliza, caolín y bentonitas",
          text: "0812 - Extracción de arcillas de uso industrial, caliza, caolín y bentonitas",
        },
        {
          value:
            "0820 - Extracción de esmeraldas, piedras preciosas y semipreciosas",
          text: "0820 - Extracción de esmeraldas, piedras preciosas y semipreciosas",
        },
        {
          value:
            "0891 - Extracción de minerales para la fabricación de abonos y productos químicos",
          text: "0891 - Extracción de minerales para la fabricación de abonos y productos químicos",
        },
        {
          value: "0892 - Extracción de halita (sal)",
          text: "0892 - Extracción de halita (sal)",
        },
        {
          value: "0899 - Extracción de otros minerales no metálicos n.c.p.",
          text: "0899 - Extracción de otros minerales no metálicos n.c.p.",
        },
        {
          value:
            "0910 - Actividades de apoyo para la extracción de petróleo y de gas natural",
          text: "0910 - Actividades de apoyo para la extracción de petróleo y de gas natural",
        },
        {
          value:
            "0990 - Actividades de apoyo para otras actividades de explotación de minas y canteras",
          text: "0990 - Actividades de apoyo para otras actividades de explotación de minas y canteras",
        },
        {
          value:
            "1011 - Procesamiento y conservación de carne y productos cárnicos",
          text: "1011 - Procesamiento y conservación de carne y productos cárnicos",
        },
        {
          value:
            "1012 - Procesamiento y conservación de pescados, crustáceos y moluscos",
          text: "1012 - Procesamiento y conservación de pescados, crustáceos y moluscos",
        },
        {
          value:
            "1020 - Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos",
          text: "1020 - Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos",
        },
        {
          value: "1031 - Extracción de aceites de origen vegetal crudos",
          text: "1031 - Extracción de aceites de origen vegetal crudos",
        },
        {
          value:
            "1032 - Elaboración de aceites y grasas de origen vegetal refinados",
          text: "1032 - Elaboración de aceites y grasas de origen vegetal refinados",
        },
        {
          value: "1033 - Elaboración de aceites y grasas de origen animal",
          text: "1033 - Elaboración de aceites y grasas de origen animal",
        },
        {
          value: "1040 - Elaboración de productos lácteos",
          text: "1040 - Elaboración de productos lácteos",
        },
        {
          value: "1051 - Elaboración de productos de molinería",
          text: "1051 - Elaboración de productos de molinería",
        },
        {
          value:
            "1052 - Elaboración de almidones y productos derivados del almidón",
          text: "1052 - Elaboración de almidones y productos derivados del almidón",
        },
        { value: "1061 - Trilla de café", text: "1061 - Trilla de café" },
        {
          value: "1062 - Descafeinado, tostión y molienda del café",
          text: "1062 - Descafeinado, tostión y molienda del café",
        },
        {
          value: "1063 - Otros derivados del café",
          text: "1063 - Otros derivados del café",
        },
        {
          value: "1071 - Elaboración y refinación de azúcar",
          text: "1071 - Elaboración y refinación de azúcar",
        },
        {
          value: "1072 - Elaboración de panela",
          text: "1072 - Elaboración de panela",
        },
        {
          value: "1081 - Elaboración de productos de panadería",
          text: "1081 - Elaboración de productos de panadería",
        },
        {
          value:
            "1082 - Elaboración de cacao, chocolate y productos de confitería",
          text: "1082 - Elaboración de cacao, chocolate y productos de confitería",
        },
        {
          value:
            "1083 - Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares",
          text: "1083 - Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares",
        },
        {
          value: "1084 - Elaboración de comidas y platos preparados",
          text: "1084 - Elaboración de comidas y platos preparados",
        },
        {
          value: "1089 - Elaboración de otros productos alimenticios n.c.p.",
          text: "1089 - Elaboración de otros productos alimenticios n.c.p.",
        },
        {
          value: "1090 - Elaboración de alimentos preparados para animales",
          text: "1090 - Elaboración de alimentos preparados para animales",
        },
        {
          value:
            "1101 - Destilación, rectificación y mezcla de bebidas alcohólicas",
          text: "1101 - Destilación, rectificación y mezcla de bebidas alcohólicas",
        },
        {
          value: "1102 - Elaboración de bebidas fermentadas no destiladas",
          text: "1102 - Elaboración de bebidas fermentadas no destiladas",
        },
        {
          value:
            "1103 - Producción de malta, elaboración de cervezas y otras bebidas malteadas",
          text: "1103 - Producción de malta, elaboración de cervezas y otras bebidas malteadas",
        },
        {
          value:
            "1104 - Elaboración de bebidas no alcohólicas, producción de aguas minerales y otras aguas embotelladas",
          text: "1104 - Elaboración de bebidas no alcohólicas, producción de aguas minerales y otras aguas embotelladas",
        },
        {
          value: "1200 - Elaboración de productos de tabaco",
          text: "1200 - Elaboración de productos de tabaco",
        },
        {
          value: "1311 - Preparación e hilatura de fibras textiles",
          text: "1311 - Preparación e hilatura de fibras textiles",
        },
        {
          value: "1312 - Tejeduría de productos textiles",
          text: "1312 - Tejeduría de productos textiles",
        },
        {
          value: "1313 - Acabado de productos textiles",
          text: "1313 - Acabado de productos textiles",
        },
        {
          value: "1391 - Fabricación de tejidos de punto y ganchillo",
          text: "1391 - Fabricación de tejidos de punto y ganchillo",
        },
        {
          value:
            "1392 - Confección de artículos con materiales textiles, excepto prendas de vestir",
          text: "1392 - Confección de artículos con materiales textiles, excepto prendas de vestir",
        },
        {
          value: "1393 - Fabricación de tapetes y alfombras para pisos",
          text: "1393 - Fabricación de tapetes y alfombras para pisos",
        },
        {
          value:
            "1394 - Fabricación de cuerdas, cordeles, cables, bramantes y redes",
          text: "1394 - Fabricación de cuerdas, cordeles, cables, bramantes y redes",
        },
        {
          value: "1399 - Fabricación de otros artículos textiles n.c.p.",
          text: "1399 - Fabricación de otros artículos textiles n.c.p.",
        },
        {
          value:
            "1410 - Confección de prendas de vestir, excepto prendas de piel",
          text: "1410 - Confección de prendas de vestir, excepto prendas de piel",
        },
        {
          value: "1420 - Fabricación de artículos de piel",
          text: "1420 - Fabricación de artículos de piel",
        },
        {
          value: "1430 - Fabricación de artículos de punto y ganchillo",
          text: "1430 - Fabricación de artículos de punto y ganchillo",
        },
        {
          value:
            "1511 - Curtido y recurtido de cueros; recurtido y teñido de pieles",
          text: "1511 - Curtido y recurtido de cueros; recurtido y teñido de pieles",
        },
        {
          value:
            "1512 - Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería",
          text: "1512 - Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería",
        },
        {
          value:
            "1513 - Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales",
          text: "1513 - Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales",
        },
        {
          value:
            "1521 - Fabricación de calzado de cuero y piel, con cualquier tipo de suela",
          text: "1521 - Fabricación de calzado de cuero y piel, con cualquier tipo de suela",
        },
        {
          value:
            "1522 - Fabricación de otros tipos de calzado, excepto calzado de cuero y piel",
          text: "1522 - Fabricación de otros tipos de calzado, excepto calzado de cuero y piel",
        },
        {
          value: "1523 - Fabricación de partes del calzado",
          text: "1523 - Fabricación de partes del calzado",
        },
        {
          value: "1610 - Aserrado, acepillado e impregnación de la madera",
          text: "1610 - Aserrado, acepillado e impregnación de la madera",
        },
        {
          value:
            "1620 - Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles",
          text: "1620 - Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles",
        },
        {
          value:
            "1630 - Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción",
          text: "1630 - Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción",
        },
        {
          value: "1640 - Fabricación de recipientes de madera",
          text: "1640 - Fabricación de recipientes de madera",
        },
        {
          value:
            "1690 - Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería",
          text: "1690 - Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería",
        },
        {
          value:
            "1701 - Fabricación de pulpas (pastas) celulósicas; papel y cartón",
          text: "1701 - Fabricación de pulpas (pastas) celulósicas; papel y cartón",
        },
        {
          value:
            "1702 - Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón",
          text: "1702 - Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón",
        },
        {
          value: "1709 - Fabricación de otros artículos de papel y cartón",
          text: "1709 - Fabricación de otros artículos de papel y cartón",
        },
        {
          value: "1811 - Actividades de impresión",
          text: "1811 - Actividades de impresión",
        },
        {
          value:
            "1812 - Actividades de servicios relacionados con la impresión",
          text: "1812 - Actividades de servicios relacionados con la impresión",
        },
        {
          value:
            "1820 - Producción de copias a partir de grabaciones originales",
          text: "1820 - Producción de copias a partir de grabaciones originales",
        },
        {
          value: "1910 - Fabricación de productos de hornos de coque",
          text: "1910 - Fabricación de productos de hornos de coque",
        },
        {
          value:
            "1921 - Fabricación de productos de la refinación del petróleo",
          text: "1921 - Fabricación de productos de la refinación del petróleo",
        },
        {
          value: "1922 - Actividad de mezcla de combustibles",
          text: "1922 - Actividad de mezcla de combustibles",
        },
        {
          value:
            "2011 - Fabricación de sustancias y productos químicos básicos",
          text: "2011 - Fabricación de sustancias y productos químicos básicos",
        },
        {
          value:
            "2012 - Fabricación de abonos y compuestos inorgánicos nitrogenados",
          text: "2012 - Fabricación de abonos y compuestos inorgánicos nitrogenados",
        },
        {
          value: "2013 - Fabricación de plásticos en formas primarias",
          text: "2013 - Fabricación de plásticos en formas primarias",
        },
        {
          value: "2014 - Fabricación de caucho sintético en formas primarias",
          text: "2014 - Fabricación de caucho sintético en formas primarias",
        },
        {
          value:
            "2021 - Fabricación de plaguicidas y otros productos químicos de uso agropecuario",
          text: "2021 - Fabricación de plaguicidas y otros productos químicos de uso agropecuario",
        },
        {
          value:
            "2022 - Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas",
          text: "2022 - Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas",
        },
        {
          value:
            "2023 - Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador",
          text: "2023 - Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador",
        },
        {
          value: "2029 - Fabricación de otros productos químicos n.c.p.",
          text: "2029 - Fabricación de otros productos químicos n.c.p.",
        },
        {
          value: "2030 - Fabricación de fibras sintéticas y artificiales",
          text: "2030 - Fabricación de fibras sintéticas y artificiales",
        },
        {
          value:
            "2100 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico",
          text: "2100 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico",
        },
        {
          value: "2211 - Fabricación de llantas y neumáticos de caucho",
          text: "2211 - Fabricación de llantas y neumáticos de caucho",
        },
        {
          value: "2212 - Reencauche de llantas usadas",
          text: "2212 - Reencauche de llantas usadas",
        },
        {
          value:
            "2219 - Fabricación de formas básicas de caucho y otros productos de caucho n.c.p.",
          text: "2219 - Fabricación de formas básicas de caucho y otros productos de caucho n.c.p.",
        },
        {
          value: "2221 - Fabricación de formas básicas de plástico",
          text: "2221 - Fabricación de formas básicas de plástico",
        },
        {
          value: "2229 - Fabricación de artículos de plástico n.c.p.",
          text: "2229 - Fabricación de artículos de plástico n.c.p.",
        },
        {
          value: "2310 - Fabricación de vidrio y productos de vidrio",
          text: "2310 - Fabricación de vidrio y productos de vidrio",
        },
        {
          value: "2391 - Fabricación de productos refractarios",
          text: "2391 - Fabricación de productos refractarios",
        },
        {
          value:
            "2392 - Fabricación de materiales de arcilla para la construcción",
          text: "2392 - Fabricación de materiales de arcilla para la construcción",
        },
        {
          value:
            "2393 - Fabricación de otros productos de cerámica y porcelana",
          text: "2393 - Fabricación de otros productos de cerámica y porcelana",
        },
        {
          value: "2394 - Fabricación de cemento, cal y yeso",
          text: "2394 - Fabricación de cemento, cal y yeso",
        },
        {
          value: "2395 - Fabricación de artículos de hormigón, cemento y yeso",
          text: "2395 - Fabricación de artículos de hormigón, cemento y yeso",
        },
        {
          value: "2396 - Corte, tallado y acabado de la piedra",
          text: "2396 - Corte, tallado y acabado de la piedra",
        },
        {
          value:
            "2399 - Fabricación de otros productos minerales no metálicos n.c.p.",
          text: "2399 - Fabricación de otros productos minerales no metálicos n.c.p.",
        },
        {
          value: "2410 - Industrias básicas de hierro y de acero",
          text: "2410 - Industrias básicas de hierro y de acero",
        },
        {
          value: "2421 - Industrias básicas de metales preciosos",
          text: "2421 - Industrias básicas de metales preciosos",
        },
        {
          value: "2429 - Industrias básicas de otros metales no ferrosos",
          text: "2429 - Industrias básicas de otros metales no ferrosos",
        },
        {
          value: "2431 - Fundición de hierro y de acero",
          text: "2431 - Fundición de hierro y de acero",
        },
        {
          value: "2432 - Fundición de metales no ferrosos",
          text: "2432 - Fundición de metales no ferrosos",
        },
        {
          value:
            "2511 - Fabricación de productos metálicos para uso estructural",
          text: "2511 - Fabricación de productos metálicos para uso estructural",
        },
        {
          value:
            "2512 - Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o el transporte de mercancías",
          text: "2512 - Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o el transporte de mercancías",
        },
        {
          value:
            "2513 - Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central",
          text: "2513 - Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central",
        },
        {
          value: "2520 - Fabricación de armas y municiones",
          text: "2520 - Fabricación de armas y municiones",
        },
        {
          value:
            "2591 - Forja, prensado, estampado y laminado de metal; pulvimetalurgia",
          text: "2591 - Forja, prensado, estampado y laminado de metal; pulvimetalurgia",
        },
        {
          value: "2592 - Tratamiento y revestimiento de metales; mecanizado",
          text: "2592 - Tratamiento y revestimiento de metales; mecanizado",
        },
        {
          value:
            "2593 - Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
          text: "2593 - Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
        },
        {
          value:
            "2599 - Fabricación de otros productos elaborados de metal n.c.p.",
          text: "2599 - Fabricación de otros productos elaborados de metal n.c.p.",
        },
        {
          value: "2610 - Fabricación de componentes y tableros electrónicos",
          text: "2610 - Fabricación de componentes y tableros electrónicos",
        },
        {
          value: "2620 - Fabricación de computadoras y de equipo periférico",
          text: "2620 - Fabricación de computadoras y de equipo periférico",
        },
        {
          value: "2630 - Fabricación de equipos de comunicación",
          text: "2630 - Fabricación de equipos de comunicación",
        },
        {
          value: "2640 - Fabricación de aparatos electrónicos de consumo",
          text: "2640 - Fabricación de aparatos electrónicos de consumo",
        },
        {
          value:
            "2651 - Fabricación de equipo de medición, prueba, navegación y control",
          text: "2651 - Fabricación de equipo de medición, prueba, navegación y control",
        },
        {
          value: "2652 - Fabricación de relojes",
          text: "2652 - Fabricación de relojes",
        },
        {
          value:
            "2660 - Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico",
          text: "2660 - Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico",
        },
        {
          value:
            "2670 - Fabricación de instrumentos ópticos y equipo fotográfico",
          text: "2670 - Fabricación de instrumentos ópticos y equipo fotográfico",
        },
        {
          value:
            "2680 - Fabricación de medios magnéticos y ópticos para almacenamiento de datos",
          text: "2680 - Fabricación de medios magnéticos y ópticos para almacenamiento de datos",
        },
        {
          value:
            "2711 - Fabricación de motores, generadores y transformadores eléctricos",
          text: "2711 - Fabricación de motores, generadores y transformadores eléctricos",
        },
        {
          value:
            "2712 - Fabricación de aparatos de distribución y control de la energía eléctrica",
          text: "2712 - Fabricación de aparatos de distribución y control de la energía eléctrica",
        },
        {
          value:
            "2720 - Fabricación de pilas, baterías y acumuladores eléctricos",
          text: "2720 - Fabricación de pilas, baterías y acumuladores eléctricos",
        },
        {
          value:
            "2731 - Fabricación de hilos y cables eléctricos y de fibra óptica",
          text: "2731 - Fabricación de hilos y cables eléctricos y de fibra óptica",
        },
        {
          value: "2732 - Fabricación de dispositivos de cableado",
          text: "2732 - Fabricación de dispositivos de cableado",
        },
        {
          value: "2740 - Fabricación de equipos eléctricos de iluminación",
          text: "2740 - Fabricación de equipos eléctricos de iluminación",
        },
        {
          value: "2750 - Fabricación de aparatos de uso doméstico",
          text: "2750 - Fabricación de aparatos de uso doméstico",
        },
        {
          value: "2790 - Fabricación de otros tipos de equipo eléctrico n.c.p.",
          text: "2790 - Fabricación de otros tipos de equipo eléctrico n.c.p.",
        },
        {
          value:
            "2811 - Fabricación de motores, turbinas, y partes para motores de combustión interna",
          text: "2811 - Fabricación de motores, turbinas, y partes para motores de combustión interna",
        },
        {
          value:
            "2812 - Fabricación de equipos de potencia hidráulica y neumática",
          text: "2812 - Fabricación de equipos de potencia hidráulica y neumática",
        },
        {
          value:
            "2813 - Fabricación de otras bombas, compresores, grifos y válvulas",
          text: "2813 - Fabricación de otras bombas, compresores, grifos y válvulas",
        },
        {
          value:
            "2814 - Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
          text: "2814 - Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
        },
        {
          value:
            "2815 - Fabricación de hornos, hogares y quemadores industriales",
          text: "2815 - Fabricación de hornos, hogares y quemadores industriales",
        },
        {
          value: "2816 - Fabricación de equipo de elevación y manipulación",
          text: "2816 - Fabricación de equipo de elevación y manipulación",
        },
        {
          value:
            "2817 - Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)",
          text: "2817 - Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)",
        },
        {
          value: "2818 - Fabricación de herramientas manuales con motor",
          text: "2818 - Fabricación de herramientas manuales con motor",
        },
        {
          value:
            "2819 - Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p.",
          text: "2819 - Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p.",
        },
        {
          value: "2821 - Fabricación de maquinaria agropecuaria y forestal",
          text: "2821 - Fabricación de maquinaria agropecuaria y forestal",
        },
        {
          value:
            "2822 - Fabricación de máquinas formadoras de metal y de máquinas herramienta",
          text: "2822 - Fabricación de máquinas formadoras de metal y de máquinas herramienta",
        },
        {
          value: "2823 - Fabricación de maquinaria para la metalurgia",
          text: "2823 - Fabricación de maquinaria para la metalurgia",
        },
        {
          value:
            "2824 - Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción",
          text: "2824 - Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción",
        },
        {
          value:
            "2825 - Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
          text: "2825 - Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
        },
        {
          value:
            "2826 - Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
          text: "2826 - Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
        },
        {
          value:
            "2829 - Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p.",
          text: "2829 - Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p.",
        },
        {
          value: "2910 - Fabricación de vehículos automotores y sus motores",
          text: "2910 - Fabricación de vehículos automotores y sus motores",
        },
        {
          value:
            "2920 - Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
          text: "2920 - Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
        },
        {
          value:
            "2930 - Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
          text: "2930 - Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
        },
        {
          value: "3011 - Construcción de barcos y de estructuras flotantes",
          text: "3011 - Construcción de barcos y de estructuras flotantes",
        },
        {
          value: "3012 - Construcción de embarcaciones de recreo y deporte",
          text: "3012 - Construcción de embarcaciones de recreo y deporte",
        },
        {
          value:
            "3020 - Fabricación de locomotoras y de material rodante para ferrocarriles",
          text: "3020 - Fabricación de locomotoras y de material rodante para ferrocarriles",
        },
        {
          value:
            "3030 - Fabricación de aeronaves, naves espaciales y de maquinaria conexa",
          text: "3030 - Fabricación de aeronaves, naves espaciales y de maquinaria conexa",
        },
        {
          value: "3040 - Fabricación de vehículos militares de combate",
          text: "3040 - Fabricación de vehículos militares de combate",
        },
        {
          value: "3091 - Fabricación de motocicletas",
          text: "3091 - Fabricación de motocicletas",
        },
        {
          value:
            "3092 - Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad",
          text: "3092 - Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad",
        },
        {
          value:
            "3099 - Fabricación de otros tipos de equipo de transporte n.c.p.",
          text: "3099 - Fabricación de otros tipos de equipo de transporte n.c.p.",
        },
        {
          value: "3110 - Fabricación de muebles",
          text: "3110 - Fabricación de muebles",
        },
        {
          value: "3120 - Fabricación de colchones y somieres",
          text: "3120 - Fabricación de colchones y somieres",
        },
        {
          value: "3211 - Fabricación de joyas y artículos conexos",
          text: "3211 - Fabricación de joyas y artículos conexos",
        },
        {
          value: "3212 - Fabricación de bisutería y artículos conexos",
          text: "3212 - Fabricación de bisutería y artículos conexos",
        },
        {
          value: "3220 - Fabricación de instrumentos musicales",
          text: "3220 - Fabricación de instrumentos musicales",
        },
        {
          value:
            "3230 - Fabricación de artículos y equipo para la práctica del deporte",
          text: "3230 - Fabricación de artículos y equipo para la práctica del deporte",
        },
        {
          value: "3240 - Fabricación de juegos, juguetes y rompecabezas",
          text: "3240 - Fabricación de juegos, juguetes y rompecabezas",
        },
        {
          value:
            "3250 - Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)",
          text: "3250 - Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)",
        },
        {
          value: "3290 - Otras industrias manufactureras n.c.p.",
          text: "3290 - Otras industrias manufactureras n.c.p.",
        },
        {
          value:
            "3311 - Mantenimiento y reparación especializado de productos elaborados en metal",
          text: "3311 - Mantenimiento y reparación especializado de productos elaborados en metal",
        },
        {
          value:
            "3312 - Mantenimiento y reparación especializado de maquinaria y equipo",
          text: "3312 - Mantenimiento y reparación especializado de maquinaria y equipo",
        },
        {
          value:
            "3313 - Mantenimiento y reparación especializado de equipo electrónico y óptico",
          text: "3313 - Mantenimiento y reparación especializado de equipo electrónico y óptico",
        },
        {
          value:
            "3314 - Mantenimiento y reparación especializado de equipo eléctrico",
          text: "3314 - Mantenimiento y reparación especializado de equipo eléctrico",
        },
        {
          value:
            "3315 - Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas",
          text: "3315 - Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas",
        },
        {
          value:
            "3319 - Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p.",
          text: "3319 - Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p.",
        },
        {
          value:
            "3320 - Instalación especializada de maquinaria y equipo industrial",
          text: "3320 - Instalación especializada de maquinaria y equipo industrial",
        },
        {
          value: "3511 - Generación de energía eléctrica",
          text: "3511 - Generación de energía eléctrica",
        },
        {
          value: "3512 - Transmisión de energía eléctrica",
          text: "3512 - Transmisión de energía eléctrica",
        },
        {
          value: "3513 - Distribución de energía eléctrica",
          text: "3513 - Distribución de energía eléctrica",
        },
        {
          value: "3514 - Comercialización de energía eléctrica",
          text: "3514 - Comercialización de energía eléctrica",
        },
        {
          value:
            "3520 - Producción de gas; distribución de combustibles gaseosos por tuberías",
          text: "3520 - Producción de gas; distribución de combustibles gaseosos por tuberías",
        },
        {
          value: "3530 - Suministro de vapor y aire acondicionado",
          text: "3530 - Suministro de vapor y aire acondicionado",
        },
        {
          value: "3600 - Captación, tratamiento y distribución de agua",
          text: "3600 - Captación, tratamiento y distribución de agua",
        },
        {
          value: "3700 - Evacuación y tratamiento de aguas residuales",
          text: "3700 - Evacuación y tratamiento de aguas residuales",
        },
        {
          value: "3811 - Recolección de desechos no peligrosos",
          text: "3811 - Recolección de desechos no peligrosos",
        },
        {
          value: "3812 - Recolección de desechos peligrosos",
          text: "3812 - Recolección de desechos peligrosos",
        },
        {
          value: "3821 - Tratamiento y disposición de desechos no peligrosos",
          text: "3821 - Tratamiento y disposición de desechos no peligrosos",
        },
        {
          value: "3822 - Tratamiento y disposición de desechos peligrosos",
          text: "3822 - Tratamiento y disposición de desechos peligrosos",
        },
        {
          value: "3830 - Recuperación de materiales",
          text: "3830 - Recuperación de materiales",
        },
        {
          value:
            "3900 - Actividades de saneamiento ambiental y otros servicios de gestión de desechos",
          text: "3900 - Actividades de saneamiento ambiental y otros servicios de gestión de desechos",
        },
        {
          value: "4111 - Construcción de edificios residenciales",
          text: "4111 - Construcción de edificios residenciales",
        },
        {
          value: "4112 - Construcción de edificios no residenciales",
          text: "4112 - Construcción de edificios no residenciales",
        },
        {
          value: "4210 - Construcción de carreteras y vías de ferrocarril",
          text: "4210 - Construcción de carreteras y vías de ferrocarril",
        },
        {
          value: "4220 - Construcción de proyectos de servicio público",
          text: "4220 - Construcción de proyectos de servicio público",
        },
        {
          value: "4290 - Construcción de otras obras de ingeniería civil",
          text: "4290 - Construcción de otras obras de ingeniería civil",
        },
        { value: "4311 - Demolición", text: "4311 - Demolición" },
        {
          value: "4312 - Preparación del terreno",
          text: "4312 - Preparación del terreno",
        },
        {
          value: "4321 - Instalaciones eléctricas",
          text: "4321 - Instalaciones eléctricas",
        },
        {
          value:
            "4322 - Instalaciones de fontanería, calefacción y aire acondicionado",
          text: "4322 - Instalaciones de fontanería, calefacción y aire acondicionado",
        },
        {
          value: "4329 - Otras instalaciones especializadas",
          text: "4329 - Otras instalaciones especializadas",
        },
        {
          value:
            "4330 - Terminación y acabado de edificios y obras de ingeniería civil",
          text: "4330 - Terminación y acabado de edificios y obras de ingeniería civil",
        },
        {
          value:
            "4390 - Otras actividades especializadas para la construcción de edificios y obras de ingeniería civil",
          text: "4390 - Otras actividades especializadas para la construcción de edificios y obras de ingeniería civil",
        },
        {
          value: "4511 - Comercio de vehículos automotores nuevos",
          text: "4511 - Comercio de vehículos automotores nuevos",
        },
        {
          value: "4512 - Comercio de vehículos automotores usados",
          text: "4512 - Comercio de vehículos automotores usados",
        },
        {
          value: "4520 - Mantenimiento y reparación de vehículos automotores",
          text: "4520 - Mantenimiento y reparación de vehículos automotores",
        },
        {
          value:
            "4530 - Comercio de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
          text: "4530 - Comercio de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
        },
        {
          value:
            "4541 - Comercio de motocicletas y de sus partes, piezas y accesorios",
          text: "4541 - Comercio de motocicletas y de sus partes, piezas y accesorios",
        },
        {
          value:
            "4542 - Mantenimiento y reparación de motocicletas y de sus partes y piezas",
          text: "4542 - Mantenimiento y reparación de motocicletas y de sus partes y piezas",
        },
        {
          value:
            "4610 - Comercio al por mayor a cambio de una retribución o por contrata",
          text: "4610 - Comercio al por mayor a cambio de una retribución o por contrata",
        },
        {
          value:
            "4620 - Comercio al por mayor de materias primas agropecuarias; animales vivos",
          text: "4620 - Comercio al por mayor de materias primas agropecuarias; animales vivos",
        },
        {
          value: "4631 - Comercio al por mayor de productos alimenticios",
          text: "4631 - Comercio al por mayor de productos alimenticios",
        },
        {
          value: "4632 - Comercio al por mayor de bebidas y tabaco",
          text: "4632 - Comercio al por mayor de bebidas y tabaco",
        },
        {
          value:
            "4641 - Comercio al por mayor de productos textiles, productos confeccionados para uso doméstico",
          text: "4641 - Comercio al por mayor de productos textiles, productos confeccionados para uso doméstico",
        },
        {
          value: "4642 - Comercio al por mayor de prendas de vestir",
          text: "4642 - Comercio al por mayor de prendas de vestir",
        },
        {
          value: "4643 - Comercio al por mayor de calzado",
          text: "4643 - Comercio al por mayor de calzado",
        },
        {
          value:
            "4644 - Comercio al por mayor de aparatos y equipo de uso doméstico",
          text: "4644 - Comercio al por mayor de aparatos y equipo de uso doméstico",
        },
        {
          value:
            "4645 - Comercio al por mayor de productos farmacéuticos, medicinales, cosméticos y de tocador",
          text: "4645 - Comercio al por mayor de productos farmacéuticos, medicinales, cosméticos y de tocador",
        },
        {
          value:
            "4649 - Comercio al por mayor de otros utensilios domésticos n.c.p.",
          text: "4649 - Comercio al por mayor de otros utensilios domésticos n.c.p.",
        },
        {
          value:
            "4651 - Comercio al por mayor de computadores, equipo periférico y programas de informática",
          text: "4651 - Comercio al por mayor de computadores, equipo periférico y programas de informática",
        },
        {
          value:
            "4652 - Comercio al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones",
          text: "4652 - Comercio al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones",
        },
        {
          value:
            "4653 - Comercio al por mayor de maquinaria y equipo agropecuarios",
          text: "4653 - Comercio al por mayor de maquinaria y equipo agropecuarios",
        },
        {
          value:
            "4659 - Comercio al por mayor de otros tipos de maquinaria y equipo n.c.p.",
          text: "4659 - Comercio al por mayor de otros tipos de maquinaria y equipo n.c.p.",
        },
        {
          value:
            "4661 - Comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos",
          text: "4661 - Comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos",
        },
        {
          value:
            "4662 - Comercio al por mayor de metales y productos metalíferos",
          text: "4662 - Comercio al por mayor de metales y productos metalíferos",
        },
        {
          value:
            "4663 - Comercio al por mayor de materiales de construcción, artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción",
          text: "4663 - Comercio al por mayor de materiales de construcción, artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción",
        },
        {
          value:
            "4664 - Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario",
          text: "4664 - Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario",
        },
        {
          value:
            "4665 - Comercio al por mayor de desperdicios, desechos y chatarra",
          text: "4665 - Comercio al por mayor de desperdicios, desechos y chatarra",
        },
        {
          value: "4669 - Comercio al por mayor de otros productos n.c.p.",
          text: "4669 - Comercio al por mayor de otros productos n.c.p.",
        },
        {
          value: "4690 - Comercio al por mayor no especializado",
          text: "4690 - Comercio al por mayor no especializado",
        },
        {
          value:
            "4711 - Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por alimentos, bebidas (alcohólicas y no alcohólicas) o tabaco",
          text: "4711 - Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por alimentos, bebidas (alcohólicas y no alcohólicas) o tabaco",
        },
        {
          value:
            "4719 - Comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por productos diferentes de alimentos (víveres en general), bebidas (alcohólicas y no alcohólicas) y tabaco",
          text: "4719 - Comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por productos diferentes de alimentos (víveres en general), bebidas (alcohólicas y no alcohólicas) y tabaco",
        },
        {
          value:
            "4721 - Comercio al por menor de productos agrícolas para el consumo en establecimientos especializados",
          text: "4721 - Comercio al por menor de productos agrícolas para el consumo en establecimientos especializados",
        },
        {
          value:
            "4722 - Comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados",
          text: "4722 - Comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados",
        },
        {
          value:
            "4723 - Comercio al por menor de carnes (incluye aves de corral), productos cárnicos, pescados y productos de mar, en establecimientos especializados",
          text: "4723 - Comercio al por menor de carnes (incluye aves de corral), productos cárnicos, pescados y productos de mar, en establecimientos especializados",
        },
        {
          value:
            "4724 - Comercio al por menor de bebidas y productos del tabaco, en establecimientos especializados",
          text: "4724 - Comercio al por menor de bebidas y productos del tabaco, en establecimientos especializados",
        },
        {
          value:
            "4729 - Comercio al por menor de otros productos alimenticios n.c.p., en establecimientos especializados",
          text: "4729 - Comercio al por menor de otros productos alimenticios n.c.p., en establecimientos especializados",
        },
        {
          value: "4731 - Comercio al por menor de combustible para automotores",
          text: "4731 - Comercio al por menor de combustible para automotores",
        },
        {
          value:
            "4732 - Comercio al por menor de lubricantes (aceites, grasas), aditivos y productos de limpieza para vehículos automotores",
          text: "4732 - Comercio al por menor de lubricantes (aceites, grasas), aditivos y productos de limpieza para vehículos automotores",
        },
        {
          value:
            "4741 - Comercio al por menor de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones en establecimientos especializados",
          text: "4741 - Comercio al por menor de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones en establecimientos especializados",
        },
        {
          value:
            "4742 - Comercio al por menor de equipos y aparatos de sonido y de video, en establecimientos especializados",
          text: "4742 - Comercio al por menor de equipos y aparatos de sonido y de video, en establecimientos especializados",
        },
        {
          value:
            "4751 - Comercio al por menor de productos textiles en establecimientos especializados",
          text: "4751 - Comercio al por menor de productos textiles en establecimientos especializados",
        },
        {
          value:
            "4752 - Comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados",
          text: "4752 - Comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados",
        },
        {
          value:
            "4753 - Comercio al por menor de tapices, alfombras y recubrimientos para paredes y pisos en establecimientos especializados",
          text: "4753 - Comercio al por menor de tapices, alfombras y recubrimientos para paredes y pisos en establecimientos especializados",
        },
        {
          value:
            "4754 - Comercio al por menor de electrodomésticos y gasodomésticos, muebles y equipos de iluminación en establecimientos especializados",
          text: "4754 - Comercio al por menor de electrodomésticos y gasodomésticos, muebles y equipos de iluminación en establecimientos especializados",
        },
        {
          value:
            "4755 - Comercio al por menor de artículos y utensilios de uso doméstico en establecimientos especializados",
          text: "4755 - Comercio al por menor de artículos y utensilios de uso doméstico en establecimientos especializados",
        },
        {
          value:
            "4759 - Comercio al por menor de otros artículos domésticos en establecimientos especializados",
          text: "4759 - Comercio al por menor de otros artículos domésticos en establecimientos especializados",
        },
        {
          value:
            "4761 - Comercio al por menor de libros, periódicos, materiales y artículos de papelería y escritorio, en establecimientos especializados",
          text: "4761 - Comercio al por menor de libros, periódicos, materiales y artículos de papelería y escritorio, en establecimientos especializados",
        },
        {
          value:
            "4762 - Comercio al por menor de artículos deportivos, en establecimientos especializados",
          text: "4762 - Comercio al por menor de artículos deportivos, en establecimientos especializados",
        },
        {
          value:
            "4769 - Comercio al por menor de otros artículos culturales y de entretenimiento n.c.p. en establecimientos especializados",
          text: "4769 - Comercio al por menor de otros artículos culturales y de entretenimiento n.c.p. en establecimientos especializados",
        },
        {
          value:
            "4771 - Comercio al por menor de prendas de vestir y sus accesorios (incluye artículos de piel) en establecimientos especializados",
          text: "4771 - Comercio al por menor de prendas de vestir y sus accesorios (incluye artículos de piel) en establecimientos especializados",
        },
        {
          value:
            "4772 - Comercio al por menor de todo tipo de calzado y artículos de cuero y sucedáneos del cuero en establecimientos especializados",
          text: "4772 - Comercio al por menor de todo tipo de calzado y artículos de cuero y sucedáneos del cuero en establecimientos especializados",
        },
        {
          value:
            "4773 - Comercio al por menor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en establecimientos especializados",
          text: "4773 - Comercio al por menor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en establecimientos especializados",
        },
        {
          value:
            "4774 - Comercio al por menor de otros productos nuevos en establecimientos especializados",
          text: "4774 - Comercio al por menor de otros productos nuevos en establecimientos especializados",
        },
        {
          value: "4775 - Comercio al por menor de artículos de segunda mano",
          text: "4775 - Comercio al por menor de artículos de segunda mano",
        },
        {
          value:
            "4781 - Comercio al por menor de alimentos, bebidas y tabaco, en puestos de venta móviles",
          text: "4781 - Comercio al por menor de alimentos, bebidas y tabaco, en puestos de venta móviles",
        },
        {
          value:
            "4782 - Comercio al por menor de productos textiles, prendas de vestir y calzado, en puestos de venta móviles",
          text: "4782 - Comercio al por menor de productos textiles, prendas de vestir y calzado, en puestos de venta móviles",
        },
        {
          value:
            "4789 - Comercio al por menor de otros productos en puestos de venta móviles",
          text: "4789 - Comercio al por menor de otros productos en puestos de venta móviles",
        },
        {
          value: "4791 - Comercio al por menor realizado a través de internet",
          text: "4791 - Comercio al por menor realizado a través de internet",
        },
        {
          value:
            "4792 - Comercio al por menor realizado a través de casas de venta o por correo",
          text: "4792 - Comercio al por menor realizado a través de casas de venta o por correo",
        },
        {
          value:
            "4799 - Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados",
          text: "4799 - Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados",
        },
        {
          value: "4911 - Transporte férreo de pasajeros",
          text: "4911 - Transporte férreo de pasajeros",
        },
        {
          value: "4912 - Transporte férreo de carga",
          text: "4912 - Transporte férreo de carga",
        },
        {
          value: "4921 - Transporte de pasajeros",
          text: "4921 - Transporte de pasajeros",
        },
        {
          value: "4922 - Transporte mixto",
          text: "4922 - Transporte mixto",
        },
        {
          value: "4923 - Transporte de carga por carretera",
          text: "4923 - Transporte de carga por carretera",
        },
        {
          value: "4930 - Transporte por tuberías",
          text: "4930 - Transporte por tuberías",
        },
        {
          value: "5011 - Transporte de pasajeros marítimo y de cabotaje",
          text: "5011 - Transporte de pasajeros marítimo y de cabotaje",
        },
        {
          value: "5012 - Transporte de carga marítimo y de cabotaje",
          text: "5012 - Transporte de carga marítimo y de cabotaje",
        },
        {
          value: "5021 - Transporte fluvial de pasajeros",
          text: "5021 - Transporte fluvial de pasajeros",
        },
        {
          value: "5022 - Transporte fluvial de carga",
          text: "5022 - Transporte fluvial de carga",
        },
        {
          value: "5111 - Transporte aéreo nacional de pasajeros",
          text: "5111 - Transporte aéreo nacional de pasajeros",
        },
        {
          value: "5112 - Transporte aéreo internacional de pasajeros",
          text: "5112 - Transporte aéreo internacional de pasajeros",
        },
        {
          value: "5121 - Transporte aéreo nacional de carga",
          text: "5121 - Transporte aéreo nacional de carga",
        },
        {
          value: "5122 - Transporte aéreo internacional de carga",
          text: "5122 - Transporte aéreo internacional de carga",
        },
        {
          value: "5210 - Almacenamiento y depósito",
          text: "5210 - Almacenamiento y depósito",
        },
        {
          value:
            "5221 - Actividades de estaciones, vías y servicios complementarios para el transporte terrestre",
          text: "5221 - Actividades de estaciones, vías y servicios complementarios para el transporte terrestre",
        },
        {
          value:
            "5222 - Actividades de puertos y servicios complementarios para el transporte acuático",
          text: "5222 - Actividades de puertos y servicios complementarios para el transporte acuático",
        },
        {
          value:
            "5223 - Actividades de aeropuertos, servicios de navegación aérea y demás actividades conexas al transporte aéreo",
          text: "5223 - Actividades de aeropuertos, servicios de navegación aérea y demás actividades conexas al transporte aéreo",
        },
        {
          value: "5224 - Manipulación de carga",
          text: "5224 - Manipulación de carga",
        },
        {
          value: "5229 - Otras actividades complementarias al transporte",
          text: "5229 - Otras actividades complementarias al transporte",
        },
        {
          value: "5310 - Actividades postales nacionales",
          text: "5310 - Actividades postales nacionales",
        },
        {
          value: "5320 - Actividades de mensajería",
          text: "5320 - Actividades de mensajería",
        },
        {
          value: "5511 - Alojamiento en hoteles",
          text: "5511 - Alojamiento en hoteles",
        },
        {
          value: "5512 - Alojamiento en apartahoteles",
          text: "5512 - Alojamiento en apartahoteles",
        },
        {
          value: "5513 - Alojamiento en centros vacacionales",
          text: "5513 - Alojamiento en centros vacacionales",
        },
        { value: "5514 - Alojamiento rural", text: "5514 - Alojamiento rural" },
        {
          value: "5519 - Otros tipos de alojamiento para visitantes",
          text: "5519 - Otros tipos de alojamiento para visitantes",
        },
        {
          value:
            "5520 - Actividades de zonas de camping y parques para vehículos recreacionales",
          text: "5520 - Actividades de zonas de camping y parques para vehículos recreacionales",
        },
        {
          value: "5530 - Servicio de estancia por horas",
          text: "5530 - Servicio de estancia por horas",
        },
        {
          value: "5590 - Otros tipos de alojamiento n.c.p.",
          text: "5590 - Otros tipos de alojamiento n.c.p.",
        },
        {
          value: "5611 - Expendio a la mesa de comidas preparadas",
          text: "5611 - Expendio a la mesa de comidas preparadas",
        },
        {
          value: "5612 - Expendio por autoservicio de comidas preparadas",
          text: "5612 - Expendio por autoservicio de comidas preparadas",
        },
        {
          value: "5613 - Expendio de comidas preparadas en cafeterías",
          text: "5613 - Expendio de comidas preparadas en cafeterías",
        },
        {
          value: "5619 - Otros tipos de expendio de comidas preparadas n.c.p.",
          text: "5619 - Otros tipos de expendio de comidas preparadas n.c.p.",
        },
        {
          value: "5621 - Catering para eventos",
          text: "5621 - Catering para eventos",
        },
        {
          value: "5629 - Actividades de otros servicios de comidas",
          text: "5629 - Actividades de otros servicios de comidas",
        },
        {
          value:
            "5630 - Expendio de bebidas alcohólicas para el consumo dentro del establecimiento",
          text: "5630 - Expendio de bebidas alcohólicas para el consumo dentro del establecimiento",
        },
        { value: "5811 - Edición de libros", text: "5811 - Edición de libros" },
        {
          value: "5812 - Edición de directorios y listas de correo",
          text: "5812 - Edición de directorios y listas de correo",
        },
        {
          value:
            "5813 - Edición de periódicos, revistas y otras publicaciones periódicas",
          text: "5813 - Edición de periódicos, revistas y otras publicaciones periódicas",
        },
        {
          value: "5819 - Otros trabajos de edición",
          text: "5819 - Otros trabajos de edición",
        },
        {
          value: "5820 - Edición de programas de informática (software)",
          text: "5820 - Edición de programas de informática (software)",
        },
        {
          value:
            "5911 - Actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
          text: "5911 - Actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
        },
        {
          value:
            "5912 - Actividades de postproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
          text: "5912 - Actividades de postproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
        },
        {
          value:
            "5913 - Actividades de distribución de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
          text: "5913 - Actividades de distribución de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
        },
        {
          value:
            "5914 - Actividades de exhibición de películas cinematográficas y videos",
          text: "5914 - Actividades de exhibición de películas cinematográficas y videos",
        },
        {
          value:
            "5920 - Actividades de grabación de sonido y edición de música",
          text: "5920 - Actividades de grabación de sonido y edición de música",
        },
        {
          value:
            "6010 - Actividades de programación y transmisión en el servicio de radiodifusión sonora",
          text: "6010 - Actividades de programación y transmisión en el servicio de radiodifusión sonora",
        },
        {
          value:
            "6020 - Actividades de programación y transmisión de televisión",
          text: "6020 - Actividades de programación y transmisión de televisión",
        },
        {
          value: "6110 - Actividades de telecomunicaciones alámbricas",
          text: "6110 - Actividades de telecomunicaciones alámbricas",
        },
        {
          value: "6120 - Actividades de telecomunicaciones inalámbricas",
          text: "6120 - Actividades de telecomunicaciones inalámbricas",
        },
        {
          value: "6130 - Actividades de telecomunicación satelital",
          text: "6130 - Actividades de telecomunicación satelital",
        },
        {
          value: "6190 - Otras actividades de telecomunicaciones",
          text: "6190 - Otras actividades de telecomunicaciones",
        },
        {
          value:
            "6201 - Actividades de desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas)",
          text: "6201 - Actividades de desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas)",
        },
        {
          value:
            "6202 - Actividades de consultoría informática y actividades de administración de instalaciones informáticas",
          text: "6202 - Actividades de consultoría informática y actividades de administración de instalaciones informáticas",
        },
        {
          value:
            "6209 - Otras actividades de tecnologías de información y actividades de servicios informáticos",
          text: "6209 - Otras actividades de tecnologías de información y actividades de servicios informáticos",
        },
        {
          value:
            "6311 - Procesamiento de datos, alojamiento (hosting) y actividades relacionadas",
          text: "6311 - Procesamiento de datos, alojamiento (hosting) y actividades relacionadas",
        },
        { value: "6312 - Portales web", text: "6312 - Portales web" },
        {
          value: "6391 - Actividades de agencias de noticias",
          text: "6391 - Actividades de agencias de noticias",
        },
        {
          value: "6399 - Otras actividades de servicios de información n.c.p.",
          text: "6399 - Otras actividades de servicios de información n.c.p.",
        },
        { value: "6411 - Banco Central", text: "6411 - Banco Central" },
        {
          value: "6412 - Bancos comerciales",
          text: "6412 - Bancos comerciales",
        },
        {
          value: "6421 - Actividades de las corporaciones financieras",
          text: "6421 - Actividades de las corporaciones financieras",
        },
        {
          value: "6422 - Actividades de las compañías de financiamiento",
          text: "6422 - Actividades de las compañías de financiamiento",
        },
        {
          value: "6423 - Banca de segundo piso",
          text: "6423 - Banca de segundo piso",
        },
        {
          value: "6424 - Actividades de las cooperativas financieras",
          text: "6424 - Actividades de las cooperativas financieras",
        },
        {
          value:
            "6431 - Fideicomisos, fondos y entidades financieras similares",
          text: "6431 - Fideicomisos, fondos y entidades financieras similares",
        },
        {
          value: "6432 - Fondos de cesantías",
          text: "6432 - Fondos de cesantías",
        },
        {
          value: "6491 - Leasing financiero (arrendamiento financiero)",
          text: "6491 - Leasing financiero (arrendamiento financiero)",
        },
        {
          value:
            "6492 - Actividades financieras de fondos de empleados y otras formas asociativas del sector solidario",
          text: "6492 - Actividades financieras de fondos de empleados y otras formas asociativas del sector solidario",
        },
        {
          value: "6493 - Actividades de compra de cartera o factoring",
          text: "6493 - Actividades de compra de cartera o factoring",
        },
        {
          value: "6494 - Otras actividades de distribución de fondos",
          text: "6494 - Otras actividades de distribución de fondos",
        },
        {
          value: "6495 - Instituciones especiales oficiales",
          text: "6495 - Instituciones especiales oficiales",
        },
        { value: "6496 - Capitalización", text: "6496 - Capitalización" },
        {
          value:
            "6499 - Otras actividades de servicio financiero, excepto las de seguros y pensiones n.c.p.",
          text: "6499 - Otras actividades de servicio financiero, excepto las de seguros y pensiones n.c.p.",
        },
        { value: "6511 - Seguros generales", text: "6511 - Seguros generales" },
        { value: "6512 - Seguros de vida", text: "6512 - Seguros de vida" },
        { value: "6513 - Reaseguros", text: "6513 - Reaseguros" },
        {
          value: "6515 - Seguros de salud",
          text: "6515 - Seguros de salud",
        },
        {
          value: "6521 - Servicios de seguros sociales de salud",
          text: "6521 - Servicios de seguros sociales de salud",
        },
        {
          value: "6522 - Servicios de seguros sociales en riesgos laborales",
          text: "6522 - Servicios de seguros sociales en riesgos laborales",
        },
        {
          value: "6523 - Servicios de seguros sociales en riesgos familia",
          text: "6523 - Servicios de seguros sociales en riesgos familia",
        },
        {
          value: "6531 - Régimen de prima media con prestación definida (RPM)",
          text: "6531 - Régimen de prima media con prestación definida (RPM)",
        },
        {
          value: "6532 - Régimen de ahorro individual con solidaridad (RAIS)",
          text: "6532 - Régimen de ahorro individual con solidaridad (RAIS)",
        },
        {
          value: "6611 - Administración de mercados financieros",
          text: "6611 - Administración de mercados financieros",
        },
        {
          value:
            "6612 - Corretaje de valores y de contratos de productos básicos",
          text: "6612 - Corretaje de valores y de contratos de productos básicos",
        },
        {
          value:
            "6613 - Otras actividades relacionadas con el mercado de valores",
          text: "6613 - Otras actividades relacionadas con el mercado de valores",
        },
        {
          value:
            "6614 - Actividades de las sociedades de intermediación cambiaria y de servicios financieros especiales",
          text: "6614 - Actividades de las sociedades de intermediación cambiaria y de servicios financieros especiales",
        },
        {
          value:
            "6615 - Actividades de los profesionales de compra y venta de divisas",
          text: "6615 - Actividades de los profesionales de compra y venta de divisas",
        },
        {
          value:
            "6619 - Otras actividades auxiliares de las actividades de servicios financieros n.c.p.",
          text: "6619 - Otras actividades auxiliares de las actividades de servicios financieros n.c.p.",
        },
        {
          value: "6621 - Actividades de agentes y corredores de seguros",
          text: "6621 - Actividades de agentes y corredores de seguros",
        },
        {
          value:
            "6629 - Evaluación de riesgos y daños, y otras actividades de servicios auxiliares",
          text: "6629 - Evaluación de riesgos y daños, y otras actividades de servicios auxiliares",
        },
        {
          value: "6630 - Actividades de administración de fondos",
          text: "6630 - Actividades de administración de fondos",
        },
        {
          value:
            "6810 - Actividades inmobiliarias realizadas con bienes propios o arrendados",
          text: "6810 - Actividades inmobiliarias realizadas con bienes propios o arrendados",
        },
        {
          value:
            "6820 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata",
          text: "6820 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata",
        },
        {
          value: "6910 - Actividades jurídicas",
          text: "6910 - Actividades jurídicas",
        },
        {
          value: "6920",
          text: "6920 - Actividades de contabilidad, teneduría de libros, auditoría financiera y asesoría tributaria",
        },
        {
          value: "7010",
          text: "7010 - Actividades de administración empresarial",
        },
        {
          value: "7020",
          text: "7020 - Actividades de consultoría de gestión",
        },
        {
          value: "7111",
          text: "7111 - Actividades de arquitectura",
        },
        {
          value: "7112",
          text: "7112 - Actividades de ingeniería y otras actividades conexas de consultoría técnica",
        },
        {
          value: "7120",
          text: "7120 - Ensayos y análisis técnicos",
        },
        {
          value: "7210",
          text: "7210 - Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería",
        },
        {
          value: "7220",
          text: "7220 - Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades",
        },
        {
          value: "7310",
          text: "7310 - Publicidad",
        },
        {
          value: "7320",
          text: "7320 - Estudios de mercado y realización de encuestas de opinión pública",
        },
        {
          value: "7410",
          text: "7410 - Actividades especializadas de diseño",
        },
        {
          value: "7420",
          text: "7420 - Actividades de fotografía",
        },
        {
          value: "7490",
          text: "7490 - Otras actividades profesionales, científicas y técnicas n.c.p.",
        },
        {
          value: "7500",
          text: "7500 - Actividades veterinarias",
        },
        {
          value: "7710",
          text: "7710 - Alquiler y arrendamiento de vehículos automotores",
        },
        {
          value: "7721",
          text: "7721 - Alquiler y arrendamiento de equipo recreativo y deportivo",
        },
        {
          value: "7722",
          text: "7722 - Alquiler de videos y discos",
        },
        {
          value:
            "7729 - Alquiler y arrendamiento de otros efectos personales y enseres domésticos n.c.p.",
          text: "7729 - Alquiler y arrendamiento de otros efectos personales y enseres domésticos n.c.p.",
        },
        {
          value:
            "7730 - Alquiler y arrendamiento de otros tipos de maquinaria, equipo y bienes tangibles n.c.p.",
          text: "7730 - Alquiler y arrendamiento de otros tipos de maquinaria, equipo y bienes tangibles n.c.p.",
        },
        {
          value:
            "7740 - Arrendamiento de propiedad intelectual y productos similares, excepto obras protegidas por derechos de autor",
          text: "7740 - Arrendamiento de propiedad intelectual y productos similares, excepto obras protegidas por derechos de autor",
        },
        {
          value:
            "7810 - Actividades de agencias de gestión y colocación de empleo",
          text: "7810 - Actividades de agencias de gestión y colocación de empleo",
        },
        {
          value: "7820 - Actividades de empresas de servicios temporales",
          text: "7820 - Actividades de empresas de servicios temporales",
        },
        {
          value: "7830 - Otras actividades de provisión de talento humano",
          text: "7830 - Otras actividades de provisión de talento humano",
        },
        {
          value: "7911 - Actividades de las agencias de viaje",
          text: "7911 - Actividades de las agencias de viaje",
        },
        {
          value: "7912 - Actividades de operadores turísticos",
          text: "7912 - Actividades de operadores turísticos",
        },
        {
          value: "7990 - Otros servicios de reserva y actividades relacionadas",
          text: "7990 - Otros servicios de reserva y actividades relacionadas",
        },
        {
          value: "8010 - Actividades de seguridad privada",
          text: "8010 - Actividades de seguridad privada",
        },
        {
          value: "8020 - Actividades de servicios de sistemas de seguridad",
          text: "8020 - Actividades de servicios de sistemas de seguridad",
        },
        {
          value: "8030 - Actividades de detectives e investigadores privados",
          text: "8030 - Actividades de detectives e investigadores privados",
        },
        {
          value: "8110 - Actividades combinadas de apoyo a instalaciones",
          text: "8110 - Actividades combinadas de apoyo a instalaciones",
        },
        {
          value: "8121 - Limpieza general interior de edificios",
          text: "8121 - Limpieza general interior de edificios",
        },
        {
          value:
            "8129 - Otras actividades de limpieza de edificios e instalaciones industriales",
          text: "8129 - Otras actividades de limpieza de edificios e instalaciones industriales",
        },
        {
          value:
            "8130 - Actividades de paisajismo y servicios de mantenimiento conexos",
          text: "8130 - Actividades de paisajismo y servicios de mantenimiento conexos",
        },
        {
          value:
            "8211 - Actividades combinadas de servicios administrativos de oficina",
          text: "8211 - Actividades combinadas de servicios administrativos de oficina",
        },
        {
          value:
            "8219 - Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo a oficina",
          text: "8219 - Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo a oficina",
        },
        {
          value: "8220 - Actividades de centros de llamadas (call center)",
          text: "8220 - Actividades de centros de llamadas (call center)",
        },
        {
          value: "8230 - Organización de convenciones y eventos comerciales",
          text: "8230 - Organización de convenciones y eventos comerciales",
        },
        {
          value:
            "8291 - Actividades de agencias de cobranza y oficinas de calificación crediticia",
          text: "8291 - Actividades de agencias de cobranza y oficinas de calificación crediticia",
        },
        {
          value: "8292 - Actividades de envase y empaque",
          text: "8292 - Actividades de envase y empaque",
        },
        {
          value:
            "8299 - Otras actividades de servicio de apoyo a las empresas n.c.p.",
          text: "8299 - Otras actividades de servicio de apoyo a las empresas n.c.p.",
        },
        {
          value: "8411 - Actividades legislativas de la administración pública",
          text: "8411 - Actividades legislativas de la administración pública",
        },
        {
          value: "8412 - Actividades ejecutivas de la administración pública",
          text: "8412 - Actividades ejecutivas de la administración pública",
        },
        {
          value:
            "8413 - Regulación de las actividades de organismos que prestan servicios de salud, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social",
          text: "8413 - Regulación de las actividades de organismos que prestan servicios de salud, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social",
        },
        {
          value:
            "8414 - Actividades reguladoras y facilitadoras de la actividad económica",
          text: "8414 - Actividades reguladoras y facilitadoras de la actividad económica",
        },
        {
          value:
            "8415 - Actividades de los órganos de control y otras instituciones",
          text: "8415 - Actividades de los órganos de control y otras instituciones",
        },
        { value: "8421 - Relaciones exteriores", text: "8421 - Relaciones exteriores" },
        { value: "8422 - Actividades de defensa", text: "8422 - Actividades de defensa" },
        { value: "8423 - Orden público y actividades de seguridad", text: "8423 - Orden público y actividades de seguridad" },
        { value: "8424 - Administración de justicia", text: "8424 - Administración de justicia" },
        {
          value: "8430 - Actividades de planes de seguridad social de afiliación obligatoria",
          text: "8430 - Actividades de planes de seguridad social de afiliación obligatoria",
        },
        { value: "8511 - Educación de la primera infancia", text: "8511 - Educación de la primera infancia" },
        { value: "8512 - Educación preescolar", text: "8512 - Educación preescolar" },
        { value: "8513 - Educación básica primaria", text: "8513 - Educación básica primaria" },
        { value: "8521 - Educación básica secundaria", text: "8521 - Educación básica secundaria" },
        { value: "8522 - Educación media académica", text: "8522 - Educación media académica" },
        { value: "8523 - Educación media técnica", text: "8523 - Educación media técnica" },
        {
          value: "8530 - Establecimientos que combinan diferentes niveles de educación",
          text: "8530 - Establecimientos que combinan diferentes niveles de educación",
        },
        { value: "8541 - Educación técnica profesional", text: "8541 - Educación técnica profesional" },
        {
          value: "8542 - Educación tecnológica",
          text: "8542 - Educación tecnológica",
        },
        {
          value:
            "8543 - Educación de instituciones universitarias o de escuelas tecnológicas",
          text: "8543 - Educación de instituciones universitarias o de escuelas tecnológicas",
        },
        {
          value: "8544 - Educación de universidades",
          text: "8544 - Educación de universidades",
        },
        {
          value: "8551 - Formación para el trabajo",
          text: "8551 - Formación para el trabajo",
        },
        {
          value: "8552 - Enseñanza deportiva y recreativa",
          text: "8552 - Enseñanza deportiva y recreativa",
        },
        {
          value: "8553 - Enseñanza cultural",
          text: "8553 - Enseñanza cultural",
        },
        {
          value: "8559 - Otros tipos de educación n.c.p.",
          text: "8559 - Otros tipos de educación n.c.p.",
        },
        {
          value: "8560 - Actividades de apoyo a la educación",
          text: "8560 - Actividades de apoyo a la educación",
        },
        {
          value: "8610 - Actividades de hospitales y clínicas, con internación",
          text: "8610 - Actividades de hospitales y clínicas, con internación",
        },
        {
          value: "8621 - Actividades de la práctica médica, sin internación",
          text: "8621 - Actividades de la práctica médica, sin internación",
        },
        {
          value: "8622 - Actividades de la práctica odontológica",
          text: "8622 - Actividades de la práctica odontológica",
        },
        {
          value: "8691 - Actividades de apoyo diagnóstico",
          text: "8691 - Actividades de apoyo diagnóstico",
        },
        { value: "8692 - Actividades de apoyo terapéutico", text: "8692 - Actividades de apoyo terapéutico" },
        {
          value: "8699 - Otras actividades de atención de la salud humana",
          text: "8699 - Otras actividades de atención de la salud humana",
        },
        {
          value: "8710 - Actividades de atención residencial medicalizada de tipo general",
          text: "8710 - Actividades de atención residencial medicalizada de tipo general",
        },
        {
          value: "8720 - Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas",
          text: "8720 - Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas",
        },
        {
          value: "8730 - Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas",
          text: "8730 - Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas",
        },
        {
          value: "8790 - Otras actividades de atención en instituciones con alojamiento",
          text: "8790 - Otras actividades de atención en instituciones con alojamiento",
        },
        {
          value: "8810 - Actividades de asistencia social sin alojamiento para personas mayores y discapacitadas",
          text: "8810 - Actividades de asistencia social sin alojamiento para personas mayores y discapacitadas",
        },
        {
          value: "8891 - Actividades de guarderías para niños y niñas",
          text: "8891 - Actividades de guarderías para niños y niñas",
        },
        {
          value: "8899 - Otras actividades de asistencia social sin alojamiento n.c.p.",
          text: "8899 - Otras actividades de asistencia social sin alojamiento n.c.p.",
        },
        { value: "9001 - Creación literaria", text: "9001 - Creación literaria" },
        { value: "9002 - Creación musical", text: "9002 - Creación musical" },
        { value: "9003 - Creación teatral", text: "9003 - Creación teatral" },
        { value: "9004 - Creación audiovisual", text: "9004 - Creación audiovisual" },
        { value: "9005 - Artes plásticas y visuales", text: "9005 - Artes plásticas y visuales" },
        { value: "9006 - Actividades teatrales", text: "9006 - Actividades teatrales" },
        {
          value: "9007 - Actividades de espectáculos musicales en vivo",
          text: "9007 - Actividades de espectáculos musicales en vivo",
        },
        {
          value: "9008 - Otras actividades de espectáculos en vivo n.c.p.",
          text: "9008 - Otras actividades de espectáculos en vivo n.c.p.",
        },
        { value: "9101 - Actividades de bibliotecas y archivos", text: "9101 - Actividades de bibliotecas y archivos" },
        {
          value: "9102 - Actividades y funcionamiento de museos, conservación de edificios y sitios históricos",
          text: "9102 - Actividades y funcionamiento de museos, conservación de edificios y sitios históricos",
        },
        {
          value: "9103 - Actividades de jardines botánicos, zoológicos y reservas naturales",
          text: "9103 - Actividades de jardines botánicos, zoológicos y reservas naturales",
        },
        { value: "9200 - Actividades de juegos de azar y apuestas", text: "9200 - Actividades de juegos de azar y apuestas" },
        { value: "9311 - Gestión de instalaciones deportivas", text: "9311 - Gestión de instalaciones deportivas" },
        { value: "9312 - Actividades de clubes deportivos", text: "9312 - Actividades de clubes deportivos" },
        { value: "9319 - Otras actividades deportivas", text: "9319 - Otras actividades deportivas" },
        {
          value: "9321 - Actividades de parques de atracciones y parques temáticos",
          text: "9321 - Actividades de parques de atracciones y parques temáticos",
        },
        {
          value: "9329 - Otras actividades recreativas y de esparcimiento n.c.p.",
          text: "9329 - Otras actividades recreativas y de esparcimiento n.c.p.",
        },
        {
          value: "9411 - Actividades de asociaciones empresariales y de empleadores",
          text: "9411 - Actividades de asociaciones empresariales y de empleadores",
        },
        {
          value: "9412 - Actividades de asociaciones profesionales",
          text: "9412 - Actividades de asociaciones profesionales",
        },
        {
          value: "9420 - Actividades de sindicatos de empleados",
          text: "9420 - Actividades de sindicatos de empleados",
        },
        {
          value: "9491 - Actividades de asociaciones religiosas",
          text: "9491 - Actividades de asociaciones religiosas",
        },
        { value: "9492 - Actividades de asociaciones políticas", text: "9492 - Actividades de asociaciones políticas" },
        {
          value: "9499 - Actividades de otras asociaciones n.c.p.",
          text: "9499 - Actividades de otras asociaciones n.c.p.",
        },
        {
          value: "9511 - Mantenimiento y reparación de computadores y de equipo periférico",
          text: "9511 - Mantenimiento y reparación de computadores y de equipo periférico",
        },
        {
          value: "9512 - Mantenimiento y reparación de equipos de comunicación",
          text: "9512 - Mantenimiento y reparación de equipos de comunicación",
        },
        {
          value:
            "9521 - Mantenimiento y reparación de aparatos electrónicos de consumo",
          text: "9521 - Mantenimiento y reparación de aparatos electrónicos de consumo",
        },
        {
          value:
            "9522 - Mantenimiento y reparación de aparatos y equipos domésticos y de jardinería",
          text: "9522 - Mantenimiento y reparación de aparatos y equipos domésticos y de jardinería",
        },
        {
          value: "9523 - Reparación de calzado y artículos de cuero",
          text: "9523 - Reparación de calzado y artículos de cuero",
        },
        {
          value: "9524 - Reparación de muebles y accesorios para el hogar",
          text: "9524 - Reparación de muebles y accesorios para el hogar",
        },
        {
          value:
            "9529 - Mantenimiento y reparación de otros efectos personales y enseres domésticos",
          text: "9529 - Mantenimiento y reparación de otros efectos personales y enseres domésticos",
        },
        {
          value:
            "9601 - Lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel",
          text: "9601 - Lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel",
        },
        {
          value: "9602 - Peluquería y otros tratamientos de belleza",
          text: "9602 - Peluquería y otros tratamientos de belleza",
        },
        {
          value: "9603 - Pompas fúnebres y actividades relacionadas",
          text: "9603 - Pompas fúnebres y actividades relacionadas",
        },
        {
          value: "9609 - Otras actividades de servicios personales n.c.p.",
          text: "9609 - Otras actividades de servicios personales n.c.p.",
        },
        {
          value:
            "9700 - Actividades de los hogares individuales como empleadores de personal doméstico",
          text: "9700 - Actividades de los hogares individuales como empleadores de personal doméstico",
        },
        {
          value:
            "9810 - Actividades no diferenciadas de los hogares individuales como productores de bienes para uso propio",
          text: "9810 - Actividades no diferenciadas de los hogares individuales como productores de bienes para uso propio",
        },
        {
          value:
            "9820 - Actividades no diferenciadas de los hogares individuales como productores de servicios para uso propio",
          text: "9820 - Actividades no diferenciadas de los hogares individuales como productores de servicios para uso propio",
        },
        {
          value:
            "9900 - Actividades de organizaciones y entidades extraterritoriales",
          text: "9900 - Actividades de organizaciones y entidades extraterritoriales",
        },
      ],
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
  },
  methods: {
    enviar() {
      this.arryaJson = [];
      this.nombrefiles = []; // nueva-----------------------------------------------------------

      // Obtener la fecha actual
      let fechaActual = new Date();

      // Obtener el día, mes y año por separado
      this.form.dia = fechaActual.getDate();
      this.form.mes = fechaActual.getMonth() + 1; // Sumamos 1 porque los meses se indexan desde 0
      this.form.anio = fechaActual.getFullYear();

      console.log("Día: " + this.form.dia);
      console.log("Mes: " + this.form.mes);
      console.log("Año: " + this.form.anio);

      this.captureSignature().then(() => {
        console.log(
          "Firma actualizada en this.form.firmaBase64:",
          this.form.firmaBase64
        );

        // Continuar con el resto de la lógica de envío aquí
      });

      this.form.desciudad = this.ciudadcompleta.text; //CAMBIOciudadpredio
      this.form.ciudadope1 = this.ciudadcompleta.text;
      this.form.ciudad = this.ciudadcompleta.value; //CAMBIO
      this.form.desciudadapoderado = this.ciudadapoderado.text; //CAMBIO
      this.form.ciudadapoderado = this.ciudadapoderado.value; //CAMBIO
      this.form.desciudadpredio = this.ciudadcompletaemp.text;
      this.form.ciudadpredio = this.ciudadcompletaemp.value; //CAMBIO
      this.form.ciudadcompletarcd1 = this.ciudadcompletaemp01.text;
      this.form.ciudadcompletarcd2 = this.ciudadcompletaemp02.text;
      this.form.ciudadcompletarcd3 = this.ciudadcompletaemp03.text;
      this.form.ciudadope2 = this.ciudadcompletaemp2.text;
      this.form.ciudadope3 = this.ciudadcompletaemp3.text;
      this.form.ciudadope4 = this.ciudadcompletaemp4.text;
      this.form.ciudadope5 = this.ciudadcompletaemp5.text;
      
      console.log("ciudadpredio 1937", this.form.ciudadpredio);

      if (this.form.selectedtipoident === "25") {
        this.form.selectedtipoident = "26";
        console.log("this.form.selectedtipoident", this.form.selectedtipoident);
      }

      if(this.form.selectedtipoidentges === "1"){
        this.form.tipoper = "Persona Jurídica";
      } else {
        this.form.tipoper = "Persona Natural";
      }

      if(this.form.selectedtipoidentges1 === "1"){
        this.form.tipoper1 = "Persona Jurídica";
      } else {
        this.form.tipoper1 = "Persona Natural";
      }

      if(this.form.selectedtipoidentges2 === "1"){
        this.form.tipoper2 = "Persona Jurídica";
      } else {
        this.form.tipoper2 = "Persona Natural";
      }

      if(this.form.selectedtipoidentges3 === "1"){
        this.form.tipoper3 = "Persona Jurídica";
      } else {
        this.form.tipoper3 = "Persona Natural";
      }

      if (this.file1.length > 0) {
        this.file1.forEach((file) => {
          this.nombrefiles.push(file.name);
        });
      }
      this.form.filesname = this.nombrefiles.toString();

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (
        this.validacioncampos(this.form) === true &&
        this.validacioncamposapo(this.form) === true
      ) {
        this.form2 = Object.assign({}, this.form, this.form1);

        //Uso de la función
        const gestores = this.crearArrayGestores(this.form);
        console.log('gestores 6874 ',gestores);

        this.arryaJson.push(this.form, this.form1, this.form2);

        if (Array.isArray(this.arryaJson)) {
          // Recorre el arryaJson y aplica la función a todas las propiedades de todos los objetos en la matriz
          this.arryaJson.forEach((obj) => {
            for (const prop in obj) {
              if (typeof obj[prop] === "string") {
                obj[prop] = this.replaceSpecialCharacters(obj[prop]);
              }
            }
          });
        } else {
          console.error("arryaJson no es una matriz o está indefinida");
        }

        this.loading = true;
        this.show = false;
        this.pesofile1 = 0; // nueva----------------------------------------------------------------
        for (let i = 0; i < this.file1.length; i++) {
          this.pesofile1 += this.file1[i].size;
        }

        // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->

        if (this.file1.length > 0) {
          if (this.validarpesofile(this.file1) === true) {
            // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
            if (this.pesofile1 > 50000000) {
              swal(
                "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
              );
              this.loading = false;
              this.show = true;
              this.pesofile1 = 0;
            } else {
              // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
              axiosInstance.post(rutaenviosolicitudrcd, {
                  arrayJson: this.arryaJson,
                  gestores: gestores
              })
                .then((response) => {
                  let blob = this.b64toBlob(response.data.pdf);

                  this.file1.push(blob);

                  console.log("adjuntos frontend ", this.file1.length);
                  if (this.file1.length > 0) {
                    this.contadorarchivos = this.file1.length;
                    this.filesValide = []; /////////////7nuevo

                    console.log("cantidad de archivos ", this.contadorarchivos);
                    this.file1.forEach((file) => {
                      /////////////7nuevo
                      this.filesValide.push({
                        name: file.name,
                        value: 0,
                        color: true,
                      });

                      const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                      const formData = new FormData();
                      let regex = /([^-\w.]*)/gm;

                      let arcPDF2 = file.name.replace(regex, "");
                      arcPDF2 = arcPDF2.replaceAll(" ", "");

                      let blob = file.slice(0, file.size, file.type);
                      let newFile = new File([blob], arcPDF2, {
                        type: file.type,
                      });

                      formData.append("file", newFile);
                      formData.append("fileName", arcPDF2);
                      formData.append("radicado", response.data.radicado);
                      formData.append("usuario", response.data.usuario);
                      formData.append("ordenTrabajo", response.data.orden);
                      formData.append("idCorreo", response.data.correo);

                      // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                      axios
                        .post(rutafile, formData, {
                          /// envio archivos
                          headers: { "content-type": "multipart/form-data" },
                          maxRedirects: 0,
                        })
                        .then((respon) => {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          if (respon.status === 200) {
                            this.contadorresponse++;
                          } else {
                            ///////////77nuevo
                            this.filesValide[keyInd].color = false;
                          } ///////////77nuevo

                          this.resetForm();

                          if (this.contadorarchivos === this.contadorresponse) {
                            this.loading = false;
                            swal({
                              closeOnClickOutside: false,
                              text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                          }

                          this.show = true;
                        })
                        .catch(function (error) {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          this.filesValide[keyInd].color = false; ///////////77nuevo
                          if (
                            error.response.status === 500 ||
                            error.response.status === 404
                          ) {
                            swal({
                              closeOnClickOutside: false,
                              text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                            this.resetForm();
                            console.log("error 2022 ", error.response.status);
                          }
                          this.show = true;
                        });
                    });
                  } else {
                    this.resetForm();
                    swal({
                      closeOnClickOutside: false,
                      text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                    this.loading = false;
                    this.show = true;
                  }
                })
                .catch(function (error) {
                  if (error.response.status === 500) {
                    swal({
                      closeOnClickOutside: false,
                      text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                  }
                  this.loading = false;
                  this.show = true;
                });
            }
          } else {
            swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
            this.loading = false;
            this.show = true;
          }
        } else {
          // CUANDO NO CARGAR ARCHIVOS SE ENVIA SOLO LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
          
          console.log('gestores 7044 ', gestores);
          axiosInstance.post(rutaenviosolicitudrcd, {
              arrayJson: this.arryaJson,
              gestores: gestores
          })
            .then((response) => {
              let blob = this.b64toBlob(response.data.pdf);

              this.file1.push(blob);

              console.log("adjuntos pdf backend", this.file1.length);

              if (this.file1.length > 0) {
                this.contadorarchivos = this.file1.length;
                this.filesValide = []; /////////////7nuevo

                this.file1.forEach((file) => {
                  /////////////7nuevo
                  this.filesValide.push({
                    name: file.name,
                    value: 0,
                    color: true,
                  });

                  const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                  const formData = new FormData();
                  let regex = /([^-\w.]*)/gm;

                  let arcPDF2 = file.name.replace(regex, "");
                  arcPDF2 = arcPDF2.replaceAll(" ", "");

                  let blob = file.slice(0, file.size, file.type);
                  let newFile = new File([blob], arcPDF2, {
                    type: file.type,
                  });

                  formData.append("file", newFile);
                  formData.append("fileName", arcPDF2);
                  formData.append("radicado", response.data.radicado);
                  formData.append("usuario", response.data.usuario);
                  formData.append("ordenTrabajo", response.data.orden);
                  formData.append("idCorreo", response.data.correo);

                  // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                  axios
                    .post(rutafile, formData, {
                      /// envio archivos
                      headers: { "content-type": "multipart/form-data" },
                      maxRedirects: 0,
                    })
                    .then((respon) => {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      if (respon.status === 200) {
                        this.contadorresponse++;
                      } else {
                        ///////////77nuevo
                        this.filesValide[keyInd].color = false;
                      } ///////////77nuevo

                      this.resetForm();

                      if (this.contadorarchivos === this.contadorresponse) {
                        this.loading = false;
                        swal({
                          closeOnClickOutside: false,
                          text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                      }

                      this.show = true;
                    })
                    .catch(function (error) {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      this.filesValide[keyInd].color = false; ///////////77nuevo
                      if (
                        error.response.status === 500 ||
                        error.response.status === 404
                      ) {
                        swal({
                          closeOnClickOutside: false,
                          text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                        this.resetForm();
                        console.log("error 2022 ", error.response.status);
                      }
                      this.show = true;
                    });
                });
              }

              this.resetForm();
              swal({
                closeOnClickOutside: false,
                text: `.,.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
              }).then((value) => {
                if (value) {
                  window.location.href = "https://cvc.gov.co/";
                }
              });
              this.loading = false;
              this.show = true;
            })
            .catch(function (error) {
              if (error.response.status === 500) {
                swal({
                  closeOnClickOutside: false,
                  text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
              }
              this.loading = false;
              this.show = true;
            });
        }
      } else {
        console.log("fallo");
      }
    },
    validarpesofile(files) {
      let count = 0;
      files.forEach((file) => {
        if (file.size > 10000000) {
          count++;
        }
      });
      if (count == 0) {
        return true;
      } else {
        return false;
      }
    },
    b64toBlob(b64Data) {
      const names = "formulario.pdf";
      const newbase64 = "data:application/pdf;base64," + b64Data;

      let arr = newbase64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file_created = new File([u8arr], names, { type: mime });

      return file_created;
    },
    resetForm() {
      this.form1.firma = "";
      (this.form = {
        tipotramite: "31",
        mediorecepcion: "4",
        observacionradicado:
          "INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA MANUFACTURERO",
        asunto: "",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "184", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: "",
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
      }),
        (this.form1 = {
          actividadeco: "",
          CodigoCIIU: "",
          costototal: "",
          deparfuente: "",
          munifuente: "",
          direccionprediofuente: "",
          fuenteabastecimiento: "",
          grado1: "",
          grado2: "",
          grado3: "",
          munitos1: "",
          munitos2: "",
          munitos3: "",
          segundos1: "",
          segundos2: "",
          segundos3: "",
          grados1l: "",
          grados2l: "",
          grados3l: "",
          minutos1l: "",
          minutos2l: "",
          minutos3l: "",
          segundos1l: "",
          segundos2l: "",
          segundos3l: "",
          altitud1l: "",
          altitud2l: "",
          altitud3l: "",
          usosueloactual: "",
          usosuelopotencial: "",
          selectedtipoverti: "",
          selectedcaptacion: "",
          tiempodescargahora: "",
          tiempodescargadias: "",
          tiempodescargacuadal: "",
          selectedtipoflujo: "",
          areavertimiento: "",
          observa: "",
          firma: "",
          aceptacion: "",
        });
    },
    validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(
        /[^0-9]/g,
        ""
      ); // Elimina cualquier carácter que no sea un número
      this.form.identificacionapode = this.form.identificacionapode.replace(
        /[^0-9]/g,
        ""
      );
    },
    validacioncampos() {
      if (this.form.selectedtipoper === "") {
        swal("Debe seleccionar el tipo de persona");
      } else if (this.form.selectedtipotram === "") {
        swal("Debe seleccionar el tipo de tramite");
      } else if (this.form.selectedtipoident === "") {
        swal("Debe seleccionar el tipo de identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.nombre === ""
      ) {
        swal("Debe ingresar un nombre o razón social");
      } else if (
        (this.form.selectedtipoident === "10" ||
          this.form.selectedtipoident === "36" ||
          this.form.selectedtipoident === "11") &&
        (this.form.nombre1 === "" || this.form.nombre3 === "")
      ) {
        swal("Debe ingresar un nombre y un apellido");
      } else if (this.form.identificacion === "") {
        swal("Debe ingresar la identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.digito === ""
      ) {
        swal("Debe ingresar el digito de verificación");
      } else if (this.form.dircorrespondencia === "") {
        swal("Debe ingresar la dirección de correspondencia");
      } else if (this.form.depart === "") {
        swal("Debe seleccionar el departamento de correspondencia");
      } else if (this.ciudadcompleta.value == undefined) {
        swal("Debe seleccionar la ciudad de correspondencia");
      } else if (this.form.selectedcorreo === "") {
        swal("Debe seleccionar si o no autoriza la notificación por correo");
      } else if (this.form.selectedcorreo === "1" && this.form.correo === "") {
        swal("Por favor ingresar un correo");
      } else if (
        this.form.selectedcorreo === "1" &&
        this.validEmail(this.form.correo) === false
      ) {
        swal("Por favor ingresar un correo valido");
      } else if (
        this.form.selectedcorreo === "2" &&
        this.form.correodirnoti === ""
      ) {
        swal("Por favor ingresar una dirección de notificación");
      } 
      /*--------------------gestor----------------------------------*/
      else if (this.form.nombregestor === "") {
        swal("Debe ingresar Nombre o Razón social del gestor");
      } else if (this.form.reprelegalgestor === "") {
        swal("Debe ingresar Nombre del representante legal del gestor");
      } else if (this.form.selectedtipoidentges === "") {
        swal("Debe seleccionar el tipo de identificación del gestor");
      } else if (this.form.identificacionges === "") {
        swal("Debe ingresar el numero de identificación del gestor");
      } else if (this.form.codigoCIIUp === "") {
        swal("Debe seleccionar la actividad principal del gestor");
      } /*else if (this.form.codigoCIIUs === "") {
        swal("Debe seleccionar la actividad secundaria del gestor");
      } */
      else if (this.form.departpredio === "") {
        swal("Debe seleccionar el departamento del gestor");
      } else if (this.ciudadcompletaemp === "") {
        swal("Debe seleccionar el municipio del gestor");
      } else if (this.form.tipodirges === "") {
        swal("Debe seleccionar tipo de direcccion");
      } else if (this.form.tipoviages === "") {
        swal("Debe seleccionar tipo de via");
      } else if (this.form.numviages === "") {
        swal("Debe ingresar el numero de via");
      } else if (this.form.direccionpredio === "") {
        swal("Debe ingresar la dirección del gestor");
      } else if (this.form.coornortges === "") {
        swal("Debe ingresar la cordenada norte del gestor");
      } else if (this.form.cooresteges === "") {
        swal("Debe ingresar la cordenada este del gestor");
      } else if (this.form.tipotel === "") {
        swal("Debe seleccionar el tipo de telefono");
      } else if (this.form.indiges === "") {
        swal("Debe ingresar el indicativo");
      } else if (this.form.telges === "") {
        swal("Debe ingresar el numero de telefono del gestor");
      } else if (this.form.correoges === "") {
        swal("Debe ingresar el correo del gestor");
      } 
      /*--------------------operacion 1----------------------------------*/
      else if (this.form.selectedactope1a === "") {
        swal("Debe seleccionar si realiza o no la ACTIVIDAD DE RECOLECCIÓN");
      } else if (this.form.selectedactope1b === "") {
        swal("Debe seleccionar si realiza o no la ACTIVIDAD DE TRANSPORTE");
      } else if (this.form.selectedactope1c === "") {
        swal("Debe seleccionar si realiza o no la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio)");
      } else if (this.form.selectedactope1d === "") {
        swal("Debe seleccionar si realiza o no la ACTIVIDAD DE APROVECHAMIENTO");
      } else if (this.form.selectedactope1e === "") {
        swal("Debe seleccionar si realiza o no la ACTIVIDAD DE DISPOSICIÓN FINAL");
      } else if (this.form.departope1 === "") {
        swal("Debe seleccionar el departamento de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.ciudadcompletaemp1 === "") {
        swal("Debe seleccionar la ciudad de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.tipodirope1 === "") {
        swal("Debe seleccionar el tipo de direccion de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.tipoviaope1 === "") {
        swal("Debe seleccionar el tipo de via de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.numviaope1 === "") {
        swal("Debe ingresar el numero via de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.dirope1 === "") {
        swal("Debe ingresar la direccion de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.coornortope1 === "") {
        swal("Debe ingresar la coordenada norte de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.cooresteope1 === "") {
        swal("Debe ingresar la coordenada este de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.usosueloope1 === "") {
        swal("Debe seleccionar el uso de suelo de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.capalmpesoplope1 === "") {
        swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.capalmflujoplope1 === "") {
        swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.capalmpesoapope1 === "") {
        swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.capalmflujoapope1 === "") {
        swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.capapropesoapope1 === "") {
        swal("Debe ingresar la Capacidad de aprovechamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.capaproflujoapope1 === "") {
        swal("Debe ingresar la Capacidad de aprovechamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.captotaldppope1 === "") {
        swal("Debe ingresar la Capacidad total de disposición final de RCD (t) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.cuotaminope1 === "") {
        swal("Debe ingresar la Cota mínima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.cuotamaxope1 === "") {
        swal("Debe ingresar la Cota máxima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 1");
      } else if (this.form.dencircdope1 === "") {
        swal("Debe ingresar la Densidad de referencia del RCD (t/m3) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 1");
      } 
/*--------------------operacion 2----------------------------------*/
      else if (this.showestab2 == true && this.form.selectedactope2a === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE RECOLECCIÓN DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.selectedactope2b === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE TRANSPORTE DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.selectedactope2c === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio) DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.selectedactope2d === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE APROVECHAMIENTO DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.selectedactope2e === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE DISPOSICIÓN FINAL DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.departope2 === "") {
            swal("Debe seleccionar el departamento de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.ciudadcompletaemp2 === "") {
            swal("Debe seleccionar la ciudad de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.tipodirope2 === "") {
            swal("Debe seleccionar el tipo de direccion de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.tipoviaope2 === "") {
            swal("Debe seleccionar el tipo de via de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.numviaope2 === "") {
            swal("Debe ingresar el numero via de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.dirope2 === "") {
            swal("Debe ingresar la direccion de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.coornortope2 === "") {
            swal("Debe ingresar la coordenada norte de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.cooresteope2 === "") {
            swal("Debe ingresar la coordenada este de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.usosueloope2 === "") {
            swal("Debe seleccionar el uso de suelo de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.capalmpesoplope2 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.capalmflujoplope2 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.capalmpesoapope2 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.capalmflujoapope2 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.capapropesoapope2 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.capaproflujoapope2 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.captotaldppope2 === "") {
            swal("Debe ingresar la Capacidad total de disposición final de RCD (t) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.cuotaminope2 === "") {
            swal("Debe ingresar la Cota mínima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.cuotamaxope2 === "") {
            swal("Debe ingresar la Cota máxima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 2");
          } else if (this.showestab2 == true && this.form.dencircdope2 === "") {
            swal("Debe ingresar la Densidad de referencia del RCD (t/m3) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 2");
          } 
/*--------------------operacion 3----------------------------------*/
else if (this.showestab3 == true && this.form.selectedactope3a === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE RECOLECCIÓN DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.selectedactope3b === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE TRANSPORTE DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.selectedactope3c === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio) DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.selectedactope3d === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE APROVECHAMIENTO DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.selectedactope3e === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE DISPOSICIÓN FINAL DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.departope3 === "") {
            swal("Debe seleccionar el departamento de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.ciudadcompletaemp3 === "") {
            swal("Debe seleccionar la ciudad de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.tipodirope3 === "") {
            swal("Debe seleccionar el tipo de direccion de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.tipoviaope3 === "") {
            swal("Debe seleccionar el tipo de via de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.numviaope3 === "") {
            swal("Debe ingresar el numero via de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.dirope3 === "") {
            swal("Debe ingresar la direccion de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.coornortope3 === "") {
            swal("Debe ingresar la coordenada norte de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.cooresteope3 === "") {
            swal("Debe ingresar la coordenada este de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.usosueloope3 === "") {
            swal("Debe seleccionar el uso de suelo de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.capalmpesoplope3 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.capalmflujoplope3 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.capalmpesoapope3 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.capalmflujoapope3 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.capapropesoapope3 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.capaproflujoapope3 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.captotaldppope3 === "") {
            swal("Debe ingresar la Capacidad total de disposición final de RCD (t) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.cuotaminope3 === "") {
            swal("Debe ingresar la Cota mínima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.cuotamaxope3 === "") {
            swal("Debe ingresar la Cota máxima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 3");
          } else if (this.showestab3 == true && this.form.dencircdope3 === "") {
            swal("Debe ingresar la Densidad de referencia del RCD (t/m3) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 3");
          } 

/*--------------------operacion 4----------------------------------*/
else if (this.showestab4 == true && this.form.selectedactope4a === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE RECOLECCIÓN DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.selectedactope4b === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE TRANSPORTE DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.selectedactope4c === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio) DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.selectedactope4d === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE APROVECHAMIENTO DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.selectedactope4e === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE DISPOSICIÓN FINAL DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.departope4 === "") {
            swal("Debe seleccionar el departamento de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.ciudadcompletaemp4 === "") {
            swal("Debe seleccionar la ciudad de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.tipodirope4 === "") {
            swal("Debe seleccionar el tipo de direccion de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.tipoviaope4 === "") {
            swal("Debe seleccionar el tipo de via de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.numviaope4 === "") {
            swal("Debe ingresar el numero via de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.dirope4 === "") {
            swal("Debe ingresar la direccion de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.coornortope4 === "") {
            swal("Debe ingresar la coordenada norte de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.cooresteope4 === "") {
            swal("Debe ingresar la coordenada este de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.usosueloope4 === "") {
            swal("Debe seleccionar el uso de suelo de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.capalmpesoplope4 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.capalmflujoplope4 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.capalmpesoapope4 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.capalmflujoapope4 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.capapropesoapope4 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.capaproflujoapope4 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.captotaldppope4 === "") {
            swal("Debe ingresar la Capacidad total de disposición final de RCD (t) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.cuotaminope4 === "") {
            swal("Debe ingresar la Cota mínima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.cuotamaxope4 === "") {
            swal("Debe ingresar la Cota máxima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 4");
          } else if (this.showestab4 == true && this.form.dencircdope4 === "") {
            swal("Debe ingresar la Densidad de referencia del RCD (t/m3) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 4");
          } 
/*--------------------operacion 5----------------------------------*/
else if (this.showestab5 == true && this.form.selectedactope5a === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE RECOLECCIÓN DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.selectedactope5b === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE TRANSPORTE DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.selectedactope5c === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio) DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.selectedactope5d === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE APROVECHAMIENTO DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.selectedactope5e === "") {
            swal("Debe seleccionar si realiza o no la ACTIVIDAD DE DISPOSICIÓN FINAL DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.departope5 === "") {
            swal("Debe seleccionar el departamento de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.ciudadcompletaemp5 === "") {
            swal("Debe seleccionar la ciudad de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.tipodirope5 === "") {
            swal("Debe seleccionar el tipo de direccion de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.tipoviaope5 === "") {
            swal("Debe seleccionar el tipo de via de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.numviaope5 === "") {
            swal("Debe ingresar el numero via de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.dirope5 === "") {
            swal("Debe ingresar la direccion de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.coornortope5 === "") {
            swal("Debe ingresar la coordenada norte de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.cooresteope5 === "") {
            swal("Debe ingresar la coordenada este de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.usosueloope5 === "") {
            swal("Debe seleccionar el uso de suelo de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.capalmpesoplope5 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.capalmflujoplope5 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividad de Almacenamiento (punto limpio) de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.capalmpesoapope5 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.capalmflujoapope5 === "") {
            swal("Debe ingresar la Capacidad de almacenamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.capapropesoapope5 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en peso (t) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.capaproflujoapope5 === "") {
            swal("Debe ingresar la Capacidad de aprovechamiento en Flujo (t/mes) Actividades de Almacenamiento y Aprovechamiento en Planta de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.captotaldppope5 === "") {
            swal("Debe ingresar la Capacidad total de disposición final de RCD (t) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.cuotaminope5 === "") {
            swal("Debe ingresar la Cota mínima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.cuotamaxope5 === "") {
            swal("Debe ingresar la Cota máxima (m.s.n.m.) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 5");
          } else if (this.showestab5 == true && this.form.dencircdope5 === "") {
            swal("Debe ingresar la Densidad de referencia del RCD (t/m3) Sitio de Disposición Final de la DIRECCIÓN DE OPERACIÓN 5");
          } 
/*--------------------preguntas----------------------------------*/
          else if(this.form.tiporesiduoges0a === ""){
            swal("Debe seleccionar si o no 1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros.");
          } else if(this.form.tiporesiduoges0b === ""){
            swal("Debe seleccionar si o no 1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás.");
          } else if(this.form.tiporesiduoges0c === ""){
            swal("Debe seleccionar si o no 1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros.");
          } else if(this.form.tiporesiduoges0d === ""){
            swal("Debe seleccionar si o no 1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros.");
          } else if(this.form.tiporesiduoges0e === ""){
            swal("Debe seleccionar si o no 2.1 Los contaminados con residuos peligrosos.");
          } else if(this.form.tiporesiduoges0f === ""){
            swal("Debe seleccionar si o no 2.2. Los que por su estado no pueden ser aprovechados.");
          } else if(this.form.tiporesiduoges0g === ""){
            swal("Debe seleccionar si o no 2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión.");
          } 
/*--------------------RCD 1----------------------------------*/
          else if(this.form.selectedanonimo === ""){
            swal("Los Residuos de construcción y Demolición- RCD son entregados a otro gestor");
          } else if(this.form.selectedanonimo == 1 && this.form.nombregestor1 === ""){
            swal("Debe ingresar Nombre completo o Razón social para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.reprelegalgestor1 === ""){
            swal("Debe ingresar Representante Legal para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.selectedtipoidentges1 === ""){
            swal("Debe seleccionar el tipo de identificacion para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.identificacionges1 === ""){
            swal("Debe ingresar el numero de identificacion para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.codigoCIIUp1 === ""){
            swal("Debe seleccionar la ACTIVIDAD PRINCIPAL para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.departpredio1 === ""){
            swal("Debe seleccionar el departamento para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.ciudadcompletaemp01 === ""){
            swal("Debe seleccionar la ciudad para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tipodirges1 === ""){
            swal("Debe seleccionar el tipo de direccion para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tipoviages1 === ""){
            swal("Debe seleccionar el tipo de via para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.numviages1 === ""){
            swal("Debe seleccionar el numero de via para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.direccionpredio1 === ""){
            swal("Debe ingresar la direccion para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.coornortges1 === ""){
            swal("Debe ingresar la coordenada norte para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.cooresteges1 === ""){
            swal("Debe ingresar la coordenada este para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tipotel1 === ""){
            swal("Debe seleccionar el tipo de telefono para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.indiges1 === ""){
            swal("Debe ingresar el indicativo para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.telges1 === ""){
            swal("Debe ingresar numero de telefono para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.selectedactope6a === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE RECOLECCIÓN para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.selectedactope6b === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE TRANSPORTE para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.selectedactope6c === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio) para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.selectedactope6d === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE APROVECHAMIENTO para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.selectedactope6e === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE DISPOSICIÓN FINAL para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tiporesiduoges1a === ""){
            swal("Debe seleccionar si o no 1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros. para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tiporesiduoges1b === ""){
            swal("Debe seleccionar si o no 1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás. para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tiporesiduoges1c === ""){
            swal("Debe seleccionar si o no 1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros. para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tiporesiduoges1d === ""){
            swal("Debe seleccionar si o no 1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros. para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tiporesiduoges1e === ""){
            swal("Debe seleccionar si o no 2.1 Los contaminados con residuos peligrosos. para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tiporesiduoges1f === ""){
            swal("Debe seleccionar si o no 2.2. Los que por su estado no pueden ser aprovechados. para GESTOR RCD 1");
          } else if(this.form.selectedanonimo == 1 && this.form.tiporesiduoges1g === ""){
            swal("Debe seleccionar si o no 2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión. para GESTOR RCD 1");
          } 

/*--------------------RCD 2----------------------------------*/
else if(this.showestab6 == true && this.form.nombregestor2 === ""){
            swal("Debe ingresar Nombre completo o Razón social para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.reprelegalgestor2 === ""){
            swal("Debe ingresar Representante Legal para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.selectedtipoidentges2 === ""){
            swal("Debe seleccionar el tipo de identificacion para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.identificacionges2 === ""){
            swal("Debe ingresar el numero de identificacion para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.codigoCIIUp2 === ""){
            swal("Debe seleccionar la ACTIVIDAD PRINCIPAL para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.departpredio2 === ""){
            swal("Debe seleccionar el departamento para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.ciudadcompletaemp02 === ""){
            swal("Debe seleccionar la ciudad para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tipodirges2 === ""){
            swal("Debe seleccionar el tipo de direccion para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tipoviages2 === ""){
            swal("Debe seleccionar el tipo de via para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.numviages2 === ""){
            swal("Debe seleccionar el numero de via para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.direccionpredio2 === ""){
            swal("Debe ingresar la direccion para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.coornortges2 === ""){
            swal("Debe ingresar la coordenada norte para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.cooresteges2 === ""){
            swal("Debe ingresar la coordenada este para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tipotel2 === ""){
            swal("Debe seleccionar el tipo de telefono para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.indiges2 === ""){
            swal("Debe ingresar el indicativo para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.telges2 === ""){
            swal("Debe ingresar numero de telefono para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.selectedactope7a === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE RECOLECCIÓN para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.selectedactope7b === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE TRANSPORTE para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.selectedactope7c === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio) para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.selectedactope7d === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE APROVECHAMIENTO para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.selectedactope7e === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE DISPOSICIÓN FINAL para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tiporesiduoges2a === ""){
            swal("Debe seleccionar si o no 1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros. para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tiporesiduoges2b === ""){
            swal("Debe seleccionar si o no 1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás. para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tiporesiduoges2c === ""){
            swal("Debe seleccionar si o no 1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros. para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tiporesiduoges2d === ""){
            swal("Debe seleccionar si o no 1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros. para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tiporesiduoges2e === ""){
            swal("Debe seleccionar si o no 2.1 Los contaminados con residuos peligrosos. para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tiporesiduoges2f === ""){
            swal("Debe seleccionar si o no 2.2. Los que por su estado no pueden ser aprovechados. para GESTOR RCD 2");
          } else if(this.showestab6 == true && this.form.tiporesiduoges2g === ""){
            swal("Debe seleccionar si o no 2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión. para GESTOR RCD 2");
          } 

          /*--------------------RCD 3----------------------------------*/
else if(this.showestab7 == true && this.form.nombregestor3 === ""){
            swal("Debe ingresar Nombre completo o Razón social para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.reprelegalgestor3 === ""){
            swal("Debe ingresar Representante Legal para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.selectedtipoidentges3 === ""){
            swal("Debe seleccionar el tipo de identificacion para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.identificacionges3 === ""){
            swal("Debe ingresar el numero de identificacion para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.codigoCIIUp3 === ""){
            swal("Debe seleccionar la ACTIVIDAD PRINCIPAL para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.departpredio3 === ""){
            swal("Debe seleccionar el departamento para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.ciudadcompletaemp03 === ""){
            swal("Debe seleccionar la ciudad para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tipodirges3 === ""){
            swal("Debe seleccionar el tipo de direccion para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tipoviages3 === ""){
            swal("Debe seleccionar el tipo de via para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.numviages3 === ""){
            swal("Debe seleccionar el numero de via para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.direccionpredio3 === ""){
            swal("Debe ingresar la direccion para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.coornortges3 === ""){
            swal("Debe ingresar la coordenada norte para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.cooresteges3 === ""){
            swal("Debe ingresar la coordenada este para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tipotel3 === ""){
            swal("Debe seleccionar el tipo de telefono para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.indiges3 === ""){
            swal("Debe ingresar el indicativo para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.telges3 === ""){
            swal("Debe ingresar numero de telefono para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.selectedactope8a === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE RECOLECCIÓN para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.selectedactope8b === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE TRANSPORTE para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.selectedactope8c === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE ALMACENAMIENTO (Punto Limpio) para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.selectedactope8d === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE APROVECHAMIENTO para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.selectedactope8e === ""){
            swal("Debe seleccionar si o no realiza la ACTIVIDAD DE DISPOSICIÓN FINAL para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tiporesiduoges3a === ""){
            swal("Debe seleccionar si o no 1.1 Productos de excavación y sobrantes de la adecuación del terreno: coberturas vegetales, tierras, limos y materiales pétreos productos de la excavación, entre otros. para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tiporesiduoges3b === ""){
            swal("Debe seleccionar si o no 1.2 Productos de cimentaciones y pilotajes: arcillas, bentonitas y demás. para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tiporesiduoges3c === ""){
            swal("Debe seleccionar si o no 1.3 Pétreos: hormigón, arenas, gravas, gravillas, cantos, pétreos asfalticos, trozos de ladrillos y bloques, cerámicas, sobrantes de mezcla de cementos y concretos hidráulicos, entre otros. para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tiporesiduoges3d === ""){
            swal("Debe seleccionar si o no 1.4 No pétreos: vidrio, metales como acero, hierro, cobre, aluminio, con o sin recubrimiento de zinc o estaño, plásticos tales como: PVC, polietileno, policarbonato, acrílico, espumas de poliestireno y de poliuretano, gomas y cauchos, madera y compuestos de madera, cartón-yeso (drywall), entre otros. para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tiporesiduoges3e === ""){
            swal("Debe seleccionar si o no 2.1 Los contaminados con residuos peligrosos. para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tiporesiduoges3f === ""){
            swal("Debe seleccionar si o no 2.2. Los que por su estado no pueden ser aprovechados. para GESTOR RCD 3");
          } else if(this.showestab7 == true && this.form.tiporesiduoges3g === ""){
            swal("Debe seleccionar si o no 2.3 Los que tengan características de peligrosidad, estos se regirán por la normatividad ambiental especial establecida para su gestión. para GESTOR RCD 3");
          } 

      else if (this.form.firmasolicitante === "") {
        swal("Debes ingresar el Nombre del solicitante o apoderado");
      } else if (this.signaturePad.isEmpty()) {
        swal("Debes firmar el campo de la firma no debe quedar vacio");
      } else if (this.form.aceptacion === "") {
        swal(
          "Debe aceptar el uso de mis datos personales, tratamiento, protección"
        );
      } else {
        return true;
      }
    },
    validacioncamposapo() {
      //console.log("this.form.selectedtipoperapo",this.form.selectedtipoperapo)
      if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoidentapo === ""
      ) {
        swal("Debe seleccionar el tipo de identificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoperapo === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.nombreapoderado === ""
      ) {
        swal("Debe ingresar un nombre o razón social del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "10") ||
          this.form.selectedtipoidentapo === "36" ||
          this.form.selectedtipoidentapo === "11") &&
        (this.form.nombre1apo === "" || this.form.nombre3apo === "")
      ) {
        swal("Debe ingresar un nombre y un apellido del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.identificacionapode === ""
      ) {
        swal("Debe ingresar la identificación del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.digitoapo === ""
      ) {
        swal("Debe ingresar el digito de verificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.dirapoderado === ""
      ) {
        swal("Debe ingresar la dirección de correspondencia del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.departapoderado === ""
      ) {
        swal(
          "Debe seleccionar el departamento de correspondencia del representante"
        );
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.ciudadapoderado.value == undefined
      ) {
        swal("Debe seleccionar la ciudad de correspondencia del representante");
      } else if (
        this.form.correoapoderado !== "" &&
        this.validEmail(this.form.correoapoderado) === false
      ) {
        swal("Por favor ingresar un correo valido del representante");
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
    onChangeCiudad() {
      if (this.form.depart !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.depart}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudad = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeCiudadapoderado() {
      //CAMBIO
      if (this.form.departapoderado !== "") {
        this.optionsciudadesapo = [];

        this.$http
          .post(rutaciudad + `${this.form.departapoderado}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudadesapo.push(objeto);
            }
            this.showciudadapo = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeRadioButton() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoper === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoident = "";
        this.form.selectedtipoperjuridica = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoper === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoident = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },

    initializeCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const windowWidth = window.innerWidth;

      if (windowWidth <= 700) {
        this.canvasWidth = windowWidth - 20;
        this.canvasHeight = (this.canvasWidth * 120) / 700;
      } else {
        this.canvasWidth = 700;
        this.canvasHeight = 120;
      }

      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;
      this.signaturePad = new SignaturePad(canvas);
    },
    clearSignature() {
      this.signaturePad.clear();
      this.form.firmaBase64 = ""; // Limpiar la variable de base64 cuando se borre la firma
    },
    captureSignature() {
      return new Promise((resolve) => {
        if (this.signaturePad.isEmpty()) {
          resolve(); // Resolvemos la promesa sin capturar la firma
          return;
        }

        // Obtener la firma en formato base64
        const dataURL = this.signaturePad.toDataURL();
        this.form.firmaBase64 = dataURL.replace(
          /^data:image\/(png|jpg);base64,/,
          ""
        );
        console.log("Firma capturada:", this.form.firmaBase64);
        resolve(); // Resolvemos la promesa después de capturar la firma
      });
    },
    replaceSpecialCharacters(value) {
      if (typeof value === "string") {
        // Reemplaza el carácter "&" y comillas simples por una cadena vacía
        return value.replace(/&/g, "").replace(/'/g, "");
      } else {
        return value; // Si no es una cadena, devuelve el valor sin cambios
      }
    },
    activasitio2() {
      this.showestab2 = true;
    },
    activasitio3() {
      this.showestab3 = true;
    },
    activasitio4() {
      this.showestab4 = true;
    },
    activasitio5() {
      this.showestab5 = true;
    },
    activasitio6() {
      swal(
        "Para registrar mas sitios de operación debes radicar una solicitud nueva, el maximo se sitios por solicitud son 5"
      );
    },
    activasitio7() {
      this.showestab6 = true;
    },
    activasitio8() {
      this.showestab7 = true;
    },
    crearArrayGestores(formulario) {
    const gestores = [];  

    const gestor = {
      "nombregestor": formulario.nombregestor, //
      "reprelegalgestor": formulario.reprelegalgestor, //
      "selectedtipoidentges": formulario.selectedtipoidentges, //
      "tipoper": formulario.tipoper, //
      "identificacionges": formulario.identificacionges, //
      "digitoges": formulario.digitoges, //
      "departpredio": formulario.departope1,
      "ciudadcompleta": formulario.ciudadope1,
      "tipodirges": formulario.tipodirope1,
      "tipoviages": formulario.tipoviaope1,
      "numviages": formulario.numviaope1,
      "direccionpredio": formulario.dirope1,
      "compldirges": formulario.compldirope1,
      "coornortges": formulario.coornortope1,
      "cooresteges": formulario.cooresteope1,
      "tipotel": formulario.tipotel, //
      "indiges": formulario.indiges, //
      "telges": formulario.telges, //
      "comptelges": formulario.comptelges, //
      "correoges": formulario.correoges, //
      "codigoCIIUp": formulario.codigoCIIUp, //
      "codigoCIIUs": formulario.codigoCIIUs, //
      "selectedactopea": formulario.selectedactope1a,
      "selectedactopeb": formulario.selectedactope1b,
      "selectedactopec": formulario.selectedactope1c,
      "selectedactoped": formulario.selectedactope1d,
      "selectedactopee": formulario.selectedactope1e,
      "usosueloope": formulario.usosueloope1,
      "capalmpesoplope": formulario.capalmpesoplope1,
      "capalmflujoplope": formulario.capalmflujoplope1,
      "capalmpesoapope": formulario.capalmpesoapope1,
      "capalmflujoapope": formulario.capalmflujoapope1,
      "capapropesoapope": formulario.capapropesoapope1,
      "capaproflujoapope": formulario.capaproflujoapope1,
      "captotaldppope": formulario.captotaldppope1,
      "cuotaminope": formulario.cuotaminope1,
      "cuotamaxope": formulario.cuotamaxope1,
      "dencircdope": formulario.dencircdope1,
      "tiporesiduogesa": formulario.tiporesiduoges0a,  //
      "tiporesiduogesb": formulario.tiporesiduoges0b, //
      "tiporesiduogesc": formulario.tiporesiduoges0c, //
      "tiporesiduogesd": formulario.tiporesiduoges0d, //
      "tiporesiduogese": formulario.tiporesiduoges0e, //
      "tiporesiduogesf": formulario.tiporesiduoges0f, //
      "tiporesiduogesg": formulario.tiporesiduoges0g //
    };

    if(formulario.departope2 !== ""){
      const gestor1 = {
      "nombregestor": formulario.nombregestor, //
      "reprelegalgestor": formulario.reprelegalgestor, //
      "selectedtipoidentges": formulario.selectedtipoidentges, //
      "tipoper": formulario.tipoper, //
      "identificacionges": formulario.identificacionges, //
      "digitoges": formulario.digitoges, //
      "departpredio": formulario.departope2,
      "ciudadcompleta": formulario.ciudadope2,
      "tipodirges": formulario.tipodirope2,
      "tipoviages": formulario.tipoviaope2,
      "numviages": formulario.numviaope2,
      "direccionpredio": formulario.dirope2,
      "compldirges": formulario.compldirope2,
      "coornortges": formulario.coornortope2,
      "cooresteges": formulario.cooresteope2,
      "tipotel": formulario.tipotel, //
      "indiges": formulario.indiges, //
      "telges": formulario.telges, //
      "comptelges": formulario.comptelges, //
      "correoges": formulario.correoges, //
      "codigoCIIUp": formulario.codigoCIIUp, //
      "codigoCIIUs": formulario.codigoCIIUs, //
      "selectedactopea": formulario.selectedactope2a,
      "selectedactopeb": formulario.selectedactope2b,
      "selectedactopec": formulario.selectedactope2c,
      "selectedactoped": formulario.selectedactope2d,
      "selectedactopee": formulario.selectedactope2e,
      "usosueloope": formulario.usosueloope2,
      "capalmpesoplope": formulario.capalmpesoplope2,
      "capalmflujoplope": formulario.capalmflujoplope2,
      "capalmpesoapope": formulario.capalmpesoapope2,
      "capalmflujoapope": formulario.capalmflujoapope2,
      "capapropesoapope": formulario.capapropesoapope2,
      "capaproflujoapope": formulario.capaproflujoapope2,
      "captotaldppope": formulario.captotaldppope2,
      "cuotaminope": formulario.cuotaminope2,
      "cuotamaxope": formulario.cuotamaxope2,
      "dencircdope": formulario.dencircdope2,
      "tiporesiduogesa": formulario.tiporesiduoges0a,  //
      "tiporesiduogesb": formulario.tiporesiduoges0b, //
      "tiporesiduogesc": formulario.tiporesiduoges0c, //
      "tiporesiduogesd": formulario.tiporesiduoges0d, //
      "tiporesiduogese": formulario.tiporesiduoges0e, //
      "tiporesiduogesf": formulario.tiporesiduoges0f, //
      "tiporesiduogesg": formulario.tiporesiduoges0g //
    };
    gestores.push(gestor1);
    }

    if(formulario.departope3 !== ""){
      const gestor2 = {
      "nombregestor": formulario.nombregestor, //
      "reprelegalgestor": formulario.reprelegalgestor, //
      "selectedtipoidentges": formulario.selectedtipoidentges, //
      "tipoper": formulario.tipoper, //
      "identificacionges": formulario.identificacionges, //
      "digitoges": formulario.digitoges, //
      "departpredio": formulario.departope3,
      "ciudadcompleta": formulario.ciudadope3,
      "tipodirges": formulario.tipodirope3,
      "tipoviages": formulario.tipoviaope3,
      "numviages": formulario.numviaope3,
      "direccionpredio": formulario.dirope3,
      "compldirges": formulario.compldirope3,
      "coornortges": formulario.coornortope3,
      "cooresteges": formulario.cooresteope3,
      "tipotel": formulario.tipotel, //
      "indiges": formulario.indiges, //
      "telges": formulario.telges, //
      "comptelges": formulario.comptelges, //
      "correoges": formulario.correoges, //
      "codigoCIIUp": formulario.codigoCIIUp, //
      "codigoCIIUs": formulario.codigoCIIUs, //
      "selectedactopea": formulario.selectedactope3a,
      "selectedactopeb": formulario.selectedactope3b,
      "selectedactopec": formulario.selectedactope3c,
      "selectedactoped": formulario.selectedactope3d,
      "selectedactopee": formulario.selectedactope3e,
      "usosueloope": formulario.usosueloope3,
      "capalmpesoplope": formulario.capalmpesoplope3,
      "capalmflujoplope": formulario.capalmflujoplope3,
      "capalmpesoapope": formulario.capalmpesoapope3,
      "capalmflujoapope": formulario.capalmflujoapope3,
      "capapropesoapope": formulario.capapropesoapope3,
      "capaproflujoapope": formulario.capaproflujoapope3,
      "captotaldppope": formulario.captotaldppope3,
      "cuotaminope": formulario.cuotaminope3,
      "cuotamaxope": formulario.cuotamaxope3,
      "dencircdope": formulario.dencircdope3,
      "tiporesiduogesa": formulario.tiporesiduoges0a,  //
      "tiporesiduogesb": formulario.tiporesiduoges0b, //
      "tiporesiduogesc": formulario.tiporesiduoges0c, //
      "tiporesiduogesd": formulario.tiporesiduoges0d, //
      "tiporesiduogese": formulario.tiporesiduoges0e, //
      "tiporesiduogesf": formulario.tiporesiduoges0f, //
      "tiporesiduogesg": formulario.tiporesiduoges0g //
    };
    gestores.push(gestor2);
    }

    if(formulario.departope4 !== ""){
      const gestor4 = {
      "nombregestor": formulario.nombregestor, //
      "reprelegalgestor": formulario.reprelegalgestor, //
      "selectedtipoidentges": formulario.selectedtipoidentges, //
      "tipoper": formulario.tipoper, //
      "identificacionges": formulario.identificacionges, //
      "digitoges": formulario.digitoges, //
      "departpredio": formulario.departope4,
      "ciudadcompleta": formulario.ciudadope4,
      "tipodirges": formulario.tipodirope4,
      "tipoviages": formulario.tipoviaope4,
      "numviages": formulario.numviaope4,
      "direccionpredio": formulario.dirope4,
      "compldirges": formulario.compldirope4,
      "coornortges": formulario.coornortope4,
      "cooresteges": formulario.cooresteope4,
      "tipotel": formulario.tipotel, //
      "indiges": formulario.indiges, //
      "telges": formulario.telges, //
      "comptelges": formulario.comptelges, //
      "correoges": formulario.correoges, //
      "codigoCIIUp": formulario.codigoCIIUp, //
      "codigoCIIUs": formulario.codigoCIIUs, //
      "selectedactopea": formulario.selectedactope4a,
      "selectedactopeb": formulario.selectedactope4b,
      "selectedactopec": formulario.selectedactope4c,
      "selectedactoped": formulario.selectedactope4d,
      "selectedactopee": formulario.selectedactope4e,
      "usosueloope": formulario.usosueloope4,
      "capalmpesoplope": formulario.capalmpesoplope4,
      "capalmflujoplope": formulario.capalmflujoplope4,
      "capalmpesoapope": formulario.capalmpesoapope4,
      "capalmflujoapope": formulario.capalmflujoapope4,
      "capapropesoapope": formulario.capapropesoapope4,
      "capaproflujoapope": formulario.capaproflujoapope4,
      "captotaldppope": formulario.captotaldppope4,
      "cuotaminope": formulario.cuotaminope4,
      "cuotamaxope": formulario.cuotamaxope4,
      "dencircdope": formulario.dencircdope4,
      "tiporesiduogesa": formulario.tiporesiduoges0a,  //
      "tiporesiduogesb": formulario.tiporesiduoges0b, //
      "tiporesiduogesc": formulario.tiporesiduoges0c, //
      "tiporesiduogesd": formulario.tiporesiduoges0d, //
      "tiporesiduogese": formulario.tiporesiduoges0e, //
      "tiporesiduogesf": formulario.tiporesiduoges0f, //
      "tiporesiduogesg": formulario.tiporesiduoges0g //
    };
    gestores.push(gestor4);
    }

    if(formulario.departope5 !== ""){
      const gestor5 = {
      "nombregestor": formulario.nombregestor, //
      "reprelegalgestor": formulario.reprelegalgestor, //
      "selectedtipoidentges": formulario.selectedtipoidentges, //
      "tipoper": formulario.tipoper, //
      "identificacionges": formulario.identificacionges, //
      "digitoges": formulario.digitoges, //
      "departpredio": formulario.departope5,
      "ciudadcompleta": formulario.ciudadope5,
      "tipodirges": formulario.tipodirope5,
      "tipoviages": formulario.tipoviaope5,
      "numviages": formulario.numviaope5,
      "direccionpredio": formulario.dirope5,
      "compldirges": formulario.compldirope5,
      "coornortges": formulario.coornortope5,
      "cooresteges": formulario.cooresteope5,
      "tipotel": formulario.tipotel, //
      "indiges": formulario.indiges, //
      "telges": formulario.telges, //
      "comptelges": formulario.comptelges, //
      "correoges": formulario.correoges, //
      "codigoCIIUp": formulario.codigoCIIUp, //
      "codigoCIIUs": formulario.codigoCIIUs, //
      "selectedactopea": formulario.selectedactope5a,
      "selectedactopeb": formulario.selectedactope5b,
      "selectedactopec": formulario.selectedactope5c,
      "selectedactoped": formulario.selectedactope5d,
      "selectedactopee": formulario.selectedactope5e,
      "usosueloope": formulario.usosueloope5,
      "capalmpesoplope": formulario.capalmpesoplope5,
      "capalmflujoplope": formulario.capalmflujoplope5,
      "capalmpesoapope": formulario.capalmpesoapope5,
      "capalmflujoapope": formulario.capalmflujoapope5,
      "capapropesoapope": formulario.capapropesoapope5,
      "capaproflujoapope": formulario.capaproflujoapope5,
      "captotaldppope": formulario.captotaldppope5,
      "cuotaminope": formulario.cuotaminope5,
      "cuotamaxope": formulario.cuotamaxope5,
      "dencircdope": formulario.dencircdope5,
      "tiporesiduogesa": formulario.tiporesiduoges0a,  //
      "tiporesiduogesb": formulario.tiporesiduoges0b, //
      "tiporesiduogesc": formulario.tiporesiduoges0c, //
      "tiporesiduogesd": formulario.tiporesiduoges0d, //
      "tiporesiduogese": formulario.tiporesiduoges0e, //
      "tiporesiduogesf": formulario.tiporesiduoges0f, //
      "tiporesiduogesg": formulario.tiporesiduoges0g //
    };
    gestores.push(gestor5);
    }
    //////////////////////////////otros gestores/////////////////////////////////////
    if(formulario.nombregestor1 !== ""){
      const gestor6 = {
      "nombregestor": formulario.nombregestor1, //
      "reprelegalgestor": formulario.reprelegalgestor1, //
      "selectedtipoidentges": formulario.selectedtipoidentges1, //
      "tipoper": formulario.tipoper1, //
      "identificacionges": formulario.identificacionges1, //
      "digitoges": formulario.digitoges1, //
      "departpredio": formulario.departpredio1,
      "ciudadcompleta": formulario.ciudadcompletarcd1,
      "tipodirges": formulario.tipodirges1,
      "tipoviages": formulario.tipoviages1,
      "numviages": formulario.numviages1,
      "direccionpredio": formulario.direccionpredio1,
      "compldirges": formulario.compldirges1,
      "coornortges": formulario.coornortges1,
      "cooresteges": formulario.cooresteges1,
      "tipotel": formulario.tipotel1, //
      "indiges": formulario.indiges1, //
      "telges": formulario.telges1, //
      "comptelges": formulario.comptelges1, //
      "correoges": "", //
      "codigoCIIUp": formulario.codigoCIIUp1, //
      "codigoCIIUs": formulario.codigoCIIUs1, //
      "selectedactopea": formulario.selectedactope6a,
      "selectedactopeb": formulario.selectedactope6b,
      "selectedactopec": formulario.selectedactope6c,
      "selectedactoped": formulario.selectedactope6d,
      "selectedactopee": formulario.selectedactope6e,
      "usosueloope": "",
      "capalmpesoplope": "",
      "capalmflujoplope": "",
      "capalmpesoapope": "",
      "capalmflujoapope": "",
      "capapropesoapope": "",
      "capaproflujoapope": "",
      "captotaldppope": "",
      "cuotaminope": "",
      "cuotamaxope": "",
      "dencircdope": "",
      "tiporesiduogesa": formulario.tiporesiduoges1a,  //
      "tiporesiduogesb": formulario.tiporesiduoges1b, //
      "tiporesiduogesc": formulario.tiporesiduoges1c, //
      "tiporesiduogesd": formulario.tiporesiduoges1d, //
      "tiporesiduogese": formulario.tiporesiduoges1e, //
      "tiporesiduogesf": formulario.tiporesiduoges1f, //
      "tiporesiduogesg": formulario.tiporesiduoges1g //
    };
    gestores.push(gestor6);
    }

    if(formulario.nombregestor2 !== ""){
      const gestor7 = {
      "nombregestor": formulario.nombregestor2, //
      "reprelegalgestor": formulario.reprelegalgestor2, //
      "selectedtipoidentges": formulario.selectedtipoidentges2, //
      "tipoper": formulario.tipoper2, //
      "identificacionges": formulario.identificacionges2, //
      "digitoges": formulario.digitoges2, //
      "departpredio": formulario.departpredio2,
      "ciudadcompleta": formulario.ciudadcompletarcd2,
      "tipodirges": formulario.tipodirges2,
      "tipoviages": formulario.tipoviages2,
      "numviages": formulario.numviages2,
      "direccionpredio": formulario.direccionpredio2,
      "compldirges": formulario.compldirges2,
      "coornortges": formulario.coornortges2,
      "cooresteges": formulario.cooresteges2,
      "tipotel": formulario.tipotel2, //
      "indiges": formulario.indiges2, //
      "telges": formulario.telges2, //
      "comptelges": formulario.comptelges2, //
      "correoges": "", //
      "codigoCIIUp": formulario.codigoCIIUp2, //
      "codigoCIIUs": formulario.codigoCIIUs2, //
      "selectedactopea": formulario.selectedactope7a,
      "selectedactopeb": formulario.selectedactope7b,
      "selectedactopec": formulario.selectedactope7c,
      "selectedactoped": formulario.selectedactope7d,
      "selectedactopee": formulario.selectedactope7e,
      "usosueloope": "",
      "capalmpesoplope": "",
      "capalmflujoplope": "",
      "capalmpesoapope": "",
      "capalmflujoapope": "",
      "capapropesoapope": "",
      "capaproflujoapope": "",
      "captotaldppope": "",
      "cuotaminope": "",
      "cuotamaxope": "",
      "dencircdope": "",
      "tiporesiduogesa": formulario.tiporesiduoges2a,  //
      "tiporesiduogesb": formulario.tiporesiduoges2b, //
      "tiporesiduogesc": formulario.tiporesiduoges2c, //
      "tiporesiduogesd": formulario.tiporesiduoges2d, //
      "tiporesiduogese": formulario.tiporesiduoges2e, //
      "tiporesiduogesf": formulario.tiporesiduoges2f, //
      "tiporesiduogesg": formulario.tiporesiduoges2g //
    };
    gestores.push(gestor7);
    }

    if(formulario.nombregestor3 !== ""){
      const gestor8 = {
      "nombregestor": formulario.nombregestor3, //
      "reprelegalgestor": formulario.reprelegalgestor3, //
      "selectedtipoidentges": formulario.selectedtipoidentges3, //
      "tipoper": formulario.tipoper3, //
      "identificacionges": formulario.identificacionges3, //
      "digitoges": formulario.digitoges3, //
      "departpredio": formulario.departpredio3,
      "ciudadcompleta": formulario.ciudadcompletarcd3,
      "tipodirges": formulario.tipodirges3,
      "tipoviages": formulario.tipoviages3,
      "numviages": formulario.numviages3,
      "direccionpredio": formulario.direccionpredio3,
      "compldirges": formulario.compldirges3,
      "coornortges": formulario.coornortges3,
      "cooresteges": formulario.cooresteges3,
      "tipotel": formulario.tipotel3, //
      "indiges": formulario.indiges3, //
      "telges": formulario.telges3, //
      "comptelges": formulario.comptelges3, //
      "correoges": "", //
      "codigoCIIUp": formulario.codigoCIIUp3, //
      "codigoCIIUs": formulario.codigoCIIUs3, //
      "selectedactopea": formulario.selectedactope8a,
      "selectedactopeb": formulario.selectedactope8b,
      "selectedactopec": formulario.selectedactope8c,
      "selectedactoped": formulario.selectedactope8d,
      "selectedactopee": formulario.selectedactope8e,
      "usosueloope": "",
      "capalmpesoplope": "",
      "capalmflujoplope": "",
      "capalmpesoapope": "",
      "capalmflujoapope": "",
      "capapropesoapope": "",
      "capaproflujoapope": "",
      "captotaldppope": "",
      "cuotaminope": "",
      "cuotamaxope": "",
      "dencircdope": "",
      "tiporesiduogesa": formulario.tiporesiduoges3a,  //
      "tiporesiduogesb": formulario.tiporesiduoges3b, //
      "tiporesiduogesc": formulario.tiporesiduoges3c, //
      "tiporesiduogesd": formulario.tiporesiduoges3d, //
      "tiporesiduogese": formulario.tiporesiduoges3e, //
      "tiporesiduogesf": formulario.tiporesiduoges3f, //
      "tiporesiduogesg": formulario.tiporesiduoges3g //
    };
    gestores.push(gestor8);
    }


    gestores.push(gestor);


     return gestores;
    } 
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initializeCanvas);
  },
  mounted() {
    window.scroll({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    this.initializeCanvas();
    window.addEventListener("resize", this.initializeCanvas);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
canvas {
  border: 1px solid #000;
  max-width: 100%;
  height: auto;
}
</style>
