<template>
  <div>
    <div class="container">
      <p style="font-weight: bold; text-align: center">
        FORMULARIO REPORTE ANUAL DE ACEITE DE COCINA USADO - ACU PARA GENERADORES
      </p>
      <p style="font-weight: bold; text-align: center">
        Resolución 0316 de 2018 – Articulo 10
      </p>
    </div>
    <div class="container">
      <p style="text-align: justify">
        Señor(a) usuario (a), los campos que tienen el * son obligatorios para
        radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
        de la información solicitada, así como adjuntar los documentos descritos
        en las instrucciones de diligenciamiento de este formulario.
      </p>
    </div>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="text-right my-3">
        <b-button
          v-b-tooltip.hover
          title="Limpiar campos"
          variant="success"
          @click="resetForm()"
        >
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold">I. DATOS DEL SOLICITANTE </label>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="*Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoper"
              name="selectedtipoper"
              @change="onChangeRadioButton()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoident"
              name="selectedtipoident"
            >
              <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoper == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoper == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Nombre o razón social:"
            style="text-align: left"
          >
            <b-form-group style="text-align: left">
              <label
                style="text-align: left"
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
              >
                *Razón Social</label
              >
              <b-form-input
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
                v-model="form.nombre"
                placeholder="razón social"
                maxlength="180"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre1"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre2"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre3"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre4"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacion"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacion"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digito"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
            <label>*Dirección de correspondencia: </label>
            <b-form-input
              v-model="form.dircorrespondencia"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.depart"
              :options="optionsdepartamento"
              @change="onChangeCiudad()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudad" v-model="ciudadcompleta">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudades"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.tel"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.fax"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*¿Autoriza la notificación mediante correo electrónico?"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcorreo"
              name="selectedcorreo"
            >
              <b-form-radio value="1">Si </b-form-radio>
              <b-form-radio value="2">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="En caso contrario indique la dirección para notificación física:"
            style="text-align: left"
            ><b-form-input
              v-model="form.correodirnoti"
              placeholder="Dirección de notificación"
              maxlength="200"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="*Descripción" style="text-align: left">
            <b-form-textarea
              id="textarea"
              v-model="form.observacionradicado"
              placeholder="Descripción breve de la solicitud"
              rows="3"
              max-rows="6"
              maxlength="3900"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="padding: 5px">
        <div class="col-md-12">
          <b-form-group
            label="FIRMA DEL SOLICITANTE O APODERADO DEBIDAMENTE CONSTITUIDO"
            style="text-align: left; font-weight: bold"
            ><b-form-input
              v-model="form.firmasolicitante"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Adjunta los documentos, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
            drop-placeholder="Drop file here..."
            multiple
            browse-text="Buscar"
          ></b-form-file>
        </div>
        <div class="col-md-1">
          <b-button id="popover-button-variant" href="#" tabindex="0"
            >Ayuda</b-button
          >
          <b-popover
            target="popover-button-variant"
            variant="info"
            triggers="focus"
          >
            <template #title>Tutorial</template>
            De click
            <b-link
              href="../../../document/Guía Rápida Adjuntar documentos Tramites Web.pdf"
              target="blank"
              >aqui</b-link
            >
          </b-popover>
        </div>
      </div>
      <br /><br />
      <b-form-checkbox
        id="aceptacion"
        v-model="form.aceptacion"
        name="aceptacion"
        value="1"
        unchecked-value="0"
        >Acepto el uso de mis datos personales, tratamiento, protección y
        conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
        de 2012.
        <a
          href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
          >Ver Política de protección de Datos Personales de la CVC.</a
        > </b-form-checkbox
      ><br />
      <div v-show="show">
        <b-button block variant="success" @click="enviar()"
          >Enviar solicitud</b-button
        >
      </div>
      <br />
      <div class="text-center" v-show="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div class="text-center">
          <strong>Enviando Solicitud</strong><br />
          <strong
            >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
            subida y salga un mensaje con el número de radicado. Si abandona el
            proceso, la solicitud quedará incompleta...</strong
          >
        </div>
        <br />

        <div
          :key="ind"
          v-for="(tr, ind) in filesValide"
          class="row ml-8 mb-2 d-flex justify-content-center"
        >
          <b-progress
            :max="100"
            striped
            animated
            :variant="'primary'"
            class="w-75"
          >
            <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
              <span>
                {{
                  tr.value !== 0
                    ? tr.color
                      ? `Se proceso el archivo '${tr.name} de manera correcta.`
                      : `No se pudo procesar el archivo '${tr.name}'.`
                    : `Se esta procesando el archivo '${tr.name}'`
                }}
                <strong></strong></span
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <br />
    </div>
    <br />
  </div>
  <!--cierre div principal-->
</template>

<script>
import {
  rutafile,
  rutaciudad,
  rutaenviosolicitud,
  tiponotificacion,
  logo,
  REPORTE_ANUAL_DE_ACEITA_DE_COCINA_USADO_ACU_PARA_GENERADORES,
} from "../properties";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      form: {
        tipotramite: "74",
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "REPORTE ANUAL DE ACEITE DE COCINA USADO - ACU PARA GENERADORES",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram:
        REPORTE_ANUAL_DE_ACEITA_DE_COCINA_USADO_ACU_PARA_GENERADORES, //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        desciudad: "", //CAMBIO
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: tiponotificacion,
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        desciudadapoderado: "", //CAMBIO
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
        logo: logo,
        filesname: "",
        firmasolicitante: "",
        aceptacion: "",
      },
      form1: {
        actividadeco: "",
        CodigoCIIU: "",
        costototal: "",
        deparfuente: "",
        munifuente: "",
        direccionprediofuente: "",
        fuenteabastecimiento: "",
        grado1: "",
        grado2: "",
        grado3: "",
        munitos1: "",
        munitos2: "",
        munitos3: "",
        segundos1: "",
        segundos2: "",
        segundos3: "",
        grados1l: "",
        grados2l: "",
        grados3l: "",
        minutos1l: "",
        minutos2l: "",
        minutos3l: "",
        segundos1l: "",
        segundos2l: "",
        segundos3l: "",
        altitud1l: "",
        altitud2l: "",
        altitud3l: "",
        usosueloactual: "",
        usosuelopotencial: "",
        selectedtipoverti: "",
        selectedcaptacion: "",
        tiempodescargahora: "",
        tiempodescargadias: "",
        tiempodescargacuadal: "",
        selectedtipoflujo: "",
        areavertimiento: "",
        observa: "",
        firma: "",
        aceptacion: "",
        tipovertmiento: "2",
      },
      nombrearchivo:
        "FT.0340.XX_ Reporte anual  de aceite de cocina usado - ACU - Para GENERADORES.xlsx",
      ciudadapoderado: "", //CAMBIO
      ciudadcompleta: "", //CAMBIO
      form2: {},
      datos: [],
      tipopeticion: "",
      nombretramite: "",
      peticion: "",
      loading: false,
      show: true,
      showciudad: false,
      showciudadapo: false,
      coincide: false,
      file1: [],
      pesofile1: 0,
      nombrefiles: [],
      arryaJson: [],
      contadorarchivos: 0, // nueva------------------------------------------------------------
      contadorresponse: 0, // nueva------------------------------------------------------------
      filesValide: [], // nueva----------------------------------------------------------------
      optionsCiudad: [
        { value: "1000", text: "SANTIAGO DE CALI" },
        { value: "20000", text: "ALCALA" },
        { value: "36000", text: "ANDALUCIA" },
        { value: "41000", text: "ANSERMANUEVO" },
        { value: "54000", text: "ARGELIA" },
        { value: "100000", text: "BOLIVAR" },
        { value: "109000", text: "BUENAVENTURA" },
        { value: "111000", text: "GUADALAJARA DE BUGA" },
        { value: "113000", text: "BUGALAGRANDE" },
        { value: "122000", text: "CAICEDONIA" },
        { value: "126000", text: "DARIEN" },
        { value: "130000", text: "CANDELARIA" },
        { value: "147000", text: "CARTAGO" },
        { value: "233000", text: "DAGUA" },
        { value: "243000", text: "EL AGUILA" },
        { value: "246000", text: "EL CAIRO" },
        { value: "248000", text: "EL CERRITO" },
        { value: "250000", text: "EL DOVIO" },
        { value: "275000", text: "FLORIDA" },
        { value: "306000", text: "GINEBRA" },
        { value: "318000", text: "GUACARI" },
        { value: "364000", text: "JAMUNDI" },
        { value: "377000", text: "LA CUMBRE" },
        { value: "400000", text: "LA UNION" },
        { value: "403000", text: "LA VICTORIA" },
        { value: "497000", text: "OBANDO" },
        { value: "520000", text: "PALMIRA" },
        { value: "563000", text: "PRADERA" },
        { value: "606000", text: "RESTREPO" },
        { value: "616000", text: "RIOFRIO" },
        { value: "622000", text: "ROLDANILLO" },
        { value: "670000", text: "SAN PEDRO" },
        { value: "736000", text: "SEVILLA" },
        { value: "823000", text: "TORO" },
        { value: "828000", text: "TRUJILLO" },
        { value: "834000", text: "TULUA" },
        { value: "845000", text: "ULLOA" },
        { value: "863000", text: "VERSALLES" },
        { value: "869000", text: "VIJES" },
        { value: "890000", text: "YOTOCO" },
        { value: "892000", text: "YUMBO" },
        { value: "895000", text: "ZARZAL" },
      ],
      optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
      optionsdepartamento: [
        { value: "91", text: "AMAZONAS" },
        { value: "5", text: "ANTIOQUIA" },
        { value: "81", text: "ARAUCA" },
        {
          value: "88",
          text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        },
        { value: "8", text: "ATLANTICO" },
        { value: "11", text: "BOGOTA D.C" },
        { value: "13", text: "BOLIVAR" },
        { value: "15", text: "BOYACA" },
        { value: "17", text: "CALDAS" },
        { value: "18", text: "CAQUETA" },
        { value: "85", text: "CASANARE" },
        { value: "19", text: "CAUCA" },
        { value: "20", text: "CESAR" },
        { value: "27", text: "CHOCO" },
        { value: "23", text: "CORDOBA" },
        { value: "25", text: "CUNDINAMARCA" },
        { value: "94", text: "GUAINIA" },
        { value: "95", text: "GUAVIARE" },
        { value: "41", text: "HUILA" },
        { value: "44", text: "LA GUAJIRA" },
        { value: "47", text: "MAGDALENA" },
        { value: "50", text: "META" },
        { value: "52", text: "NARIÑO" },
        { value: "54", text: "NORTE DE SANTANDER" },
        { value: "86", text: "PUTUMAYO" },
        { value: "63", text: "QUINDIO" },
        { value: "66", text: "RISARALDA" },
        { value: "68", text: "SANTANDER" },
        { value: "70", text: "SUCRE" },
        { value: "73", text: "TOLIMA" },
        { value: "76", text: "VALLE DEL CAUCA" },
        { value: "97", text: "VAUPES" },
        { value: "99", text: "VICHADA" },
      ],
      optionsciudades: [],
      optionsciudadesapo: [], //CAMBIO
      optionstiponoti: [
        { value: "2", text: "CORREO CERTIFICADO" },
        { value: "1", text: "CORREO ELECTRONICO" },
        { value: "7", text: "NOTIFICACIÓN PERSONAL" },
      ],
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
  },
  methods: {
    enviar() {
      this.arryaJson = [];
      this.nombrefiles = []; // nueva-----------------------------------------------------------

      this.form.desciudad = this.ciudadcompleta.text; //CAMBIO
      this.form.ciudad = this.ciudadcompleta.value; //CAMBIO
      this.form.desciudadapoderado = this.ciudadapoderado.text; //CAMBIO
      this.form.ciudadapoderado = this.ciudadapoderado.value; //CAMBIO

      this.form.direccionpredio = this.form.dircorrespondencia;
      this.form.departpredio = this.form.depart;
      this.form.ciudadpredio = this.form.ciudad;

      if (this.form.selectedtipoident === "25") {
        this.form.selectedtipoident = "26";
        console.log("this.form.selectedtipoident", this.form.selectedtipoident);
      }

      if (this.file1.length > 0) {
        this.file1.forEach((file) => {
          this.nombrefiles.push(file.name);
          //console.log("nombre archivos adjuntos", file.name)
          if (file.name === this.nombrearchivo) {
            this.coincide = true;
          }
        });
      }

      if (this.coincide) {
        console.log("El nombre está en el array.");
      } else {
        console.log("El nombre no está en el array.");
      }

      this.form.filesname = this.nombrefiles.toString();

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (
        this.validacioncampos(this.form) === true &&
        this.validacioncamposapo(this.form) === true
      ) {
        this.form2 = Object.assign({}, this.form, this.form1);

        this.arryaJson.push(this.form, this.form1, this.form2);

        if (Array.isArray(this.arryaJson)) {
          // Recorre el arryaJson y aplica la función a todas las propiedades de todos los objetos en la matriz
          this.arryaJson.forEach((obj) => {
            for (const prop in obj) {
              if (typeof obj[prop] === "string") {
                obj[prop] = this.replaceSpecialCharacters(obj[prop]);
              }
            }
          });
        } else {
          console.error("arryaJson no es una matriz o está indefinida");
        }

        this.loading = true;
        this.show = false;
        this.pesofile1 = 0; // nueva----------------------------------------------------------------
        for (let i = 0; i < this.file1.length; i++) {
          this.pesofile1 += this.file1[i].size;
        }

        // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->

        if (this.file1.length > 0 && this.coincide === true) {
          if (this.validarpesofile(this.file1) === true) {
            // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
            if (this.pesofile1 > 50000000) {
              swal(
                "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
              );
              this.loading = false;
              this.show = true;

              this.pesofile1 = 0;
            } else {
              // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
              axiosInstance
                .post(rutaenviosolicitud, this.arryaJson)
                .then((response) => {
                  let blob = this.b64toBlob(response.data.pdf);

                  this.file1.push(blob);

                  console.log("adjuntos frontend ", this.file1.length);
                  if (this.file1.length > 0) {
                    this.contadorarchivos = this.file1.length;
                    this.filesValide = []; /////////////7nuevo

                    console.log("cantidad de archivos ", this.contadorarchivos);
                    this.file1.forEach((file) => {
                      /////////////7nuevo
                      this.filesValide.push({
                        name: file.name,
                        value: 0,
                        color: true,
                      });

                      const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                      const formData = new FormData();
                      var regex = /([^-\w.]*)/gm;
                      //console.log("nombre archivo", file.name);
                      var arcPDF2 = file.name.replace(regex, "");
                      arcPDF2 = arcPDF2.replaceAll(" ", "");
                      //console.log("nombre nuevo ", arcPDF2);

                      var blob = file.slice(0, file.size, file.type);
                      var newFile = new File([blob], arcPDF2, {
                        type: file.type,
                      });

                      formData.append("file", newFile);
                      formData.append("fileName", arcPDF2);
                      formData.append("radicado", response.data.radicado);
                      formData.append("usuario", response.data.usuario);
                      formData.append("ordenTrabajo", response.data.orden);
                      formData.append("idCorreo", response.data.correo);

                      // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                      axios
                        .post(rutafile, formData, {
                          /// envio archivos
                          headers: { "content-type": "multipart/form-data" },
                          maxRedirects: 0,
                        })
                        .then((respon) => {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          if (respon.status === 200) {
                            this.contadorresponse++;
                          } else {
                            ///////////77nuevo
                            this.filesValide[keyInd].color = false;
                          } ///////////77nuevo

                          this.resetForm();
                          //console.log("Respuesta envio adjuntos", respon);

                          if (this.contadorarchivos === this.contadorresponse) {
                            this.loading = false;
                            swal({
                              closeOnClickOutside: false,
                              text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                          }

                          this.show = true;
                        })
                        .catch(function (error) {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          this.filesValide[keyInd].color = false; ///////////77nuevo
                          if (
                            error.response.status === 500 ||
                            error.response.status === 404
                          ) {
                            swal({
                              closeOnClickOutside: false,
                              text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                            this.resetForm();
                            console.log("error 2022 ", error.response.status);
                          }
                          this.show = true;
                        });
                    });
                  } else {
                    this.resetForm();
                    swal({
                      closeOnClickOutside: false,
                      text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                    this.loading = false;
                    this.show = true;
                  }
                })
                .catch(function (error) {
                  if (error.response.status === 500) {
                    swal({
                      closeOnClickOutside: false,
                      text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                  }
                  this.loading = false;
                  this.show = true;
                });
            }
          } else {
            swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
            this.loading = false;
            this.show = true;
          }
        } else {
          swal(
            "Señor(a) usuario(a) debe adjuntar el documento llamado ",
            this.nombrearchivo + " NOTA: No le modifique el nombre al archivo"
          );
          this.loading = false;
          this.show = true;
        }
      } else {
        console.log("fallo");
      }
    },
    validarpesofile(files) {
      var count = 0;
      //console.log(files.length);
      files.forEach((file) => {
        if (file.size > 10000000) {
          count++;
        }
      });
      //console.log("archivos con peso de mas de 10 mb", count);
      if (count == 0) {
        return true;
      } else {
        return false;
      }
    },
    b64toBlob(b64Data) {
      const names = "formulario.pdf";
      const newbase64 = "data:application/pdf;base64," + b64Data;

      let arr = newbase64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file_created = new File([u8arr], names, { type: mime });

      /*var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
  
        var blob = new Blob([file_created], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "fileName.pdf";
        //a.click();*/

      return file_created;
    },
    resetForm() {
      this.form1.firma = "";
      (this.form = {
        tipotramite: "",
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: "",
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
      }),
        (this.form1 = {
          actividadeco: "",
          CodigoCIIU: "",
          costototal: "",
          deparfuente: "",
          munifuente: "",
          direccionprediofuente: "",
          fuenteabastecimiento: "",
          grado1: "",
          grado2: "",
          grado3: "",
          munitos1: "",
          munitos2: "",
          munitos3: "",
          segundos1: "",
          segundos2: "",
          segundos3: "",
          grados1l: "",
          grados2l: "",
          grados3l: "",
          minutos1l: "",
          minutos2l: "",
          minutos3l: "",
          segundos1l: "",
          segundos2l: "",
          segundos3l: "",
          altitud1l: "",
          altitud2l: "",
          altitud3l: "",
          usosueloactual: "",
          usosuelopotencial: "",
          selectedtipoverti: "",
          selectedcaptacion: "",
          tiempodescargahora: "",
          tiempodescargadias: "",
          tiempodescargacuadal: "",
          selectedtipoflujo: "",
          areavertimiento: "",
          observa: "",
          firma: "",
          aceptacion: "",
        });
    },
    validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(
        /[^0-9]/g,
        ""
      ); // Elimina cualquier carácter que no sea un número
      this.form.identificacionapode = this.form.identificacionapode.replace(
        /[^0-9]/g,
        ""
      );
    },
    validacioncampos() {
      if (this.form.selectedtipoper === "") {
        swal("Debe seleccionar el tipo de persona");
      } else if (this.form.selectedtipotram === "") {
        swal("Debe seleccionar el tipo de tramite");
      } else if (this.form.selectedtipoident === "") {
        swal("Debe seleccionar el tipo de identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.nombre === ""
      ) {
        swal("Debe ingresar un nombre o razón social");
      } else if (
        (this.form.selectedtipoident === "10" ||
          this.form.selectedtipoident === "36" ||
          this.form.selectedtipoident === "11") &&
        (this.form.nombre1 === "" || this.form.nombre3 === "")
      ) {
        swal("Debe ingresar un nombre y un apellido");
      } else if (this.form.identificacion === "") {
        swal("Debe ingresar la identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.digito === ""
      ) {
        swal("Debe ingresar el digito de verificación");
      } else if (this.form.dircorrespondencia === "") {
        swal("Debe ingresar la dirección de correspondencia");
      } else if (this.form.depart === "") {
        swal("Debe seleccionar el departamento de correspondencia");
      } else if (this.ciudadcompleta.value == undefined) {
        swal("Debe seleccionar la ciudad de correspondencia");
      } else if (this.form.selectedcorreo === "") {
        swal("Debe seleccionar si o no autoriza la notificación por correo");
      } else if (this.form.selectedcorreo === "1" && this.form.correo === "") {
        swal("Por favor ingresar un correo");
      } else if (
        this.form.selectedcorreo === "1" &&
        this.validEmail(this.form.correo) === false
      ) {
        swal("Por favor ingresar un correo valido");
      } else if (
        this.form.selectedcorreo === "2" &&
        this.form.correodirnoti === ""
      ) {
        swal("Por favor ingresar una dirección de notificación");
      } else if (this.form.observacionradicado === "") {
        swal("Debe ingresar una breve descripción de la solicitud");
      } else if (this.form.direccionpredio === "") {
        swal(
          "Debe ingresar la dirección del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.departpredio === "") {
        swal(
          "Debe seleccionar el departamento del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.ciudadpredio === "") {
        swal(
          "Debe seleccionar el municipio del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.aceptacion === "") {
        swal(
          "Debe aceptar el uso de mis datos personales, tratamiento, protección"
        );
      } else {
        return true;
      }
    },
    validacioncamposapo() {
      if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoidentapo === ""
      ) {
        swal("Debe seleccionar el tipo de identificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoperapo === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.nombreapoderado === ""
      ) {
        swal("Debe ingresar un nombre o razón social del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "10") ||
          this.form.selectedtipoidentapo === "36" ||
          this.form.selectedtipoidentapo === "11") &&
        (this.form.nombre1apo === "" || this.form.nombre3apo === "")
      ) {
        swal("Debe ingresar un nombre y un apellido del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.identificacionapode === ""
      ) {
        swal("Debe ingresar la identificación del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.digitoapo === ""
      ) {
        swal("Debe ingresar el digito de verificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.dirapoderado === ""
      ) {
        swal("Debe ingresar la dirección de correspondencia del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.departapoderado === ""
      ) {
        swal(
          "Debe seleccionar el departamento de correspondencia del representante"
        );
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.ciudadapoderado.value == undefined
      ) {
        swal("Debe seleccionar la ciudad de correspondencia del representante");
      } else if (
        this.form.correoapoderado !== "" &&
        this.validEmail(this.form.correoapoderado) === false
      ) {
        swal("Por favor ingresar un correo valido del representante");
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
    onChangeCiudad() {
      if (this.form.depart !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.depart}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudad = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeCiudadapoderado() {
      //CAMBIO
      if (this.form.departapoderado !== "") {
        this.optionsciudadesapo = [];

        this.$http
          .post(rutaciudad + `${this.form.departapoderado}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudadesapo.push(objeto);
            }
            this.showciudadapo = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeRadioButton() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoper === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoident = "";
        this.form.selectedtipoperjuridica = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoper === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoident = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    onChangeRadioButtonApo() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoperapo === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoidentapo = "";
        this.form.selectedtipoperjuridicaapo = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoperapo === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoidentapo = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    replaceSpecialCharacters(value) {
      if (typeof value === "string") {
        // Reemplaza el carácter "&" y comillas simples por una cadena vacía
        return value.replace(/&/g, "").replace(/'/g, "");
      } else {
        return value; // Si no es una cadena, devuelve el valor sin cambios
      }
    },
  },
  mounted() {
    window.scroll({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
